import React, { useState, useCallback } from 'react';
import { MaxWidth, Section } from 'GlobalStyle';
import { Main01, Main02, Main03, Wrapper, Main02Item, Main03Item } from './style';

const Security = () => {
  return (
    <>
      <Main01>
        <div>
          <div className="main01_wrap">
            <p>기원테크가 만든 최고 수준의 보안,</p>
            <h1>SECU E Cloud</h1>
            <p className="intro">
              <span>급증하고 있는 이메일 공격으로부터 </span>
              <span>사용자가 안심하고 메일을 사용할 수 있도록, </span>
              <span>메일 보안에 필요한 모든 것을 하나로 담은 SECU E Cloud</span>
            </p>
          </div>
        </div>
      </Main01>
      <Main02>
        <MaxWidth>
          <h2>
            <span>
              <span>능동적인 선제대응,</span>
            </span>
            <span className="highlight">‘실시간, 동적, 학습’ </span>기반
            <strong> 강력한 수신메일 보안</strong>
          </h2>
          <Wrapper>
            <Main02Item>
              <h5>
                <span>정상메일로 위장한 </span>
                <span>사기메일 실시간 검출 </span>
              </h5>
              <p>
                <span>도입 기업의 거래처 메일 정보를 </span>
                <span>
                  <span className="highlight">실시간으로 학습</span>하고 분석하여
                </span>
                <span> 백신으로 탐지 불가한 사기메일 검출</span>
              </p>
            </Main02Item>
            <Main02Item>
              <h5>
                <span>첨부파일과 URL에 숨겨진 </span>
                <span>신종 랜섬웨어 검출</span>
              </h5>
              <p>
                <span>
                  <span className="highlight">‘3단계 정·동적 다중 분석 검사’</span>를 통해&nbsp;
                </span>
                <span>이미 알려진 위험은 물론 </span>
                <span>알려지지 않은 위협까지 검출</span>
              </p>
            </Main02Item>
            <Main02Item>
              <h5>
                <span>학습형 스팸 필터링이 결합된 </span>
                <span>효과적인 스팸메일 차단 </span>
              </h5>
              <p>
                <span>
                  DB 기반 <span className="highlight">빠르고 효율적인</span>&nbsp;‘스팸 차단
                </span>
                <span>
                  &nbsp;기본 기능’과 <span className="highlight">능동적으로</span>&nbsp;스팸을
                  탐지하는
                </span>
                <span> ‘학습형 스팸 필터링’의 결합</span>
              </p>
            </Main02Item>
          </Wrapper>
        </MaxWidth>
      </Main02>
      <Main03>
        <MaxWidth>
          <h2>
            <span>이미 발송한 메일을 제어,</span>
            <span>
              <strong className="bold">오발송</strong> 사고 대응과
              <strong className="bold"> 정보 유출 방지</strong>
            </span>
          </h2>
          <Wrapper>
            <Main03Item>
              <h5>
                <span>오발송 대응, 메일 </span>
                <span>열람 확인과 본문 보기 제한</span>
              </h5>
              <p>
                <span>
                  수신자의 메일 <strong className="highlight">열람 일시</strong> 및 IP 로그
                  제공,&nbsp;
                </span>
                <span>
                  메일 <strong className="highlight">본문 보기를 제한</strong>하여&nbsp;
                </span>
                <span>간편하게 오발송 대응 (보안메일 기능)</span>
              </p>
            </Main03Item>
            <Main03Item>
              <h5>
                <span>정보 유출 차단, </span>
                <span>정교한 승인메일 체계 구축</span>
              </h5>
              <p>
                <span>‘제목/본문/첨부파일’에 특정 키워드,</span>
                <span>
                  <span className="highlight">‘본문 이미지/대용량 파일 첨부 여부’</span> 등&nbsp;
                </span>
                <span>정밀한 승인메일 발송 조건 설정</span>
              </p>
            </Main03Item>
            <Main03Item>
              <h5>
                <span>악성 계정으로 </span>
                <span>메일 발신 방지</span>
              </h5>
              <p>
                <span>수신메일 보안 솔루션과 악성 계정 데이터를 </span>
                <span>
                  공유하여 <span className="highlight">위·변조 및 악성메일에 답장</span>
                  시,&nbsp;
                </span>
                <span>
                  발신 <span className="highlight">경고</span> 알림을 발송
                </span>
              </p>
            </Main03Item>
          </Wrapper>
        </MaxWidth>
      </Main03>
    </>
  );
};

export default Security;
