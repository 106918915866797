import styled, { keyframes } from 'styled-components';

export const Main01 = styled.article`
  position: relative;
  width: 100%;
  padding: 231px 40px 150px;
  box-sizing: border-box;

  @media screen and (max-width: 920px) {
    padding: 170px 40px 110px;
  }
  @media screen and (max-width: 670px) {
    padding: 140px 40px 70px;
  }
  @media screen and (max-width: 600px) {
    padding: 470px 30px 50px;
  }
  @media screen and (max-width: 480px) {
    padding: 450px 30px 50px;
  }

  & > div {
    display: flex;
    justify-content: right;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      justify-content: center;
      align-items: end;
    }
  }

  .main01_wrap {
    position: relative;
    z-index: 1;
    width: 50%;

    @media screen and (max-width: 600px) {
      width: 100%;
      text-align: center;
      padding-bottom: 55px;
    }
    @media screen and (max-width: 330px) {
      padding-bottom: 25px;
    }

    h1 {
      color: #3d6eb6;
      font-size: 60px;
      font-weight: 500;
      font-family: 'SBAggrolL';
      margin-bottom: 24px;
      line-height: 1.2;
      letter-spacing: -0.05em;

      @media screen and (max-width: 920px) {
        font-size: 48px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 480px) {
        font-size: 38px;
      }
    }
    p {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #3d6eb6;
      -webkit-background-clip: text;
      letter-spacing: -0.025em;

      @media screen and (max-width: 920px) {
        font-size: 20px;
      }
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }

    .intro {
      @media screen and (max-width: 1200px) {
        padding-right: 50px;
      }

      @media screen and (max-width: 920px) {
        font-size: 16px;
        word-break: break-word;
      }

      @media screen and (max-width: 670px) {
        padding-right: 30px;
      }

      @media screen and (max-width: 600px) {
        width: auto;
        margin: 0 auto;
        padding-right: 0;
      }

      @media screen and (max-width: 330px) {
        width: 100%;
      }
      span {
        display: block;
        margin: 0 0 3px 0;
        color: #6f6f6f;
        font-size: 19px;
        font-weight: 500;
        line-height: 1.6;

        @media screen and (max-width: 1200px) {
          display: inline;
        }
        @media screen and (max-width: 920px) {
          font-size: 16px;
        }
      }
    }
  }

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-security01_left.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    right: 56%;
    width: 890px;
    top: 0;
    bottom: 0;

    @media screen and (max-width: 920px) {
      background-size: auto 335px;
      width: 400px;
      background-position: right bottom;
      right: 57%;
    }

    @media screen and (max-width: 820px) {
      background-position: right bottom 1%;
      width: 400px;
      right: 52%;
    }
    @media screen and (max-width: 750px) {
      right: 47%;
    }

    @media screen and (max-width: 600px) {
      background-image: url('/images/background/bg-security01_center.png');
      background-size: 600px;
      background-position: bottom center;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-mail01-right.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
`;

export const Main02 = styled.article`
  width: 100%;
  padding: 120px 0px;
  background-color: #4d81db;
  box-sizing: border-box;
  @media screen and (max-width: 1300px) {
    padding: 100px 0px;
  }
  @media screen and (max-width: 1080px) {
    padding: 90px 45px;
  }
  @media screen and (max-width: 680px) {
    padding: 75px 0px;
  }

  h2 {
    text-align: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: 400;
    font-family: 'SBAggrolL';
    line-height: 1.5;
    letter-spacing: -0.01em;
    padding-bottom: 65px;
    @media screen and (max-width: 880px) {
      padding-bottom: 45px;
      font-size: 32px;
    }
    @media screen and (max-width: 480px) {
      padding-bottom: 28px;
      font-size: 28px;
    }
    .highlight {
      font-family: 'SBAggrolL';
      color: #ccf7ff;
    }
    strong {
      font-family: 'SBAggrolL';
      font-weight: 500;
    }
    span {
      & > span {
        display: block;
        font-family: 'SBAggrolL';
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 25px;
  box-sizing: border-box;
  @media screen and (max-width: 1300px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 1080px) {
    flex-direction: column;
    row-gap: 30px;
  }
  @media screen and (max-width: 680px) {
    flex-direction: column;
    row-gap: 35px;
  }
`;

export const Main02Item = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 70px 40px 70px;
  box-sizing: border-box;
  height: 330px;
  box-shadow: 15px 15px 20px rgba(72, 81, 113, 0.15);

  h5 {
    font-family: 'NotoSansKr';
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: 1.6;
    letter-spacing: -0.035em;
    color: #333;

    @media screen and (max-width: 680px) {
      font-size: 20px;
    }

    span {
      display: block;
      color: #333;
      margin: -3px 0 0 0;

      @media screen and (max-width: 1080px) {
        display: inline;
      }
      @media screen and (max-width: 680px) {
        display: block;
      }
      @media screen and (max-width: 380px) {
        display: inline;
      }
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    font-family: 'NotoSansKr';
    color: #656565;
    line-height: 1.6;
    letter-spacing: -0.025em;

    .highlight {
      background: #ccf7ff;
      font-weight: 600;
      color: #4575c7;
      display: inline;
      padding: 0 3px;
    }
    span {
      display: inline-block;
      margin: 0 0 10px 0;
      @media screen and (max-width: 1300px) {
        display: inline;
      }
    }
    @media screen and (max-width: 680px) {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 70px 20px 70px 30px;
  }
  @media screen and (max-width: 1180px) {
    height: 380px;
  }
  @media screen and (max-width: 1080px) {
    padding: 45px 40px 60px;
    height: auto;
  }
  @media screen and (max-width: 680px) {
    padding: 45px 30px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 380px) {
    padding-top: 40px;
  }
`;

export const Main03 = styled.article`
  width: 100%;
  padding: 105px 0px;
  background-color: #f7f8ff;
  box-sizing: border-box;
  h2 {
    text-align: center;
    color: #4d4d4d;
    line-height: 1.5;
    padding-bottom: 65px;

    span {
      font-size: 38px;
      font-weight: 400;
      font-family: 'SBAggrolL';
      display: block;
      @media screen and (max-width: 350px) {
        display: inline;
      }
      & > .bold {
        display: inline;
        font-family: 'SBAggrolL';
        font-weight: 500;
        color: #4d81db;
      }
      @media screen and (max-width: 1080px) {
        font-size: 32px;
      }
      @media screen and (max-width: 680px) {
        font-size: 28px;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 100px 0px;
  }
  @media screen and (max-width: 1080px) {
    padding: 90px 45px;
  }
  @media screen and (max-width: 680px) {
    padding: 75px 0px;
  }
`;

export const Main03Item = styled.div`
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 60px 20px;
  box-sizing: border-box;
  height: 320px;
  background-repeat: no-repeat;
  box-shadow: 15px 15px 25px rgba(72, 81, 113, 0.15);

  &:before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    position: absolute;
    height: 63px;
    width: 63px;
    left: 50%;
    right: 0;
    top: -25px;
    transform: translate(-51%, 0);
  }
  &:nth-of-type(1):before {
    background-image: url('/images/background/bg-security03_num1.png');
  }
  &:nth-of-type(2):before {
    background-image: url('/images/background/bg-security03_num2.png');
  }
  &:nth-of-type(3):before {
    background-image: url('/images/background/bg-security03_num3.png');
  }
  h5 {
    font-family: 'NotoSansKr';
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: center;
    letter-spacing: -0.035em;
    & > span {
      display: block;
      color: #333;
    }
    @media screen and (max-width: 680px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 20px;
    font-family: 'NotoSansKr';
    color: #656565;
    line-height: 1.8;
    text-align: center;
    letter-spacing: -0.025em;
    @media screen and (max-width: 400px) {
      line-height: 1.5;
    }
    .highlight {
      background-color: #ccf7ff;
      font-weight: 600;
      color: #4575c7;
      display: inline;
      padding: 0 3px;
    }
    span {
      display: block;
      @media screen and (max-width: 1300px) {
        display: inline;
      }
      @media screen and (max-width: 1080px) {
        display: block;
      }
      @media screen and (max-width: 680px) {
        font-size: 16px;
      }
      @media screen and (max-width: 400px) {
        display: inline;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 70px 25px;
    height: 380px;
  }
  @media screen and (max-width: 1080px) {
    padding: 50px 0px 35px 0px;
    height: auto;
  }
  @media screen and (max-width: 680px) {
    padding: 55px 35px;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 25px;
  }
  @media screen and (max-width: 380px) {
    padding: 35px 20px;
  }
`;
