import styled from 'styled-components';

export const Main = styled.article`
  position: relative;
  width: 100%;
  padding: 235px 40px 70px;
  box-sizing: border-box;
  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    max-width: 1280px;

    img {
      position: relative;
      z-index: 1;
      top: -33px;
      @media screen and (max-width: 920px) {
        display: none;
      }
      @media screen and (max-width: 1000px) {
        // width: auto;
        left: 33px;
      }
    }
    @media screen and (max-width: 920px) {
      display: block;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-cs_circle01.png');
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    width: 382px;
    bottom: 0;
    left: 0;
    top: 95px;

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-cs_circle02.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    width: 382px;
    bottom: 0;
    top: 0;
    right: 0;

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }

  .main01_wrap {
    position: relative;
    width: 50%;
    padding: 0 15px;

    @media screen and (max-width: 1300px) {
      padding: 0;
    }

    h1 {
      font-family: 'SBAggrolL';
      font-size: 64px;
      color: #4a4a4a;
      font-weight: 500;
      margin: 0 0 25px 0;
      letter-spacing: -0.015em;

      @media screen and (max-width: 920px) {
        margin-left: 0px;
        margin-bottom: 20px;
        font-size: 40px;
      }
      @media screen and (max-width: 480px) {
        font-size: 34px;
      }
    }

    p {
      font-size: 18px;
      color: #666666;
      line-height: 1.6;
      margin: 0 0 50px 0;
      letter-spacing: -0.025em;

      span {
        display: block;
      }
      @media screen and (max-width: 920px) {
        margin-left: 0px;
      }
      @media screen and (max-width: 480px) {
        margin-bottom: 40px;
        font-size: 16px;
      }
    }
    ul {
      display: flex;
      column-gap: 15px;

      li {
        display: inline-block;

        button {
          background-color: #ffffff;
          border-radius: 22px;
          padding: 0px 35px;
          height: 45px;
          box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
          color: #505050;
          font-weight: 400;
          font-size: 18px;
          font-family: SBAggrolL;
          line-height: 2.7;
          letter-spacing: -0.025em;

          @media screen and (max-width: 992px) {
            padding: 0px 25px;
            font-size: 16px;
            line-height: 1.2;
          }
          @media screen and (max-width: 480px) {
            font-size: 14px;
            padding: 0px 20px;
          }
        }
        .active {
          background-color: #4d81db;
          color: #ffffff;
          box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
          font-weight: 400;
        }
      }
      @media screen and (max-width: 920px) {
        justify-content: center;
      }
    }
    @media screen and (max-width: 920px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
  @media screen and (max-width: 920px) {
    padding-top: 150px;
  }
  @media screen and (max-width: 480px) {
    padding: 130px 0px 70px;
  }
`;
