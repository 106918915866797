import styled from 'styled-components';

export const DownloadWrapper = styled.form`
  border-radius: 15px;
  box-sizing: border-box;

  h3 {
    box-sizing: border-box;
    color: #5d5d5d;
    font-weight: 500;
    font-family: SBAggrolL;
    font-size: 26px;
    margin-bottom: 50px;
    span {
      display: block;
      font-size: 15px;
      font-weight: 400;
      color: #868686;
      line-height: 2;
      @media screen and (max-width: 750px) {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 750px) {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }
  button {
    width: 130px;
    height: 40px;
    background-color: #4d81db;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 0 30px;
  }
  .agreeWrapper {
    color: #6f6f6f;
    font-size: 14px;
    font-weight: 500;

    a {
      cursor: pointer;
    }
    button {
      display: block;
      margin: 20px auto 0;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 45px;
  box-sizing: border-box;

  @media screen and (max-width: 750px) {
    overflow-y: scroll;
    height: 35vh;
  }
  .flexItem {
    display: flex;
    justify-content: start;
    align-items: center;
    .documentType {
      text-align: left;
      width: 90px;
      span {
        font-size: 15px;
        font-weight: 500;
        color: #5e5e5e;
      }
      @media screen and (max-width: 750px) {
        width: 110px;
      }
    }
    input {
      height: 35px;
      border-radius: 10px;
      background-color: #f4f4f9;
      padding: 0 0 0 10px;
    }
    .company {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 50%;
      font-size: 15px;
      font-weight: 500;
      color: #5e5e5e;
      span {
        text-align: left;
        width: 90px;
      }
      input {
        width: 70%;
      }
      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }
    .unit {
      display: flex;
      justify-content: start;
      align-items: center;
      span {
        text-align: left;
        width: 90px;
        color: #5e5e5e;
        font-weight: 500;
        font-size: 15px;
        @media screen and (max-width: 350px) {
          width: 85px;
        }
      }
      .title {
        @media screen and (max-width: 1200px) {
          padding-left: 30px;
        }
        @media screen and (max-width: 750px) {
          padding-left: 0px;
        }
      }
      input {
        width: 75px;
        margin-right: 20px;
      }
    }
    .name {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 50%;
      font-size: 15px;
      font-weight: 500;
      color: #5e5e5e;
      span {
        text-align: left;
        width: 90px;
      }
      input {
        width: 70%;
        @media screen and (max-width: 750px) {
          width: 70%;
        }
      }
      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }
    .phone {
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      color: #5e5e5e;
      width: 50%;
      span {
        text-align: left;
        width: 90px;
        @media screen and (max-width: 1200px) {
          padding-left: 30px;
        }
        @media screen and (max-width: 750px) {
          padding-left: 0px;
        }
      }
      input {
        width: 50%;
        @media screen and (max-width: 750px) {
          width: 70%;
        }
      }
      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }
    .email {
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      color: #5e5e5e;
      width: 100%;
      span {
        text-align: left;
        width: 90px;
      }
      input {
        width: 40%;
        @media screen and (max-width: 750px) {
          width: 70%;
        }
      }
      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 750px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 15px;
      :nth-of-type(1) {
        flex-direction: row;
      }
    }
  }
  .checkboxWrapper {
    display: grid;
    row-gap: 10px;
    width: 90%;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    .trn {
      display: inline-block;
      margin-left: 5px;
      font-size: 15px;
      color: #858585;
      font-weight: 500;
      line-height: 1.2;
    }
    @media screen and (max-width: 750px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }
  @media screen and (max-width: 750px) {
    row-gap: 15px;
    margin-bottom: 20px;
  }
`;

export const CheckBox = styled.label`
  color: #6f6f6f;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  padding-left: 30px;
  text-align: left;
  min-width: 14px;
  min-height: 20px;
  margin-bottom: 0;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0px;
    width: 0px;
  }
  .checkmark {
    position: absolute;
    left: 0;
    top: 0px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #dcdcdc;
  }
  input:checked ~ .checkmark {
    background: #5581e4;
    border-color: #5581e4;
  }
  input:checked ~ .checkmark:after {
    display: block;
    content: '';
    cursor: pointer;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &.agree {
    display: inline-block;
    margin: 0 auto;
    span {
      line-height: 1.3;
    }
    .privacy {
      padding-left: 5px;
    }
    @media screen and (max-width: 750px) {
      text-align: center;
    }
    & > .checkmark {
      @media screen and (max-width: 750px) {
        margin-left: 0px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding-left: 20px;
  }
  :nth-of-type(2),
  :nth-of-type(5) {
    margin-left: 15px;
    @media screen and (max-width: 750px) {
      margin-left: 0px;
    }
  }
`;
