import React from 'react';
import { Inner, MainUL } from '../style';
import { NavLink } from 'react-router-dom';

const NewsIssueMain = () => {
  return (
    <Inner>
      <div className="main_content">
        <div className="title">
          <h2>보안이슈</h2>
          <p>기원테크와 시큐이클라우드의 새로운 소식을 알려드립니다. </p>
          <MainUL>
            <li>
              <NavLink to="/news/news">뉴스</NavLink>
            </li>
            <li>
              <NavLink to="/news/securityissue">보안이슈</NavLink>
            </li>
            <li>
              <NavLink to="/news/newsletter">뉴스레터</NavLink>
            </li>
          </MainUL>
        </div>
        <div className="main_visual"></div>
      </div>
    </Inner>
  );
};

export default NewsIssueMain;
