import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DownloadWrapper, InputWrapper, CheckBox, ContentWrapper } from './style';
import axios from 'axios';
import Modal from '../modal';
import DownLoadConfirm from '../confirm/DownLoadConfirm';

const Download = ({ setDownload, setDownSucc }) => {
  const useRefs = useRef([]);
  const validNum = /^[0-9]+$/;
  const emailForm =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const [proGubun, setProGubun] = useState([]);
  const initialVal = {
    corName: '',
    proGubun: proGubun,
    qSize: '',
    mng_name: '',
    manage_contact: '',
    manage_email: '',
    formType: 'down',
  };
  const [values, setValues] = useState(initialVal);
  const [loading, setLoading] = useState(false);
  const [agreeChk, setAgreeChk] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const pbHandler = (checked, id) => {
    if (checked) {
      setProGubun([...proGubun, id]);
    } else {
      setProGubun(proGubun.filter((el) => el !== id));
    }
  };
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (proGubun.length === 0) {
        alert('신청자료를 선택해주세요.');
        return false;
      }
      for (let i = 0; i < useRefs.current.length; i++) {
        if (useRefs.current[i].value === '' || !useRefs.current[i].value.trim()) {
          alert(useRefs.current[i].title + '을(를) 입력해주세요');
          useRefs.current[i].focus();
          return false;
        }
      }
      if (!emailForm.test(values.manage_email)) {
        alert('이메일 양식을 확인해주세요');
        return false;
        useRefs[7]?.current.focus();
      }
      if (agreeChk === false) {
        alert('개인정보 수집 및 이용을 동의해주세요.');
        return false;
      }

      let payload = {
        ...values,
        qSize: +values.qSize,
        privacy_chk: 'Y',
        formType: 'down',
      };

      for (let i = 0; i < proGubun.length; i++) {
        payload['proGubun[' + i + '].product_target'] = proGubun[i];
      }

      // 전송 로직
      try {
        setLoading(true);
        axios
          .post('/save', payload, {
            headers: {
              'Content-Type': `application/x-www-form-urlencoded`,
            },
          })
          .then((res) => {
            setDownSucc(true);
            setValues({ ...initialVal, proGubun: [] });
            setDownload(false);
          });
      } catch (error) {
        alert('오류가 발생했습니다. 관리자에게 연락 부탁드립니다.');
      } finally {
        setLoading(false);
      }
    },
    [values, proGubun, agreeChk],
  );
  return (
    <DownloadWrapper onSubmit={onSubmit}>
      <h3>
        다운로드 신청
        <span>자료 다운로드를 위해 신청서를 작성해주세요.</span>
      </h3>
      <InputWrapper>
        <div className="flexItem">
          <label className="documentType">
            <span>신청자료</span>
          </label>
          <div className="checkboxWrapper">
            <CheckBox>
              <input
                type="checkbox"
                name="SC_P"
                id="SC_P"
                onChange={(e) => {
                  pbHandler(e.currentTarget.checked, 'SC_P');
                }}
                checked={proGubun.includes('SC_P')}
              />
              <span className="checkmark"></span>
              <span className="trn" data-trn-key="TR_IDSAVE">
                시큐이클라우드 제안서
              </span>
            </CheckBox>
            <CheckBox>
              <input
                type="checkbox"
                name="EG_P"
                id="EG_P"
                onChange={(e) => {
                  pbHandler(e.currentTarget.checked, 'EG_P');
                }}
                checked={proGubun.includes('EG_P')}
              />
              <span className="checkmark"></span>
              <span className="trn" data-trn-key="TR_IDSAVE">
                이지플랫폼 제안서
              </span>
            </CheckBox>
            <CheckBox>
              <input
                type="checkbox"
                name="KI_P"
                id="KI_P"
                onChange={(e) => {
                  pbHandler(e.currentTarget.checked, 'KI_P');
                }}
                checked={proGubun.includes('KI_P')}
              />
              <span className="checkmark"></span>
              <span className="trn" data-trn-key="TR_IDSAVE">
                기원테크 회사소개서
              </span>
            </CheckBox>
            <CheckBox>
              <input
                type="checkbox"
                name="SC_B"
                id="SC_B"
                onChange={(e) => {
                  pbHandler(e.currentTarget.checked, 'SC_B');
                }}
                checked={proGubun.includes('SC_B')}
              />
              <span className="checkmark"></span>
              <span className="trn" data-trn-key="TR_IDSAVE">
                시큐이클라우드 브로슈어
              </span>
            </CheckBox>
            <CheckBox>
              <input
                type="checkbox"
                name="EG_B"
                id="EG_B"
                onChange={(e) => {
                  pbHandler(e.currentTarget.checked, 'EG_B');
                }}
                checked={proGubun.includes('EG_B')}
              />
              <span className="checkmark"></span>
              <span className="trn" data-trn-key="TR_IDSAVE">
                이지플랫폼 브로슈어
              </span>
            </CheckBox>
          </div>
        </div>
        <div className="flexItem">
          <label className="company">
            <span>기업명</span>
            <input
              type="text"
              name="corName"
              title="기업명"
              ref={(el) => (useRefs.current[0] = el)}
              onChange={handleChange}
              value={values.corName}
            />
          </label>
          <label className="unit">
            <span className="title">직원 수</span>
            <input
              type="text"
              name="qSize"
              title="직원 수"
              ref={(el) => (useRefs.current[1] = el)}
              onChange={handleChange}
              value={values.qSize.replace(/[^0-9]/g, '')}
            />
            <span>명</span>
          </label>
        </div>
        <div className="flexItem">
          <label className="name">
            <span>이름/직책</span>
            <input
              type="text"
              name="mng_name"
              title="이름/직책"
              ref={(el) => (useRefs.current[2] = el)}
              value={values.mng_name}
              onChange={handleChange}
            />
          </label>
          <label className="phone">
            <span>연락처</span>
            <input
              type="text"
              name="manage_contact"
              title="연락처"
              ref={(el) => (useRefs.current[3] = el)}
              value={values.manage_contact.replace(/[^0-9]/g, '')}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="flexItem">
          <label className="email">
            <span>이메일 주소</span>
            <input
              type="text"
              name="manage_email"
              title="이메일 주소"
              ref={(el) => (useRefs.current[4] = el)}
              value={values.manage_email}
              onChange={handleChange}
            />
          </label>
        </div>
      </InputWrapper>
      <div className="agreeWrapper">
        <CheckBox className="agree">
          <input
            type="checkbox"
            name="agreeChk"
            onChange={(e) => {
              setAgreeChk((prev) => !prev);
            }}
            checked={agreeChk}
          />
          <span className="checkmark"></span>
          <span className="privacy">개인정보 수집 및 이용에 동의합니다. &nbsp;</span>
        </CheckBox>
        <a
          onClick={() => {
            window.open(
              '/popup/TermPersonal',
              'window',
              'width=500, height=700, location=no, status=no, scrollbars=yes',
            );
          }}
        >
          [개인정보취급방침 보기]
        </a>
        <button>신청하기</button>
      </div>
    </DownloadWrapper>
  );
};

export default Download;
