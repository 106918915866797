import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Section } from './style';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NewsItem from './items/NewsItem';
import SecurityItem from './items/SecurityItem';
import NewsletterItem from './items/NewsletterItem';
import DetailPage from './detailpage/DetailPage';
import DetailPageNL from './detailpage/DetailPageNL';
import NewsIssueMain from './MainTitle/NewsIssueMain';
import NewsIssueItem from './items/NewsIssueItem';

const News = () => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <Section onClick={() => setShowOptions(false)}>
      <div className="tab_content">
        <Routes>
          <Route
            path="/news"
            element={<NewsItem setShowOptions={setShowOptions} showOptions={showOptions} />}
          />
          <Route path="/news/detail/:id" element={<DetailPage />} />
          <Route
            path="/securityissue"
            element={<NewsIssueItem setShowOptions={setShowOptions} showOptions={showOptions} />}
          />
          <Route path="/securityissue/detail/:id" element={<DetailPage />} />
          <Route path="/newsletter" element={<NewsletterItem />} />
          <Route path="/newsletter/detail/:id" element={<DetailPageNL />} />
        </Routes>
      </div>
    </Section>
  );
};

export default News;
