import React, { useEffect, useRef, useState } from 'react';

const LazyImage = ({ src }) => {
  const [isLoading, setIsLoading] = useState(false);

  const imgRef = useRef(null);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(intersectionOberserver);
    imgRef.current && observer.current.observe(imgRef.current);
  }, []);

  const intersectionOberserver = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        io.unobserve(entry.target);
        setIsLoading(true);
      }
    });
  };

  return <img ref={imgRef} src={isLoading ? src : '/images/img-lazeImg.jpg'} />;
};

export default LazyImage;
