import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Form,
  Label,
  LabelBox,
  LabelIcon,
  Option,
  SearchBox,
  SearchForm,
  SelectOption,
  Test,
} from '../style';
import { RiArrowDownSFill } from 'react-icons/ri';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';

const SearchBar = ({ showOptions, setShowOptions }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchGubun, setSearchGubun] = useState('');
  const [currentGubun, setCurrnetGubun] = useState('전체');
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleToggleOption = useCallback(
    (e) => {
      e.stopPropagation();
      setShowOptions((prev) => !prev);
    },
    [showOptions],
  );

  const optionRef = useRef();

  const handleOnChangeSelectValue = (text, value) => {
    setCurrnetGubun(text);
    setSearchGubun(value);
  };

  const OptionList = [
    { text: '전체', value: '' },
    { text: '제목', value: 'title' },
    { text: '내용', value: 'content' },
  ];

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      searchParams.set('pageNo', 1);
      searchGubun && searchKeyword
        ? searchParams.set('searchGubun', searchGubun)
        : searchParams.delete('searchGubun');
      searchKeyword
        ? searchParams.set('searchKeyword', searchKeyword)
        : searchParams.delete('searchKeyword');
      setSearchParams(searchParams);
    },
    [searchParams, searchGubun, searchKeyword, setSearchParams],
  );

  const onChangeText = useCallback(
    (e) => {
      setSearchKeyword(e.target.value);
    },
    [setSearchKeyword],
  );

  return (
    <SearchBox>
      <SearchForm onClick={handleToggleOption}>
        <LabelBox>
          <Label>{currentGubun}</Label>
          <LabelIcon>
            <RiArrowDownSFill />
          </LabelIcon>
        </LabelBox>

        <SelectOption show={showOptions} ref={optionRef}>
          {OptionList.map((it) => (
            <Option
              key={it.text}
              onClick={() => {
                handleOnChangeSelectValue(it.text, it.value);
              }}
            >
              {it.text}
            </Option>
          ))}
        </SelectOption>
      </SearchForm>

      <Form onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="검색어를 입력하세요."
          value={searchKeyword}
          onChange={(e) => {
            onChangeText(e);
          }}
        />
        <button type="submit">
          <AiOutlineSearch className="btn" />
        </button>
      </Form>
    </SearchBox>
  );
};

export default SearchBar;
