import React, { useState, useCallback, useEffect, useRef } from 'react';
import { MaxWidth, Section } from 'GlobalStyle';
import {
  Main01,
  Main02,
  Main02Item,
  Wrapper,
  Main03,
  TabSection,
  TabWrapper,
  ContentWrapper,
  Content,
  SubSection,
} from './style';
import { tabs, contents } from './tabs.js';

const Mail = () => {
  const [tabKey, setTabKey] = useState(1);
  const prevBtnUrl = tabKey === 1 ? '/images/icon/ico-prev-first.png' : '/images/icon/ico-prev.png';
  const nextBtnUrl = tabKey === 8 ? '/images/icon/ico-next-last.png' : '/images/icon/ico-next.png';
  const [ScrollActive, setScrollActive] = useState(false);
  const [position, setPosition] = useState(0);

  const selectTab = useCallback(
    (key) => {
      setTabKey(key);
    },
    [tabKey],
  );
  const moveToPrev = useCallback(
    (idx) => {
      if (--idx > 0) {
        setTabKey(idx);
      }
    },
    [tabKey],
  );
  const moveToNext = useCallback(
    (idx) => {
      if (++idx <= tabs.length) {
        setTabKey(idx);
      }
    },
    [tabKey],
  );

  const onScroll = () => {
    setPosition(window.scrollY);
    if (position > 1470) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [position]);

  return (
    <>
      <Main01>
        <div>
          <div className="main01_wrap">
            <p>스마트하고 안전한 All-in-One</p>
            <h1>보안메일 서비스</h1>
            <p className="intro">
              <span>이메일 보안 전문기업이 만든 세상에서 가장 안전한 </span>
              <span>SECU E Cloud 메일은 사용자를 위한 다양한 편의 기능을 제공합니다. </span>
              <span>메일을 보다 스마트하고 안전하게 사용하세요.</span>
            </p>
          </div>
        </div>
      </Main01>
      <Main02>
        <MaxWidth>
          <h2>
            <div>
              왜 <span>SECU E Cloud </span>
            </div>
            <div>메일을 사용해야 하나요?</div>
          </h2>
          <Wrapper>
            <Main02Item>
              <div>
                <h5>
                  <span>해커로부터 </span>
                  <span>안전하고 강력한 보안</span>
                </h5>
                <p>
                  이메일 보안 전문가, 기원테크가 선사하는 강력한 메일 보안은 고도화되고 있는 메일
                  공격으로부터 기업의 자산을 안전하게 보호합니다.
                </p>
              </div>
            </Main02Item>
            <Main02Item>
              <div>
                <h5>
                  <span>업무 효율을 높이는 </span>
                  <span>다양한 메일 기능</span>
                </h5>
                <p>
                  메일 승인/반려 시스템, 업무 관련 메일 분류 등, SECU E Cloud가 제공하는 다양한
                  기능을 통해 업무 효율과 생산성을 동시에 향상시키세요.
                </p>
              </div>
            </Main02Item>
            <Main02Item>
              <div>
                <h5>
                  <span>간단하고 효과적인 </span>
                  <span>브랜드 이미지 구축</span>
                </h5>
                <p>
                  기업 도메인(@egplatforms.com)을 사용하는 SECU E Cloud 메일은 고객에게 전문성과
                  신뢰를 줄 수 있는 가장 효과적인 방법입니다.
                </p>
              </div>
            </Main02Item>
          </Wrapper>
        </MaxWidth>
      </Main02>
      <Main03>
        <MaxWidth>
          <div className="tab-menu">
            <h2>
              SECU E Cloud <span>메일 주요 기능</span>
            </h2>
            <ul className={`inner mail ${ScrollActive ? 'fixed' : ''}`}>
              {tabs.map((el) => (
                <li key={el.key}>
                  <button
                    className={`tab ${tabKey === el.key ? 'active' : ''}`}
                    onClick={() => {
                      selectTab(el.key);
                    }}
                  >
                    {el.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </MaxWidth>
        <TabSection>
          <MaxWidth>
            <h3>
              {tabs[tabKey - 1].title} <span>{tabs[tabKey - 1].bold}</span>
            </h3>
            <TabWrapper>
              <div className="tab-content">
                <button className="prev" onClick={() => moveToPrev(tabKey)}>
                  <img src={prevBtnUrl} />
                </button>
                <img src={tabs[tabKey - 1].url} className="main_img" />
                <button className="next" onClick={() => moveToNext(tabKey)}>
                  <img src={nextBtnUrl} />
                </button>
              </div>
              <ContentWrapper>
                {contents[tabKey].map((el) => (
                  <Content key={el.title} className={contents[tabKey].length}>
                    <h5>{el.num}</h5>
                    <div className="title">{el.title}</div>
                    <p>
                      <span key={el.content}>{el.content}</span>
                    </p>
                  </Content>
                ))}
              </ContentWrapper>
            </TabWrapper>
          </MaxWidth>
        </TabSection>
      </Main03>
    </>
  );
};

export default Mail;
