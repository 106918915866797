import styled from 'styled-components';

export const Content = styled.li`
  position: relative;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(104, 124, 165, 0.15);
  padding: 20px 25px;
  cursor: pointer;
  box-sizing: border-box;
  @media screen and (max-width: 480px) {
    padding: 15px;
  }
`;

export const Question = styled.div`
  display: flex;
  position: relative;
  & > div {
    padding-right: 30px;
    display: flex;
    @media screen and (max-width: 680px) {
      display: block;
      padding-right: 20px;
    }
  }
  & > span {
    font-size: 30px;
    font-family: 'SBAggrolL';
    margin-right: 20px;
    font-weight: 600;
    color: #4d81db;
    @media screen and (max-width: 920px) {
      font-size: 26px;
      margin-right: 15px;
    }
    @media screen and (max-width: 480px) {
      margin-right: 15px;
      font-size: 24px;
    }
  }
  .category {
    font-size: 18px;
    font-family: 'Pretendard';
    display: inline-block;
    font-weight: 500;
    color: #4d81db;
    margin-right: 50px;
    line-height: 1.6;
    width: 60px;
    letter-spacing: -0.01em;
    @media screen and (max-width: 920px) {
      margin-right: 25px;
    }
    @media screen and (max-width: 680px) {
      font-size: 15px;
      width: 100%;
    }
  }
  .title {
    font-size: 18px;
    font-family: 'Pretendard';
    font-weight: 500;
    color: #3b3b3b;
    line-height: 1.6;
    display: inline-block;
    letter-spacing: -0.01em;
    width: calc(100% - 60px);
    @media screen and (max-width: 920px) {
      font-size: 17px;
      padding-right: 30px;
    }
    @media screen and (max-width: 680px) {
      font-size: 17px;
      display: block;
      width: 100%;
    }
  }
  i {
    position: absolute;
    background-repeat: no-repeat;
    background-image: url('/images/icon/ico-cs_dropdownArrow01.png');
    width: 16px;
    height: 12px;
    top: 50%;
    right: 0;
    transform: translate(0, -25%);
    &.active {
      background-image: url('/images/icon/ico-cs_dropdownArrow02.png');
      @media screen and (max-width: 480px) {
        background-image: url('/images/icon/ico-cs_dropdownArrow02_small.png');
      }
    }
    @media screen and (max-width: 480px) {
      background-image: url('/images/icon/ico-cs_dropdownArrow01_small.png');
    }
  }
  @media screen and (max-width: 680px) {
    align-items: center;
  }
`;

export const Answer = styled.div`
  border-top: 1px solid #f0f2f5;
  display: flex;
  margin-top: 20px;
  padding-top: 25px;
  & > span {
    font-size: 30px;
    font-family: 'SBAggrolL';
    font-weight: 600;
    color: #4d81db;
    margin-right: 20px;
    @media screen and (max-width: 920px) {
      margin-right: 15px;
      font-size: 26px;
    }
    @media screen and (max-width: 480px) {
      font-size: 24px;
    }
  }
  .contents {
    //margin-left: 20px;
    font-size: 16px;
    font-family: 'Pretendard';
    color: #4b4b4b;
    line-height: 2;
    font-weight: 400;
    * {
      font-family: 'Pretendard' !important;
    }
  }
  @media screen and (max-width: 480px) {
    padding-top: 20px;
  }
`;
