import styled from 'styled-components';

export const ServiceInfo = styled.article`
  padding: 100px 0 140px;
  background-color: #4d81db;
  box-sizing: border-box;
  background-image: url('/images/background/bg-support02_circle.png');
  background-repeat: no-repeat;
  background-position: left bottom;

  @media screen and (max-width: 1200px) {
    padding: 100px 100px;
  }
  @media screen and (max-width: 630px) {
    padding: 70px 0;
  }
  h2 {
    font-family: SBAggrolL;
    font-size: 52px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin-bottom: 65px;
    line-height: 1.6;
    & > span {
      font-family: SBAggrolL;
      font-size: 42px;
      color: #ccf7ff;
      @media screen and (max-width: 1200px) {
        font-size: 30px;
        display: block;
      }
      @media screen and (max-width: 480px) {
        font-size: 24px;
      }
    }
    @media screen and (max-width: 1200px) {
      margin-bottom: 40px;
      font-size: 38px;
    }
    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
  }
`;
export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export const ServiceInfoItem = styled.div`
  height: 345px;
  background-color: #ffffff;
  border-radius: 40px;
  padding: 75px 60px;
  box-sizing: border-box;
  width: calc((100% / 3) - 1%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    text-align: center;
    line-height: 1.6;
    margin-bottom: 30px;
    letter-spacing: -0.05em;

    span {
      font-family: SBAggrolL;
      font-size: 30px;
      font-weight: 500;
      color: #494949;
      @media screen and (max-width: 750px) {
        font-size: 22px;
      }
    }
    & > .describe {
      display: block;
      font-size: 20px;
      letter-spacing: -0.05em;
      @media screen and (max-width: 750px) {
        font-size: 16px;
      }
    }
  }
  & > button {
    position: relative;
    display: block;
    padding: 0 25px;
    width: 100%;
    height: 75px;
    border-radius: 40px;
    border: 4px solid #4d81db;
    font-size: 24px;
    font-family: SBAggrolL;
    font-weight: 500;
    color: #4d81db;
    box-sizing: border-box;
    letter-spacing: -0.025em;
    &:before {
      content: '';
      border: 4px solid #ccf7ff;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      border-radius: 40px;
    }
    &:after {
      content: '';
      display: inline-block;
      background-image: url('/images/icon/ico-support02_arrow01.png');
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      width: 50px;
      top: 50%;
      bottom: 0;
      transform: translate(0, -25%);
      @media screen and (max-width: 375px) {
        right: -10px;
      }
    }
    & > span {
      display: inline-block;
      font-size: 24px;
      font-family: SBAggrolL;
      font-weight: 500;
      color: #4d81db;
      line-height: 3;

      margin-left: -30px;
      @media screen and (max-width: 750px) {
        font-size: 20px;
      }
    }
    @media screen and (max-width: 1380px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    @media screen and (max-width: 750px) {
      height: 60px;
    }
  }
  &:nth-of-type(2) {
    padding: 65px 35px;
    box-sizing: border-box;
    display: block;
    h5 {
      text-align: center;
      line-height: 1.6;
      margin-bottom: 20px;
      span {
        font-family: SBAggrolL;
        font-size: 22px;
        font-weight: 500;
        color: #494949;
        @media screen and (max-width: 750px) {
          font-size: 18px;
        }
      }
      @media screen and (max-width: 750px) {
        margin-bottom: 10px;
      }
    }
    & > ul {
      display: flex;
      flex-direction: column;
      height: calc(100% - 65px);
      row-gap: 10px;
      li {
        position: relative;
        padding: 0 40px 0 50px;
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        line-height: 45px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 0px 20px 0px rgba(117, 117, 117, 0.14);
        font-family: SBAggrolL;
        font-size: 20px;
        font-weight: 500;
        color: #4d81db;
        span {
          font-family: SBAggrolL;
          font-size: 20px;
          font-weight: 600;
          line-height: 45px;
          letter-spacing: -0.025em;
          &.unit {
            color: #656565;
            font-weight: 500;
          }
          @media screen and (max-width: 750px) {
            font-size: 16px;
          }
        }
        &:before {
          content: '\\2022';
          color: #4d81db;
          font-weight: bold;
          display: inline-block;
          width: 1px;
          position: absolute;
          top: 0;
          left: 30px;
          @media screen and (max-width: 1380px) {
            left: 10px;
          }
          @media screen and (max-width: 375px) {
            display: none;
          }
        }
        @media screen and (max-width: 1380px) {
          padding: 0 25px;
        }
        @media screen and (max-width: 750px) {
          font-size: 16px;
        }
        @media screen and (max-width: 375px) {
          padding: 0 15px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      padding: 30px 80px;
    }
    @media screen and (max-width: 750px) {
      padding: 25px;
      height: 250px;
    }
    @media screen and (max-width: 375px) {
      padding: 20px;
    }
  }
  &:nth-of-type(3) {
    padding-top: 85px;
    h5 {
      span {
        font-size: 22px;
      }
      & > .describe {
        display: block;
        font-size: 20px;
        @media screen and (max-width: 750px) {
          font-size: 16px;
        }
      }
      @media screen and (max-width: 750px) {
        font-size: 22px;
      }
    }
    & > button {
      border: 4px solid #ccf7ff;
      background-color: #4d81db;
      &:before {
        display: none;
      }
      &:after {
        background-image: url('/images/icon/ico-support02_arrow02.png');
      }
      & > span {
        display: inline-block;
        font-size: 24px;
        font-family: SBAggrolL;
        font-weight: 500;
        color: #ffffff;
        line-height: 3;
        @media screen and (max-width: 750px) {
          font-size: 20px;
        }
      }
      @media screen and (max-width: 1380px) {
        padding: 0 10px;
      }
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
      @media screen and (max-width: 750px) {
        height: 60px;
      }
    }
    @media screen and (max-width: 1200px) {
      padding: 55px 95px;
    }
    @media screen and (max-width: 750px) {
      height: 250px;
      padding: 45px;
    }
    @media screen and (max-width: 375px) {
      padding: 20px;
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    padding: 55px 75px;
    height: 290px;
  }
  @media screen and (max-width: 750px) {
    height: 260px;
    padding: 45px;
  }
  @media screen and (max-width: 480px) {
    height: 245px;
  }
  @media screen and (max-width: 375px) {
    padding: 25px;
    height: 225px;
  }
`;

export const ServiceContact = styled.article`
  padding: 140px 0px 120px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 1.5;
    text-align: center;
    font-weight: 500;
    font-family: SBAggrolL;
    color: #3f3f3f;
    font-size: 38px;
    margin-bottom: 65px;
    @media screen and (max-width: 1200px) {
      font-size: 32px;
    }
    @media screen and (max-width: 750px) {
      font-size: 24px;
    }
    .bold {
      font-weight: 600;
      font-family: SBAggrolL;
      font-size: 38px;
      color: #4d81db;
      @media screen and (max-width: 1200px) {
        font-size: 32px;
      }
      @media screen and (max-width: 750px) {
        font-size: 24px;
      }
    }
    .block {
      display: block;
      font-weight: 500;
      font-family: SBAggrolL;
      @media screen and (max-width: 750px) {
        display: inline;
      }
    }
    @media screen and (max-width: 1200px) {
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 90px 40px;
  }
  @media screen and (max-width: 780px) {
    padding: 75px 0px;
  }
`;

export const ServiceContactWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export const ServiceContactItem = styled.div`
  width: calc((100% / 2) - 1%);
  background-color: #ffffff;
  //height: 315px;
  box-shadow: 0px 0px 18px 0px rgba(141, 141, 141, 0.15);
  border-radius: 15px;
  padding: 85px 50px 85px 230px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url('/images/icon/ico-support03_phone.png');
  background-position: center left 65px;
  @media screen and (max-width: 1380px) {
    background-position: center left 25px;
    padding: 85px 50px 85px 165px;
  }
  @media screen and (max-width: 1200px) {
    padding: 85px 50px 85px 220px;
    background-position: center left 35px;
    width: 100%;
  }
  @media screen and (max-width: 780px) {
    padding: 60px;
    background-position: top 35px right 35px;
    background-size: 75px 75px;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 30px;
    background-position: top 25px right 25px;
    background-size: 60px 60px;
  }
  @media screen and (max-width: 375px) {
    padding: 40px 15px;
    background-position: top 10px right 10px;
  }
  & > ul {
    & > li {
      position: relative;
      font-family: SBAggrolL;
      font-size: 24px;
      color: #959595;
      font-weight: 500;
      line-height: 1.5;
      &:before {
        content: '\\2022';
        color: #959595;
        font-weight: bold;
        display: inline-block;
        width: 1em;
      }
      & > span {
        display: block;
        font-family: Pretendard;
        font-size: 24px;
        color: #959595;
        font-weight: 600;
        margin-left: 20px;
        @media screen and (max-width: 550px) {
          font-size: 20px;
        }
        @media screen and (max-width: 375px) {
          font-size: 18px;
        }
      }
      .phoneNum {
        position: relative;
        width: fit-content;
        font-family: Pretendard;
        font-size: 44px;
        color: #3b3b3b;
        font-weight: 600;
        z-index: 1;
        margin-left: 20px;
        &:before {
          display: none;
        }
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 40%;
          background-color: #eef5ff;
          top: 40%;
          left: 0px;
          z-index: -1;
        }
        @media screen and (max-width: 550px) {
          font-size: 26px;
        }
        @media screen and (max-width: 375px) {
          font-size: 24px;
        }
      }
      @media screen and (max-width: 550px) {
        font-size: 20px;
      }
    }
  }
  &:nth-of-type(2) {
    background-image: url('/images/icon/ico-support03_mail.png');
    & > ul {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      & > li {
        line-height: 1.3;
        & > span {
          &.mail {
            position: relative;
            width: fit-content;
            font-family: Pretendard;
            font-size: 32px;
            color: #3b3b3b;
            font-weight: 600;
            z-index: 1;
            &:before {
              display: none;
            }
            &:after {
              content: '';
              display: inline-block;
              position: absolute;
              width: 100%;
              height: 50%;
              background-color: #eef5ff;
              top: 40%;
              left: 0px;
              z-index: -1;
            }
            @media screen and (max-width: 550px) {
              font-size: 22px;
            }
            @media screen and (max-width: 375px) {
              font-size: 20px;
            }
            @media screen and (max-width: 330px) {
              word-break: break-all;
            }
          }
        }
      }
      @media screen and (max-width: 780px) {
        row-gap: 20px;
      }
    }
    @media screen and (max-width: 780px) {
      padding: 65px 60px;
      background-position: top 35px right 35px;
      background-size: 75px 75px;
    }
    @media screen and (max-width: 480px) {
      padding: 65px 30px;
      background-position: top 25px right 25px;
      background-size: 60px 60px;
    }
    @media screen and (max-width: 375px) {
      padding: 40px 15px;
      background-position: top 10px right 10px;
    }
  }
`;

export const OnlineRequest = styled.article`
  padding: 100px 0 125px;
  background-color: #f4f4f9;
  & > div {
    //padding: 0 60px;
    display: flex;
    justify-content: center;
    // justify-content: space-evenly;

    h3 {
      position: relative;
      margin-top: 35px;
      margin-right: 145px;
      font-weight: 600;
      font-family: SBAggrolL;
      color: #4d81db;
      font-size: 26px;
      line-height: 1.5;
      margin-bottom: 35px;
      & > span {
        display: block;
        font-size: 38px;
        color: #4a4a4a;
        font-weight: 500;
        font-family: SBAggrolL;
        @media screen and (max-width: 1200px) {
          font-size: 32px;
        }
        @media screen and (max-width: 750px) {
          font-size: 28px;
        }
      }
      .line {
        color: #989898;
        font-weight: 400;
        margin-bottom: 20px;
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
      p {
        line-height: 1.5;
        letter-spacing: -0.025em;
        & > span {
          display: block;
          font-weight: 500;
          color: #989898;
          font-size: 18px;
          @media screen and (max-width: 1200px) {
            display: inline;
            font-size: 16px;
          }
        }
        @media screen and (max-width: 1200px) {
          width: 70%;
          margin: 0px auto 30px;
          line-height: 1;
        }
      }
      &:after {
        content: '';
        display: inline-block;
        background-image: url('/images/background/bg-support03_note01.png');
        background-repeat: no-repeat;
        position: absolute;
        width: 440px;
        height: 340px;
        left: 0px;
        bottom: 35px;
        z-index: 3;
        @media screen and (max-width: 1200px) {
          display: inline-block;
          background-image: url('/images/background/bg-support03_note02.png');
          width: 202px;
          height: 167px;
          bottom: -15px;
          left: 85%;
        }
        @media screen and (max-width: 750px) {
          display: inline-block;
          background-image: url('/images/background/bg-support03_note02.png');
          background-size: 80px 68px;
          width: 80px;
          height: 68px;
          bottom: -10%;
          left: 80%;
        }
        @media screen and (max-width: 380px) {
          left: 70%;
        }
      }
      @media screen and (max-width: 1200px) {
        margin: 0 auto;
        text-align: center;
        font-size: 28px;
      }
      @media screen and (max-width: 750px) {
        font-size: 22px;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 90px 0px 40px;
  }
  @media screen and (max-width: 750px) {
    padding: 85px 0 180px;
  }
`;
