export const tabs = [
  {
    key: 1,
    name: '보안메일',
    title: '이미 발송한 메일을 회수할 수 있는',
    bold: '‘보안메일’',
    url: '/images/img-mail02-tab01.png',
  },
  {
    key: 2,
    name: '승인메일',
    title: '정보 유출을 방지하는 체계적인',
    bold: '‘승인메일’',
    url: '/images/img-mail02-tab02.png',
  },
  {
    key: 3,
    name: '업무형메일',
    title: '사내 프로젝트 메일의 체계적인 관리',
    bold: '‘업무형메일’',
    url: '/images/img-mail02-tab03.png',
  },
  {
    key: 4,
    name: '공유메일',
    title: '효과적인 업무 메일 공유',
    bold: '‘공유메일’',
    url: '/images/img-mail02-tab04.png',
  },
  {
    key: 5,
    name: '공용메일',
    title: '팀원과 공유하고 싶은 메일만 공유하는',
    bold: '‘공용메일’',
    url: '/images/img-mail02-tab05.png',
  },
  {
    key: 6,
    name: '그룹메일',
    title: '대표메일 주소로 수신부터 발신까지',
    bold: '‘그룹메일’',
    url: '/images/img-mail02-tab06.png',
  },
  {
    key: 7,
    name: '대화형메일',
    title: '상대방과의 수·발신 메일은 한눈에',
    bold: '‘대화형메일’',
    url: '/images/img-mail02-tab07.png',
  },
  {
    key: 8,
    name: '첨부파일 보관함',
    title: '수·발신 메일의 첨부파일을 한눈에',
    bold: '‘첨부파일 보관함’',
    url: '/images/img-mail02-tab08.png',
  },
];

export const contents = {
  1: [
    {
      num: '01',
      title: '비밀번호 설정',
      content:
        '중요한 문서나 정보를 메일로 발송할 때, 비밀번호를 설정하여 정보를 안전하게 보호할 수 있습니다.',
    },
    {
      num: '02',
      title: '수신자 메일 열람 제한',
      content:
        '메일을 잘못 보낸 경우, 수신자의 메일 열람을 제한하여 오발송 사고에 대응할 수 있습니다.',
    },
    {
      num: '03',
      title: '메일 열람 확인',
      content:
        '수신자의 메일 열람 여부를 확인할 수 있어 대외 협업 프로젝트를 원활히 진행 할 수 있습니다.',
    },
  ],
  2: [
    {
      num: '01',
      title: '정보 유출 방지',
      content: `제목, 본문, 첨부파일에 ‘승인문구’ 포함 시 승인 절차를 거쳐 메일 발송, 정보 유출 시도를 차단 할 수 있습니다.`,
    },
    {
      num: '02',
      title: '발신메일 관리·감독',
      content: '팀원의 메일을 승인자의 검토 후 발송하여 오발송 사고를 방지할 수 있습니다.',
    },
    {
      num: '03',
      title: '감사기록 제공',
      content:
        '메일 발신자 정보와 승인자 및 승인 처리일 등 감사기록을 제공하여 보다 체계적으로 관리·감독할 수 있습니다.',
    },
  ],
  3: [
    {
      num: '01',
      title: '메일 누락 방지, 체계적인 관리',
      content: `사내 프로젝트 관련 메일을 체계적으로 관리, 여러 대외 업무 메일과 뒤섞여 메일이 누락되는 것을 방지합니다.`,
    },
    {
      num: '02',
      title: '업무 진행 현황 확인',
      content:
        '답변 요청 상태 및 답변 여부 확인 가능, ‘확인/미확인’ 체크 기능으로 메일을 체계적으로 관리합니다.',
    },
  ],
  4: [
    {
      num: '01',
      title: '공유 메일함',
      content:
        '팀원과 수·발신 메일을 모두 공유할 수 있어 중요메일 누락을 방지하여 업무 공유에 용이합니다.',
    },
    {
      num: '02',
      title: '관리자 공유 설정',
      content: '관리자는 전직원의 수·발신 메일을 관리·감독할 수 있습니다.',
    },
  ],
  5: [
    {
      num: '01',
      title: '공용 메일함',
      content:
        '팀원과 공유가 필요한 특정 메일만 공유할 수 있어 긴밀한 업무 협업이 가능하고 메일함 용량을 절약할 수 있습니다.',
    },
    {
      num: '02',
      title: '메일 열람 현황',
      content: '공유한 메일을 누가 열람했는지 파악할 수 있어 업무를 수월하게 수행할 수 있습니다.',
    },
  ],
  6: [
    {
      num: '01',
      title: '그룹메일 주소 메일 발송',
      content:
        '그룹메일은 대표메일로 수신을 받는 역할을 하지만 기원테크의 그룹메일은 ‘메일쓰기’에서 보내는 사람을 그룹메일 주소로 설정하여 발송도 가능합니다.',
    },
    {
      num: '02',
      title: '대표메일 설정',
      content:
        '대표 메일 주소로 메일을 받은 후 수신된 메일은 그룹 내 모든 계정으로 전송되어  모든 직원들이 메일을 확인할 수 있어 업무를 효과적으로 처리 할 수 있습니다.',
    },
  ],
  7: [
    {
      num: '01',
      title: '대화형 메일함',
      content: '특정인과 주고 받은 메일을 대화형으로 한눈에 볼 수 있어 업무 파악에 용이합니다.',
    },
    {
      num: '02',
      title: '하나의 화면구성',
      content: '주고 받은 메일 리스트와 본문 내용을 하나의 화면에서 확인할 수 있습니다.',
    },
    {
      num: '03',
      title: '빠른 확인',
      content: '메일 내용을 화면 넘김 없이 빠르게 확인 할 수 있습니다.',
    },
  ],
  8: [
    {
      num: '01',
      title: '첨부파일 보관함',
      content: '수·발신 메일에 첨부된 파일을 한눈에 확인할 수 있고 문서 찾기에 용이합니다.',
    },
    {
      num: '02',
      title: '발신메일 관리·감독',
      content:
        '파일이 첨부된 메일의 내용을 확인할 수 있고, 첨부파일을 한번의 클릭으로 다운로드할 수 있습니다.',
    },
  ],
};
