import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SearchSection } from '../style';
import { MaxWidth } from '../../../../GlobalStyle';
import { useSearchParams } from 'react-router-dom';

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKeywordVal = searchParams.get('searchKeyword');

  const [selectToggle, setSelectToggle] = useState(false);
  const [val, setVal] = useState(null);
  const [text, setText] = useState('전체');
  const [searchText, setSearchText] = useState('');

  const dropdown = useRef();

  const onClickVal = useCallback(
    (v, t) => {
      setVal(v);
      setText(t);
      setSelectToggle(false);
    },
    [val, text],
  );

  const onClickCategory = useCallback(
    (v) => {
      setVal(v);
      searchParams.set('pageNo', 1);
      v ? searchParams.set('categorySo', v) : searchParams.delete('categorySo');
      searchParams.delete('searchKeyword');
      setSearchParams(searchParams);
    },
    [val, searchParams],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      searchParams.set('pageNo', 1);
      val ? searchParams.set('categorySo', val) : searchParams.delete('categorySo');
      searchText
        ? searchParams.set('searchKeyword', searchText)
        : searchParams.delete('searchKeyword');
      setSearchParams(searchParams);
    },
    [searchParams, val, searchText],
  );

  const dropdownCloseHandler = ({ target }) => {
    if (selectToggle && !dropdown?.current?.contains(target)) {
      setSelectToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', dropdownCloseHandler);
    return () => {
      window.addEventListener('click', dropdownCloseHandler);
    };
  });
  return (
    <SearchSection>
      <MaxWidth>
        <ul>
          <li>
            <button className={val === null ? 'active' : ''} onClick={() => onClickCategory(null)}>
              전체
            </button>
          </li>
          <li>
            <button className={val === 'U' ? 'active' : ''} onClick={() => onClickCategory('U')}>
              이용방법
            </button>
          </li>
          <li>
            <button className={val === 'C' ? 'active' : ''} onClick={() => onClickCategory('C')}>
              제품특징
            </button>
          </li>
        </ul>
        <div className="main02_search_wrapper">
          <div className="select-wrapper">
            <div
              className="selected"
              onClick={() => {
                setSelectToggle((prev) => !prev);
              }}
              ref={dropdown}
            >
              {text}
              <button className={selectToggle ? 'active' : ''} />
            </div>
            <ul className={selectToggle ? 'active' : ''}>
              <li onClick={() => onClickVal(null, '전체')}>전체</li>
              <li onClick={() => onClickVal('U', '이용방법')}>이용방법</li>
              <li onClick={() => onClickVal('C', '제품 특징')}>제품 특징</li>
            </ul>
          </div>
          <form onSubmit={onSubmit}>
            <div className="input-wrapper">
              <input
                placeholder="검색어를 입력하세요."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <button type="submit" />
            </div>
          </form>
        </div>
      </MaxWidth>
    </SearchSection>
  );
};

export default Search;
