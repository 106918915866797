import React from 'react';
import { PricePolicyWrapper, Table, Table400 } from './style';

const PricePolicy = ({}) => {
  return (
    <PricePolicyWrapper>
      <h3>
        서비스 요금 정책
        <span>최종 협의는 파트너사에 문의 부탁드리겠습니다.</span>
      </h3>
      <Table>
        <div className="item title">서비스 신청 종류</div>
        <div className="item title">가격(계정당)</div>
        <div className="item title">최소 신청 단위</div>
        <div className="item service">SECU E Cloud</div>
        <div className="item content">30,000원 / 월</div>
        <div className="item content">20계정</div>
        <div className="item service">EG-Platform Cloud</div>
        <div className="item content">20,000원 / 월</div>
        <div className="item content">40계정</div>
        <div className="item service">악성메일 모의훈련(1회)</div>
        <div className="item content">30,000원 / 회</div>
        <div className="item content">50계정</div>
      </Table>
      <Table400>
        <h5>SECU E Cloud</h5>
        <div className="table">
          <div className="item title">가격(계정당)</div>
          <div className="item title">최소 신청 단위</div>
          <div className="item content">30,000원 / 월</div>
          <div className="item content">20계정</div>
        </div>
        <h5>EG-Platform Cloud</h5>
        <div className="table">
          <div className="item title">가격(계정당)</div>
          <div className="item title">최소 신청 단위</div>
          <div className="item content">20,000원 / 월</div>
          <div className="item content">40계정</div>
        </div>
        <h5>악성메일 모의훈련 (1회)</h5>
        <div className="table">
          <div className="item title">가격(계정당)</div>
          <div className="item title">최소 신청 단위</div>
          <div className="item content">30,000원 / 회</div>
          <div className="item content">50계정</div>
        </div>
      </Table400>
    </PricePolicyWrapper>
  );
};

export default PricePolicy;
