import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  color: '',
};

export const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    changeColor: (state, action) => {
      state.color = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeColor } = footerSlice.actions;

export default footerSlice.reducer;
