import React, { useEffect } from 'react';
import { ModalBody, ModalWrapper } from '../modal/style';
import { DownloadWrapper } from './style';
import { Link } from 'react-router-dom';

const DownLoadConfirm = ({ setDownSucc }) => {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);
  return (
    <ModalWrapper>
      <ModalBody className="downconfirm">
        <DownloadWrapper>
          <div className="dconfirm_content">
            <h2>다운로드 신청 완료</h2>
            <div className="salutation">
              <p>신청이 성공적으로 접수되었습니다.</p>
              <p>감사합니다.</p>
            </div>
            <button
              onClick={() => {
                setDownSucc(false);
              }}
            >
              확인
            </button>
          </div>
        </DownloadWrapper>
      </ModalBody>
    </ModalWrapper>
  );
};

export default DownLoadConfirm;
