import React, { useEffect, useState } from 'react';
import { Container, Contentbox, NewsMaxWidth, NLContentbox, Section } from '../style';
import { tabs, tabsNL } from './tab';
import Pagination from '../../../components/pagination';
import { Link, useSearchParams } from 'react-router-dom';
import NewsMain from '../MainTitle/NewsMain';
import axios from 'axios';
import NewsLetterMain from '../MainTitle/NewsLetterMain';
import trTags from '../../../hooks/useTrTag';

const NewsletterItem = () => {
  const [params, setParams] = useSearchParams();
  const currentPage = params.get('pageNo') || 1;
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [boardVo, setBoardVo] = useState();
  const [pagingInfo, setPagingInfo] = useState();

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(`/board?boardType=L&pageSize=6&pageNo=${currentPage}`);
        setList(response.data.boardList);
        setBoardVo(response.data.BoardVO);
        setPagingInfo(response.data.Paging);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [params]);

  return (
    <NewsMaxWidth>
      <NewsLetterMain />
      <Container className="line">
        {list ? (
          <NLContentbox>
            {list.map((it) => {
              return (
                <li key={it.idx} className="lettercon">
                  <Link
                    to={{
                      pathname: `detail/${it.idx}`,
                    }}
                  >
                    <figure>
                      <div>
                        <img
                          src={`https://back.secuecloud.co.kr/file/imgView?idx=${it.mainImgIdx}`}
                          alt=""
                        />
                      </div>

                      <p>{it.title}</p>
                    </figure>
                  </Link>
                </li>
              );
            })}
          </NLContentbox>
        ) : (
          <p className="noList">리스트가 없습니다.</p>
        )}
        {pagingInfo && <Pagination pagingInfo={pagingInfo} />}
      </Container>
    </NewsMaxWidth>
  );
};

export default NewsletterItem;
