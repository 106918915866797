import React, { useState, useEffect, useCallback } from 'react';
import { PaginationList } from './style';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const Pagination = ({ pagingInfo }) => {
  const [pageParams, setPageParams] = useSearchParams();
  const currentPage = pageParams.get('pageNo');
  const { firstPageNo, finalPageNo, startPageNo, endPageNo, nextPageNo, prevPageNo, pageNo } =
    pagingInfo;
  let pageItem = [];
  for (let i = startPageNo; i <= endPageNo; i++) {
    pageItem.push(i);
  }

  const moveToFirst = useCallback(() => {
    if (currentPage) {
      pageParams.set('pageNo', firstPageNo);
    } else {
      pageParams.append('pageNo', firstPageNo);
    }
    setPageParams(pageParams);
  }, [currentPage, firstPageNo, pageParams, setPageParams]);

  const moveToLast = useCallback(() => {
    if (currentPage) {
      pageParams.set('pageNo', finalPageNo);
    } else {
      pageParams.append('pageNo', finalPageNo);
    }
    setPageParams(pageParams);
  }, [currentPage, finalPageNo, pageParams, setPageParams]);

  const moveToPrev = useCallback(() => {
    if (currentPage > 1) {
      pageParams.set('pageNo', prevPageNo);
    }
    setPageParams(pageParams);
  }, [pageParams, setPageParams, prevPageNo, currentPage]);

  const moveToNext = useCallback(() => {
    if (currentPage < finalPageNo) {
      pageParams.set('pageNo', nextPageNo);
    }
    setPageParams(pageParams);
  }, [pageParams, setPageParams, prevPageNo, currentPage, finalPageNo, nextPageNo]);

  const onClickPage = useCallback(
    (id) => {
      if (currentPage) {
        pageParams.set('pageNo', id);
      } else {
        pageParams.append('pageNo', id);
      }
      setPageParams(pageParams);
    },
    [currentPage, pageParams, setPageParams],
  );
  return (
    pagingInfo && (
      <PaginationList>
        <li>
          <button className="first-page" onClick={moveToFirst}>
            <img src="/images/icon/ico-cs_pagination01.png" />
          </button>
        </li>
        <li>
          <button className="prev-page" onClick={moveToPrev}>
            <img src="/images/icon/ico-cs_pagination02.png" />
          </button>
        </li>
        {pageItem.map((item) => (
          <li key={item}>
            <button
              className={item === +pageNo ? 'page-num active' : 'page-num'}
              onClick={() => onClickPage(item)}
            >
              {item}
            </button>
          </li>
        ))}
        <li>
          <button className="next-page" onClick={moveToNext}>
            <img src="/images/icon/ico-cs_pagination03.png" />
          </button>
        </li>
        <li>
          <button className="last-page" onClick={moveToLast}>
            <img src="/images/icon/ico-cs_pagination04.png" />
          </button>
        </li>
      </PaginationList>
    )
  );
};

export default Pagination;
