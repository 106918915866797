import styled from 'styled-components';

export const ContentSection = styled.article`
  width: 100%;
  .nolist {
    font-size: 20px;
    font-weight: 500;
    padding: 100px 0;
    text-align: center;
    max-width: 1280px;
    margin: 0 auto;
  }
  & > div {
    &:nth-of-type(2) {
      max-width: 1280px;
      & > ul {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-bottom: 75px;
      }
    }
  }
`;

export const SearchSection = styled.div`
  padding: 75px 0 40px;

  & > div {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1280px;
    width: 100%;
    ul {
      display: flex;
      column-gap: 15px;
      //width: 130px;
      width: 100%;
      li {
        //display: inline-block;
        button {
          background-color: #ffffff;
          border-radius: 10px;
          padding: 0px 20px;
          height: 40px;
          box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
          color: #505050;
          font-weight: 500;
          font-size: 16px;
          box-sizing: border-box;
          letter-spacing: -0.025em;
          @media screen and (max-width: 480px) {
            font-size: 15px;
          }
          @media screen and (max-width: 330px) {
            padding: 0px 15px;
          }
        }
        .active {
          background-color: #4d81db;
          color: #ffffff;
          box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
          font-weight: 500;
        }
      }
      @media screen and (max-width: 480px) {
        justify-content: center;
      }
      @media screen and (max-width: 350px) {
        column-gap: 10px;
      }
    }
    @media screen and (max-width: 920px) {
      flex-direction: column-reverse;
      row-gap: 40px;
    }
  }
  .main02_search_wrapper {
    display: flex;
    gap: 15px;
    justify-content: space-between;

    @media screen and (max-width: 920px) {
      justify-content: start;
      width: 100%;

      form {
        width: 70%;
      }
    }
    @media screen and (max-width: 350px) {
      display: flex;
      flex-direction: column;
      gap: 0;

      form {
        width: 100%;
      }
    }
    & > .select-wrapper {
      position: relative;
      width: 130px;
      height: 45px;
      background-color: #f9faff;
      border: none;
      padding: 0px 20px;
      box-sizing: border-box;
      border-radius: 25px;
      color: #9c9ea8;
      font-size: 16px;
      cursor: pointer;
      line-height: 3;

      & > .selected {
        font-family: 'Pretendard';
        font-size: 16px;
        button {
          background-image: url('/images/icon/ico-cs_triangle01.png');
          background-repeat: no-repeat;
          background-position: right center;
          position: absolute;
          left: 0;
          right: 20px;
          top: 0;
          bottom: 0;
          &.active {
            background-image: url('/images/icon/ico-cs_triangle02.png');
          }
          @media screen and (max-width: 480px) {
            right: 15px;
          }
          @media screen and (max-width: 330px) {
            right: 10px;
          }
        }
        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
      & > ul {
        position: absolute;
        display: none;
        width: 100%;
        border: 1px solid #b9b9b9;
        border-radius: 15px;
        left: 0px;
        top: 55px;
        background-color: #ffffff;
        z-index: 10;
        li {
          padding: 0 20px;
          box-sizing: border-box;
          width: 100%;
          letter-spacing: 0.01em;
          &:hover {
            background-color: #f4f4f9;
            &:nth-of-type(1) {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
            }
            &:nth-of-type(3) {
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
            }
          }
          @media screen and (max-width: 480px) {
            font-size: 14px;
            padding: 0 10px;
          }
        }
        &.active {
          display: block;
        }
      }
      @media screen and (max-width: 920px) {
        width: 30%;
      }
      @media screen and (max-width: 350px) {
        width: 150px;
      }
      @media screen and (max-width: 480px) {
        padding: 0px 15px;
      }
    }
    .input-wrapper {
      position: relative;
      @media screen and (max-width: 350px) {
        margin-top: 10px;
      }
      input {
        width: 365px;
        height: 45px;
        background-color: #f9faff;
        border: none;
        padding: 0px 20px;
        box-sizing: border-box;
        letter-spacing: -0.025em;
        border-radius: 25px;
        ::placeholder {
          color: #9c9ea8;
          font-size: 16px;
          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 920px) {
          width: 100%;
        }
        @media screen and (max-width: 480px) {
          padding: 0px 15px;
        }
      }
      button {
        background-image: url('/images/icon/ico-cs_search.png');
        background-repeat: no-repeat;
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 15px;
        bottom: 0;
        width: 20px;
        @media screen and (max-width: 480px) {
          right: 15px;
        }
        @media screen and (max-width: 330px) {
          right: 10px;
        }
      }
      @media screen and (max-width: 920px) {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 920px) {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
  }
`;
