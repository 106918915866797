import React, { useEffect, useState } from 'react';
import { FooterWrap, Top, Bottom } from './style';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = () => {
  const { pathname } = useLocation();
  const [bgGray, setBgGray] = useState(true);
  const color = useSelector((state) => state.footer.color);
  useEffect(() => {
    if (pathname.split('/')[1] === 'news' || color === 'gray') {
      setBgGray(true);
    } else if (pathname === '/') {
      setBgGray(true);
    } else {
      setBgGray(false);
    }
  }, [pathname, color]);

  return (
    <>
      <FooterWrap className={bgGray ? 'bgGray' : ''}>
        <Top>
          <Link to="/">
            <img src="/images/img-logo.png" />
          </Link>
          <div>
            <ul>
              <li>
                <a href="https://www.kiwontech.com/" target="_blank" rel="noreferrer">
                  Kiwontech
                </a>
              </li>
              <li>
                <a href="https://secu-email.com/" target="_blank" rel="noreferrer">
                  EG-Platform
                </a>
              </li>
            </ul>
            <span>FAMILY SITE</span>
          </div>
        </Top>
        <Bottom>
          <span>
            서울 : 08376 서울특별시 구로구 디지털로31길 62 에이스아티스포럼 1001~1004호
            <span className="unit">&nbsp;/&nbsp;</span>
            <span className="dis-b_tb">
              부산 : 부산광역시 부산진구 범일로 176 로얄팰리스 2차 702호
            </span>
          </span>
          <div className="btnWrap">
            <a href="https://www.kiwontech.com/#0" target="_blank" rel="noreferrer">
              회사소개
            </a>
            <Link to="/customerSupport/contactUs" className="link">
              제품문의
            </Link>
            <button
              className="link"
              onClick={() => {
                window.open(
                  '/popup/TermPersonal',
                  'window',
                  'width=500, height=700, location=no, status=no, scrollbars=yes',
                );
              }}
            >
              개인정보처리방침
            </button>
            <button
              className="link"
              onClick={() => {
                window.open(
                  '/popup/TermEmail',
                  'window',
                  'width=500, height=700, location=no, status=no, scrollbars=yes',
                );
              }}
            >
              이메일주소무단수집거부
            </button>
          </div>
        </Bottom>
        <Bottom className="bot">
          <div className="contact_number">
            <span className="phone">Tel : 02.6012.7406</span>
            <span className="phone">Fax : 02.6085.4330</span>
            <span>Email : 315@egplatforms.com</span>
          </div>
          <div>
            <span className="copy">COPYRIGHT©2024 kiwontech all rights reserved.</span>
          </div>
        </Bottom>
      </FooterWrap>
    </>
  );
};

export default Footer;
