import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form, TextArea } from './style';
import DaumPostcode, { useDaumPostcodePopup } from 'react-daum-postcode';
import { postcodeScriptUrl } from 'react-daum-postcode/lib/loadPostcode';
import axios from 'axios';

const RequestForm = ({ confirm, setConfirm }) => {
  const open = useDaumPostcodePopup(postcodeScriptUrl);
  const useRefs = useRef([]);
  const validNum = /^[0-9]+$/;
  const emailForm =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const initialVal = {
    corName: '',
    qSize: '',
    zipcode: '',
    addr: '',
    addrDetail: '',
    mng_name: '',
    manage_contact: '',
    manage_email: '',
    contents: '',
  };
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialVal);
  const {
    corName,
    qSize,
    zipcode,
    addr,
    addrDetail,
    mng_name,
    manage_contact,
    manage_email,
    contents,
  } = values;
  const [checkbox, setCheckbox] = useState(false);
  const handleComplete = (data) => {
    setValues({ ...values, zipcode: data.zonecode, addr: data.roadAddress });
  };
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) {
        return false;
      }
      for (let i = 0; i < useRefs.current.length; i++) {
        if (useRefs.current[i].value === '' || !useRefs.current[i].value.trim()) {
          alert(useRefs.current[i].title + '을(를) 입력해주세요');
          useRefs.current[i].focus();
          return false;
        }
      }
      if (!emailForm.test(manage_email)) {
        alert('이메일 양식을 확인해주세요');
        useRefs[7]?.current.focus();
        return false;
      }
      if (checkbox === false) {
        alert('개인정보 수집 및 이용을 동의해주세요.');
        return false;
      }
      // 전송 로직
      try {
        setLoading(true);
        axios
          .post(
            '/save',
            {
              ...values,
              qSize: +qSize,
              zipcode: +zipcode,
              privacy_chk: 'Y',
              formType: 'supp',
            },
            {
              headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
              },
            },
          )
          .then((res) => {
            setConfirm(!confirm);
            setValues(initialVal);
            setCheckbox(false);
          });
      } catch (error) {
        alert('오류가 발생했습니다. 관리자에게 연락 부탁드립니다.');
      } finally {
        setLoading(false);
      }
    },
    [values, checkbox],
  );

  return (
    <Form onSubmit={onSubmit}>
      <div className="flex">
        <div className="flexItem">
          <label className="company">
            <span>기업명</span>
            <input
              type="text"
              name="corName"
              onChange={handleChange}
              ref={(el) => (useRefs.current[0] = el)}
              value={corName}
              title="기업명"
            />
          </label>
          <label className="num">
            <span>직원수</span>
            <input
              type="text"
              name="qSize"
              onChange={handleChange}
              ref={(el) => (useRefs.current[1] = el)}
              value={qSize.replace(/[^0-9]/g, '')}
              title="직원수"
            />
            <span>명</span>
          </label>
        </div>
        <div className="flexItem">
          <label className="addr">
            <span>기업주소</span>
            <input
              type="text"
              name="zipcode"
              onClick={() => {
                open({ onComplete: handleComplete });
              }}
              onChange={handleChange}
              value={zipcode}
              ref={(el) => (useRefs.current[2] = el)}
              title="우편번호"
              tabIndex="-1"
            />
            <input
              type="text"
              name="addr"
              className="addrInput"
              onClick={() => {
                open({ onComplete: handleComplete });
              }}
              onChange={handleChange}
              value={addr}
              ref={(el) => (useRefs.current[3] = el)}
              title="도로명주소"
              tabIndex="-1"
            />
          </label>
          <button
            type="button"
            className="addrBtn"
            onClick={() => {
              open({ onComplete: handleComplete });
            }}
            onChange={handleChange}
          >
            우편번호 검색
          </button>
        </div>
        <div className="flexItem wp100">
          <input
            type="text"
            placeholder="상세주소를 입력해주세요."
            name="addrDetail"
            onChange={handleChange}
            ref={(el) => (useRefs.current[4] = el)}
            value={addrDetail}
            title="상세주소"
          />
        </div>
      </div>
      <div className="flex">
        <div className="flexItem">
          <label className="name">
            <span>이름/직책</span>
            <input
              type="text"
              name="mng_name"
              onChange={handleChange}
              value={mng_name}
              title="이름/직책"
              ref={(el) => (useRefs.current[5] = el)}
            />
          </label>
          <label className="phone">
            <span>연락처</span>
            <input
              type="text"
              name="manage_contact"
              onChange={handleChange}
              ref={(el) => (useRefs.current[6] = el)}
              value={manage_contact.replace(/[^0-9]/g, '')}
              title="연락처"
            />
          </label>
        </div>
        <div className="flexItem">
          <label className="name">
            <span>이메일 주소</span>
            <input
              type="text"
              name="manage_email"
              onChange={handleChange}
              ref={(el) => (useRefs.current[7] = el)}
              value={manage_email}
              title="이메일"
            />
          </label>
        </div>
      </div>
      <div className="flex">
        <TextArea>
          <textarea
            placeholder="문의 내용을 입력해 주세요."
            name="contents"
            onChange={handleChange}
            ref={(el) => (useRefs.current[8] = el)}
            value={contents}
            title="문의내용"
          />
          <label className="check">
            <input
              type="checkbox"
              name="checkbox"
              title="개인정보 수집 및 이용"
              value={checkbox}
              onChange={(e) => {
                setCheckbox((prev) => !prev);
              }}
              checked={checkbox}
            />
            <span className="checkmark"></span>
            <span className="trn" data-trn-key="TR_IDSAVE">
              개인정보 수집 및 이용에 동의합니다. &nbsp;
            </span>
          </label>
          <a
            onClick={() => {
              window.open(
                '/popup/TermPersonal',
                'window',
                'width=500, height=700, location=no, status=no, scrollbars=yes',
              );
            }}
          >
            [개인정보취급방침 보기]
          </a>
          <button type="submit" className="submit">
            <span>문의하기</span>
            <i />
          </button>
        </TextArea>
      </div>
    </Form>
  );
};

export default RequestForm;
