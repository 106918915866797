import React, { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BtnBox,
  DetailContent,
  DetailInner,
  DetailLink,
  DetailTitle,
  ListLink,
  MenuH2,
  NextPage,
  PageDirection,
  PrevPage,
  SourceLink,
  Sourcetit,
} from './style';
import axios from 'axios';
import trTags from 'hooks/useTrTag';
import TrDate from '../../../hooks/useTrDate';

const DetailPage = () => {
  const [detail, setDetail] = useState('');
  const { pathname } = useLocation();
  const idx = pathname.split('/')[4];
  const boardType = pathname.indexOf('securityissue') !== -1 ? 'N' : 'T';

  useEffect(() => {
    async function getDetail() {
      try {
        const response = await axios.get(`/board/view?boardType=${boardType}&idx=${idx}`);
        setDetail(response.data.view);
      } catch (error) {}
    }
    getDetail();
  }, [pathname]);

  const navigate = useNavigate();

  if (!detail) return false;
  return (
    <DetailInner>
      <MenuH2>뉴스</MenuH2>
      <DetailTitle>
        <h3>{detail.title}</h3>
        <span>{TrDate(detail.rgsde)}</span>
      </DetailTitle>
      <DetailContent dangerouslySetInnerHTML={{ __html: trTags(detail.contents) }}></DetailContent>
      {detail.articleUrl && (
        <DetailLink>
          <Sourcetit>[기사원문]</Sourcetit>
          <SourceLink>
            <a href={detail.articleUrl} target="_blank" rel="noreferrer">
              {detail.articleUrl}
            </a>
          </SourceLink>
        </DetailLink>
      )}
      <PageDirection>
        <NextPage>
          <p>다음글</p>
          <span>
            <RiArrowUpSFill />
          </span>
          <span className="detailink">
            <Link
              to={`/news/news/detail/${detail.nextIdx}`}
              style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}
            >
              {detail.nextTitle === null ? '다음글이 없습니다.' : detail.nextTitle}
            </Link>
          </span>
        </NextPage>

        <PrevPage>
          <p>이전글</p>
          <span>
            <RiArrowDownSFill />
          </span>
          <span className="detailink">
            <Link
              to={`/news/news/detail/${detail.prevIdx}`}
              style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}
            >
              {detail.prevTitle === null ? '이전글이 없습니다.' : detail.prevTitle}
            </Link>
          </span>
        </PrevPage>
      </PageDirection>
      <BtnBox>
        <ListLink to={boardType === 'T' ? '/news/news' : '/news/securityissue'}>list</ListLink>
      </BtnBox>
    </DetailInner>
  );
};

export default DetailPage;
