import React, { useState, useCallback, useEffect } from 'react';
import trTags from '../../hooks/useTrTag';
import { MaxWidth, Section } from 'GlobalStyle';
import {
  Main01,
  Main02,
  Main02Item,
  Wrapper,
  Main03,
  Main04,
  Main05,
  TabSection,
  TabWrapper,
} from './style';
import { tabs, contents } from './tabs.js';

const Groupware = () => {
  const [tabKey, setTabKey] = useState(1);
  const prevBtnUrl = tabKey === 1 ? '/images/icon/ico-prev-first.png' : '/images/icon/ico-prev.png';
  const nextBtnUrl = tabKey === 5 ? '/images/icon/ico-next-last.png' : '/images/icon/ico-next.png';
  const [ScrollActive, setScrollActive] = useState(false);
  const [position, setPosition] = useState(0);

  const selectTab = useCallback(
    (key) => {
      setTabKey(key);
    },
    [tabKey],
  );
  const moveToPrev = useCallback(
    (idx) => {
      if (--idx > 0) {
        setTabKey(idx);
      }
    },
    [tabKey],
  );
  const moveToNext = useCallback(
    (idx) => {
      if (++idx <= tabs.length) {
        setTabKey(idx);
      }
    },
    [tabKey],
  );
  const onScroll = () => {
    setPosition(window.scrollY);
    if (position > 1500) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [position]);

  return (
    <>
      <Main01>
        <div>
          <div className="main01_wrap">
            <p>효율적인 업무 협업 Tool</p>
            <h1>그룹웨어 서비스</h1>
            <p className="intro">
              <span> 일을 더 쉽고, 효율적으로! </span>
              <span>프로젝트 관리 · 기업용 웹하드 · 캘린더 · 커뮤니티 등 </span>
              <span>보안 그룹웨어 시스템 SECU E Cloud</span>
            </p>
          </div>
        </div>
      </Main01>
      <Main02>
        <MaxWidth>
          <h2>왜 그룹웨어를 사용해야 하나요?</h2>
          <Wrapper>
            <Main02Item>
              <h5>
                <span>제약 없는 </span>
                <span>업무환경 제공</span>
              </h5>
              <p>
                <span>
                  그룹웨어 도입을 통해 비대면 근무 및 재택근무 환경을 제공하여 효율적인 근무환경을
                  구축합니다.
                </span>
              </p>
            </Main02Item>
            <Main02Item>
              <h5>
                <span>원활한 </span>
                <span>커뮤니케이션 제공</span>
              </h5>
              <p>
                <span>
                  전자결재, 커뮤니티 등의 기능을 활용하여 원활한 소통과 신속한 의사결정을
                  진행합니다.
                </span>
              </p>
            </Main02Item>
            <Main02Item>
              <h5>
                <span>업무 개선 및 </span>
                <span>사내 정보화 수준 향상</span>
              </h5>
              <p>
                <span>
                  전자결재, 문서관리, 업무메일 등 모든 업무를 온라인으로 전환하여 효율적으로 업무를
                  처리합니다.
                </span>
              </p>
            </Main02Item>
          </Wrapper>
        </MaxWidth>
      </Main02>
      <Main03>
        <MaxWidth>
          <div className="tab-menu">
            <h2>
              SECU E Cloud <span>그룹웨어 기능</span>
            </h2>
            <ul className={`inner ${ScrollActive ? 'fixed' : ''}`}>
              {tabs.map((el) => (
                <li key={el.key}>
                  <button
                    className={`tab ${tabKey === el.key ? 'active' : ''}`}
                    onClick={() => {
                      selectTab(el.key);
                    }}
                  >
                    {el.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </MaxWidth>
        <TabSection>
          <MaxWidth>
            <h3>
              {tabs[tabKey - 1].title} <span>{tabs[tabKey - 1].bold}</span>
            </h3>
            <p>
              <span>{tabs[tabKey - 1].describe1}</span>
              <span>{tabs[tabKey - 1].describe2}</span>
            </p>
            <TabWrapper>
              <button className="prev" onClick={() => moveToPrev(tabKey)}>
                <img className="tab-img" src={prevBtnUrl} />
              </button>
              <img src={tabs[tabKey - 1].url} className="centerimg" />
              <button className="next" onClick={() => moveToNext(tabKey)}>
                <img src={nextBtnUrl} />
              </button>
            </TabWrapper>
          </MaxWidth>
        </TabSection>
      </Main03>
      <Main04>
        <MaxWidth>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: trTags(contents[tabKey][0].title) }}></h3>
            <span>{contents[tabKey][0].content1}</span>
            <span>{contents[tabKey][0].content2}</span>
            <span>{contents[tabKey][0].content3}</span>
          </div>
          <img src={contents[tabKey][0].imageUrl} />
        </MaxWidth>
      </Main04>
      <Main05>
        <MaxWidth>
          <div className="txtWrap">
            <h3 dangerouslySetInnerHTML={{ __html: trTags(contents[tabKey][1].title) }}></h3>
            <span>{contents[tabKey][1].content1}</span>
            <span>{contents[tabKey][1].content2}</span>
            <span>{contents[tabKey][1].content3}</span>
          </div>
          <div>
            <img src={contents[tabKey][1].imageUrl} />
          </div>
        </MaxWidth>
      </Main05>
    </>
  );
};

export default Groupware;
