import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MaxWidth, Section } from 'GlobalStyle';
import { SearchSection, ContentSection } from './style';
import ContentItem from './component/contentItem';
import Pagination from 'components/pagination';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Search from './component/Search';
import { useDispatch } from 'react-redux';
import { changeColor } from 'store/module/footerSlice';

const FAQ = () => {
  const [list, setList] = useState();
  const [pagingInfo, setPagingInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('pageNo') || 1;
  const categorySoVal = searchParams.get('categorySo');
  const searchKeywordVal = searchParams.get('searchKeyword');
  const dispatch = useDispatch();

  useEffect(() => {
    const getList = async () => {
      const result = await axios.get(
        `/support?pageNo=${currentPage}${categorySoVal ? `&categorySo=${categorySoVal}` : ''}${
          searchKeywordVal ? `&searchKeyword=${searchKeywordVal}` : ''
        }`,
      );
      if (result.data.faqList.length !== 0) {
        setList(result.data.faqList);
        setPagingInfo(result.data.Paging);
      } else {
        setList([]);
      }
    };
    getList();
  }, [searchParams]);
  useEffect(() => {
    dispatch(changeColor('gray'));
    searchParams.set('pageNo', 1);
    searchParams.delete('categorySo');
    searchParams.delete('searchKeyword');
    setSearchParams(searchParams);
    return () => {
      dispatch(changeColor(''));
    };
  }, []);
  return (
    <>
      <ContentSection>
        <Search />
        {list?.length !== 0 ? (
          <>
            <MaxWidth>
              <ul className="content-list">
                {list?.map((val) => (
                  <ContentItem info={val} key={val.idx} />
                ))}
              </ul>
            </MaxWidth>
            {list?.length !== 0 && pagingInfo ? <Pagination pagingInfo={pagingInfo} /> : null}
          </>
        ) : (
          <div className="nolist">리스트가 없습니다.</div>
        )}
      </ContentSection>
    </>
  );
};

export default FAQ;
