import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DownloadWrapper } from './style';
import { Link } from 'react-router-dom';

const Confirm = ({ setConfirm }) => {
  return (
    <DownloadWrapper>
      <div className="confirm_content">
        <h2>문의 접수 완료</h2>
        <div className="salutation">
          <p>문의가 성공적으로 접수되었습니다.</p>
          <p>문의 내용은 담당자 확인 후 기입하신 연락처로 답변 드리겠습니다.</p>
          <p>감사합니다.</p>
        </div>
        <button
          onClick={() => {
            setConfirm((prev) => !prev);
          }}
        >
          확인
        </button>
      </div>
    </DownloadWrapper>
  );
};

export default Confirm;
