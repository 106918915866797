export const navData = [
  {
    id: 1,
    title: '메일',
    link: '/mail',
  },
  {
    id: 2,
    title: '그룹웨어',
    link: '/groupware',
  },
  {
    id: 3,
    title: '보안',
    link: '/security',
  },
  {
    id: 4,
    title: '뉴스',
    link: '/news/news',
  },
  {
    id: 5,
    title: '고객지원',
    link: '/customerSupport',
  },
];
