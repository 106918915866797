import styled from 'styled-components';

export const Form = styled.form`
  width: 950px;

  @media screen and (max-width: 1200px) {
    width: auto;
  }

  .flex {
    background-color: #fff;
    padding: 25px 60px;
    border-radius: 15px;
    margin-bottom: 15px;

    .flexItem {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 15px;

      input {
        padding: 0 0 0 15px;
        height: 45px;
        border-radius: 10px;
        background-color: #f4f4f9;
      }
      &.wp100 {
        justify-content: end;
        input {
          width: calc(100% - 105px);
          ::placeholder {
            color: #a3a3a3;
            font-size: 14px;
          }

          @media screen and (max-width: 750px) {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          }
        }
        @media screen and (max-width: 750px) {
          height: 115px;
        }
      }
      label {
        display: flex;
        align-items: center;
        justify-content: start;

        span {
          color: #5e5e5e;
          font-size: 15px;
          font-weight: 500;
          display: inline-block;
          max-width: 90px;
          width: 100%;
          letter-spacing: -0.025em;
          @media screen and (max-width: 750px) {
            max-width: 60px;
          }
        }
      }
      .num {
        input {
          width: 80px;
          margin: 0 15px 0 20px;
        }
      }

      .addr {
        width: calc(100% - 105px);

        span {
          display: inline-block;
          max-width: 90px;
          width: 100%;

          @media screen and (max-width: 750px) {
            margin-bottom: 20px;
          }
        }

        input {
          width: calc(25% - 5px);

          &:first-of-type {
            margin-right: 10px;
          }

          @media screen and (max-width: 750px) {
            width: 100%;
            margin-bottom: 20px;
            box-sizing: border-box;
          }

          &:nth-of-type(2) {
            @media screen and (max-width: 750px) {
              margin-bottom: 0px;
            }
          }

          &.addrInput {
            width: calc(75% - 5px);
            @media screen and (max-width: 750px) {
              width: 100%;
              box-sizing: border-box;
            }
          }
        }

        @media screen and (max-width: 750px) {
          width: 100%;
          display: block;
        }
      }
      .addrBtn {
        width: 120px;
        border: 1px solid #4d81db;
        color: #4d81db;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        border-radius: 10px;
        margin-left: 10px;
        @media screen and (max-width: 750px) {
          width: 100%;
          margin-left: 0px;
        }
      }
      .company {
        width: 55%;
        padding-right: 10px;
        box-sizing: border-box;
        input {
          max-width: 230px;
          width: 100%;

          @media screen and (max-width: 1200px) {
            max-width: 100%;
          }
        }
        @media screen and (max-width: 750px) {
          width: 100%;
          padding-right: 0px;
        }
      }
      .name {
        width: 50%;
        padding-right: 15px;
        box-sizing: border-box;
        input {
          max-width: 230px;
          width: 100%;
          @media screen and (max-width: 1200px) {
            max-width: 100%;
          }
        }
        & > span {
          max-width: 95px;

          @media screen and (max-width: 750px) {
            max-width: 95px;
          }
        }
        @media screen and (max-width: 1200px) {
          width: 100%;
          padding-right: 0px;
        }
      }
      .phone {
        width: 50%;
        padding-right: 15px;
        box-sizing: border-box;
        input {
          max-width: 230px;
          width: 100%;
          @media screen and (max-width: 1200px) {
            max-width: 100%;
          }
        }
        @media screen and (max-width: 1200px) {
          width: 100%;
          padding-right: 0px;
        }
        & > span {
          text-align: center;
          @media screen and (max-width: 750px) {
            max-width: 95px;
            text-align: left;
          }
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
      }
    }
    &:nth-of-type(3) {
      position: relative;
      padding: 30px 60px 85px;
      @media screen and (max-width: 1200px) {
        padding: 30px 30px 85px;
      }
      @media screen and (max-width: 750px) {
        padding: 30px 20px 85px;
      }
    }
    @media screen and (max-width: 1200px) {
      padding: 25px 30px;
    }
    @media screen and (max-width: 750px) {
      padding: 25px 20px;
    }
  }
`;

export const TextArea = styled.div`
  textarea {
    position: relative;
    border: none;
    border-radius: 10px;
    background-color: #f4f4f9;
    padding: 20px;
    width: 100%;
    height: 190px;
    resize: none;
    box-sizing: border-box;
    ::placeholder {
      color: #a3a3a3;
      font-size: 14px;
    }
    :focus {
      outline: none;
    }
    margin-bottom: 15px;
    @media screen and (max-width: 750px) {
      height: 100%;
    }
  }
  .check {
    color: #6f6f6f;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    padding-left: 22px;
    text-align: left;
    min-width: 14px;
    min-height: 20px;
    margin-bottom: 0;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0px;
      width: 0px;
    }
    .checkmark {
      position: absolute;
      left: 0;
      top: 0px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #fff;
      border: 2px solid #dcdcdc;
    }
    input:checked ~ .checkmark {
      background: #5581e4;
      border-color: #5581e4;
    }
    input:checked ~ .checkmark:after {
      display: block;
      content: '';
      cursor: pointer;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;
      position: absolute;
      top: 45%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
    }
    @media screen and (max-width: 750px) {
      display: block;
    }
  }
  .trn {
    display: inline-block;
    margin-left: 10px;
  }
  .submit {
    position: absolute;
    width: 130px;
    box-sizing: border-box;
    height: 40px;
    background-color: #4d81db;
    display: block;
    border-radius: 10px;
    right: 75px;
    bottom: 35px;
    padding: 0 15px;
    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      display: inline-block;
      margin-right: 15px;
    }
    i {
      background-image: url('/images/icon/ico-support03_arrow.png');
      width: 17px;
      height: 12px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
    }
    @media screen and (max-width: 750px) {
      left: 50%;
      bottom: -70px;
      transform: translate(-50%);
    }
  }
  a {
    color: #6f6f6f;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    line-height: 1.5;
    @media screen and (max-width: 750px) {
      display: inline-block;
      margin-left: 30px;
    }
  }
  @media screen and (max-width: 750px) {
    height: 350px;
  }
`;
