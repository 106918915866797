export const tabs = [
  {
    key: 1,
    name: '전자결재',
    title: `신속한 의사결정 사항`,
    bold: '‘전자결재’',
    url: '/images/img-groupware02-tab01.png',
    describe1: '종이 서류의 복잡하고 불편한 결재는 이제 그만! ',
    describe2: '온라인으로 언제 어디서나 빠르고 편리하게 결재하세요.',
  },
  {
    key: 2,
    name: '커뮤니티',
    title: '원활한 정보 공유를 위한',
    bold: '‘커뮤니티’',
    url: '/images/img-groupware02-tab02.png',
    describe1: '중요한 사내 소식과 공지사항, ',
    describe2: '다양한 정보를 가장 빠르고 한번에 공유하세요.',
  },
  {
    key: 3,
    name: '문서관리',
    title: '안전한 문서보관 저장소',
    bold: '‘문서관리’',
    url: '/images/img-groupware02-tab03.png',
    describe1: '기업 내 임직원 PC 또는 종이 서류로 관리하던 수많은 문서를 편리하게 관리하고, ',
    describe2: '분실 위험 없이 안전하게 보관하세요.',
  },
  {
    key: 4,
    name: '일정관리',
    title: '업무 일정 공유를 쉽고 빠르게,',
    bold: '‘일정관리’',
    url: '/images/img-groupware02-tab04.png',
    describe1: '그룹별 일정을 일간,주간,월간 단위로 확인하여 ',
    describe2: '조직원 간의 일정을 원활하게 조율 및 공유할 수 있습니다.',
  },
  {
    key: 5,
    name: 'TO DO 관리',
    title: `꼼꼼한 업무관리 Tool`,
    bold: '‘TO DO 관리’',
    url: '/images/img-groupware02-tab05.png',
    describe1: '메모장에 기록했던 업무들과 서면으로 보고했던 습관을 ',
    describe2: 'TO DO로 체계적으로, 개인 업무와 프로젝트 업무를 체계적으로 관리하세요.',
  },
];

export const contents = {
  1: [
    {
      title: `<p>
              다양한 상세 설정을 통해
              <br />
              <span>결재를 효율적</span>으로
            </p>`,
      content1: '결재순서를 자유/순차 결재 중에서 선택할 수 있어, ',
      content2: '최종 결재자를 제외한 결재자들이 순서와 상관없이 ',
      content3: '결재를 빠르고 편리하게 처리할 수 있습니다.',
      imageUrl: '/images/img-tab01-content01.png',
    },
    {
      title: `<p>
              에디터로 내가
              <span>직접 만드는</span><br/>우리 회사 결재 서식!
            </p>`,
      content1: '결재 서식을 회사에 맞게 생성할 수 있도록 ',
      content2: '에디터를 제공합니다. ',
      content3: '쉽고 간편하게 양식을 만들어보세요.',
      imageUrl: '/images/img-tab01-content02.png',
    },
  ],
  2: [
    {
      title: `<p>
              사내 소식을 빠르게<br />
              전하는 <span>‘게시판’</span>
            </p>`,
      content1: '중요한 정보를 커뮤니티 공지사항으로 한번에 ',
      content2: '전사 공유 가능합니다. 게시글 성격에 맞게 ',
      content3: '세부 게시판으로 분류하여 사용할 수 있습니다.',
      imageUrl: '/images/img-tab02-content01.png',
    },
    {
      title: `<p>
              중요한 전달 사항은
              <br />
              <span>공지</span>로 확실하게
            </p>`,
      content1: '전직원에게 공유해야 하는 중요한 게시글은 ',
      content2: '공지사항으로 업로드하여 ',
      content3: '게시판 최상단에 노출시킬 수 있습니다.',
      imageUrl: '/images/img-tab02-content02.png',
    },
  ],
  3: [
    {
      title: `<p>
              언제, 어디서든
              <br />
              <span>최신 버전 문서 공유</span>
            </p>`,
      content1: '최신 버전 수정 후, 관련 담당자에게 모두 공유할 필요 없이 ',
      content2: '수정 이력을 통해 히스토리를 파악하고, ',
      content3: '최신 버전의 문서를 언제, 어디서든 다운로드 받으세요.',
      imageUrl: '/images/img-tab03-content01.png',
    },
    {
      title: `<p>
              업무 자료를 저장하고
              <br />
              공유하는 <span>프로젝트 별 문서함</span>
            </p>`,
      content1: '프로젝트에 필요한 업무 파일이나 데이터를 ',
      content2: '공유문서함에 업로드하여 ',
      content3: '관련 담당자와 효과적으로 공유할 수 있습니다.',
      imageUrl: '/images/img-tab03-content02.png',
    },
  ],
  4: [
    {
      title: `<p>
              협업을 쉽게 하는
              <br />
              <span>캘린더형 공유</span>기능
            </p>`,
      content1: '개인 일정과 프로젝트별 일정을 ',
      content2: '한눈에 파악 할 수 있어 전체 업무 일정을 ',
      content3: '효과적으로 관리할 수 있습니다.',
      imageUrl: '/images/img-tab04-content01.png',
    },
    {
      title: `<p>
              <span>모바일</span>에서도 한 눈에<br />
              확인하는 프로젝트 일정
            </p>`,
      content1: '모바일 앱에서도 일정관리를 한눈에 확인하고 관리 할 수 ',
      content2: '있습니다. 오늘 일정을 시간별로 확인 할 수 있으며, ',
      content3: '주요 일정을 놓치지 않고 확인할 수 있습니다.',
      imageUrl: '/images/img-tab04-content02.png',
    },
  ],
  5: [
    {
      title: `<p>
              <span>진행기간</span>과 <span>진행률</span>을<br/>
              한눈에 확인하는<br/> 
              업무 진행 현황
            </p>`,
      content1: '조직도 기반의 효율적인 업무 협업 시스템으로 ',
      content2: '편리하게 업무 공유 및 보고, ',
      content3: '진행상황을 놓치지 않게 도와줍니다.',
      imageUrl: '/images/img-tab05-content01.png',
    },
    {
      title: `<p>
              배정업무 결과와 문서를<br/>
              팀원과 팀장님께 <span>손쉽게 공유</span>
            </p>`,
      content1: '배정업무와 관련된 내용과 문서를 ',
      content2: '프로젝트 투입 인력과 손쉽게 공유할 수 있고, ',
      content3: '검토자에게 간편하게 보고할 수 있습니다.',
      imageUrl: '/images/img-tab05-content02.png',
    },
  ],
};
