import styled, { keyframes } from 'styled-components';

export const Main01 = styled.article`
  position: relative;
  width: 100%;
  padding: 231px 40px 150px;
  box-sizing: border-box;

  @media screen and (max-width: 920px) {
    padding: 180px 40px 110px;
  }

  @media screen and (max-width: 670px) {
    padding: 140px 40px 70px;
  }

  @media screen and (max-width: 600px) {
    padding: 470px 30px 50px;
  }

  @media screen and (max-width: 480px) {
    padding: 450px 30px 50px;
  }

  & > div {
    display: flex;
    justify-content: right;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      justify-content: center;
      align-items: end;
    }
  }

  .main01_wrap {
    position: relative;
    z-index: 1;
    width: 50%;

    @media screen and (max-width: 600px) {
      width: 100%;
      text-align: center;
      padding-bottom: 55px;
    }

    h1 {
      color: #3d6eb6;
      font-size: 60px;
      font-weight: 500;
      font-family: 'SBAggrolL';
      margin-bottom: 24px;
      letter-spacing: -0.015em;
      line-height: 1.2;

      @media screen and (max-width: 920px) {
        font-size: 48px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 480px) {
        font-size: 38px;
      }
    }
    p {
      color: #3d6eb6;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      letter-spacing: -0.025em;
      @media screen and (max-width: 920px) {
        font-size: 20px;
      }
      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }

    .intro {
      @media screen and (max-width: 1200px) {
        padding-right: 50px;
      }

      @media screen and (max-width: 920px) {
        font-size: 16px;
        word-break: break-word;
      }

      @media screen and (max-width: 670px) {
        padding-right: 30px;
      }

      @media screen and (max-width: 600px) {
        width: auto;
        margin: 0 auto;
        padding-right: 0;
      }

      @media screen and (max-width: 330px) {
        width: 100%;
      }

      span {
        display: block;
        margin: 0 0 3px 0;
        color: #6f6f6f;
        font-size: 19px;
        font-weight: 500;
        line-height: 1.6;

        @media screen and (max-width: 1200px) {
          display: inline;
        }
        @media screen and (max-width: 920px) {
          font-size: 16px;
        }
      }
    }
  }

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-mail01-left.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    right: 55%;
    width: 890px;
    top: 0;
    bottom: 0;

    @media screen and (max-width: 920px) {
      background-size: auto 335px;
      width: 400px;
      background-position: right bottom;
      right: 57%;
    }

    @media screen and (max-width: 830px) {
      right: 53%;
    }

    @media screen and (max-width: 800px) {
      right: 50%;
      background-position: right bottom;
    }

    @media screen and (max-width: 720px) {
      right: 47%;
    }

    @media screen and (max-width: 600px) {
      background-image: url('/images/background/bg-mail01_bot.png');
      background-size: 600px;
      background-position: bottom center;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-mail01-right.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
`;

export const Main02 = styled.article`
  width: 100%;
  padding: 120px 0px;
  background-color: #4d81db;
  box-sizing: border-box;
  @media screen and (max-width: 1300px) {
    padding: 100px 0px;
  }
  @media screen and (max-width: 880px) {
    padding: 90px 45px;
  }
  @media screen and (max-width: 580px) {
    padding: 75px 0px;
  }
  & > div {
    @media screen and (max-width: 580px) {
      padding: 0 30px;
    }
  }
  h2 {
    color: #ffffff;
    text-align: center;
    font-size: 48px;
    padding-bottom: 65px;
    @media screen and (max-width: 880px) {
      padding-bottom: 45px;
    }
    & > div {
      display: inline;

      @media screen and (max-width: 880px) {
        display: block;
        font-size: 38px;
      }
      @media screen and (max-width: 480px) {
        font-size: 28px;
      }
      font-family: 'SBAggrolL';
      font-weight: 500;
      & > span {
        color: #ccf7ff;
        font-size: 48px;
        font-family: 'SBAggrolL';
        font-weight: 500;
        line-height: 1.6;
        @media screen and (max-width: 880px) {
          font-size: 38px;
        }
        @media screen and (max-width: 480px) {
          font-size: 28px;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  box-sizing: border-box;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;

export const Main02Item = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 148px 40px 68px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 40px 62px;
  box-shadow: 7px 7px 3px rgba(72, 81, 113, 0.15);

  &:nth-of-type(1) {
    background-image: url('/images/icon/ico-mail01.png');
  }
  &:nth-of-type(2) {
    background-image: url('/images/icon/ico-mail02.png');
  }
  &:nth-of-type(3) {
    background-image: url('/images/icon/ico-mail03.png');
  }

  @media screen and (max-width: 1300px) {
    background-position: 40px 60px;
    padding: 140px 40px 100px;
    height: 450px;
  }

  @media screen and (max-width: 1200px) {
    background-position: 40px 60px;
    padding: 140px 40px 100px;
  }
  @media screen and (max-width: 965px) {
    background-position: 40px 50px;
    padding: 130px 40px 0;
    height: 495px;
  }
  @media screen and (max-width: 890px) {
    background-position: 40px 50px;
    padding: 130px 40px 0;
    height: 530px;
  }

  @media screen and (max-width: 880px) {
    padding: 50px 35px 50px 170px;
    background-position: 60px 50%;
    height: auto;
  }

  @media screen and (max-width: 680px) {
    padding: 50px 15px 50px 150px;
    background-position: 50px 50%;
  }
  @media screen and (max-width: 580px) {
    padding: 40px;
    background-position: 40px 60px;
    background-size: auto 50px;
  }
  @media screen and (max-width: 480px) {
    padding: 35px;
    background-position: 35px 40px;
  }
  @media screen and (max-width: 390px) {
    padding: 90px 25px 50px 25px;
    background-position: 25px 35px;
    background-size: 40px 40px;
  }

  div {
    margin: 0 0 auto 0;

    & > h5 {
      font-family: 'NotoSansKr';
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: 1.3;
      letter-spacing: -0.035em;

      @media screen and (max-width: 680px) {
        font-size: 20px;
      }
      @media screen and (max-width: 580px) {
        margin: 15px 0 20px 75px;
      }
      @media screen and (max-width: 480px) {
        margin: 0 0 20px 80px;
      }
      @media screen and (max-width: 390px) {
        margin: 0 0 10px 0;
      }

      & > span {
        @media screen and (max-width: 1300px) {
          display: block;
          line-height: 1.5;
        }
        @media screen and (max-width: 880px) {
          display: inline;
        }
        @media screen and (max-width: 680px) {
          display: block;
        }
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      font-family: 'NotoSansKr';
      color: #656565;
      line-height: 1.5;
      letter-spacing: -0.025em;
      word-break: keep-all;
      padding-right: 0;

      @media screen and (max-width: 680px) {
        font-size: 16px;
      }
      @media screen and (max-width: 580px) {
        padding-right: 0;
      }
    }
  }
`;

export const Main03 = styled.article`
  width: 100%;
  box-sizing: border-box;
  font-family: 'SBAggrolL';

  & > div {
    .tab-menu {
      padding: 160px 0 65px;

      @media screen and (max-width: 1300px) {
        padding: 115px 0 50px;
      }
      @media screen and (max-width: 800px) {
        padding: 115px 0 55px;
      }
      @media screen and (max-width: 400px) {
        padding: 75px 0 55px;
      }
    }
    h2 {
      font-family: 'SBAggrolL';
      font-weight: 600;
      font-size: 44px;
      line-height: 1.3;
      color: #3d6eb6;
      text-align: center;
      margin-bottom: 50px;
      & > span {
        font-family: 'SBAggrolL';
        font-weight: 500;
        color: #4e4e4e;
        font-size: 50px;
        @media screen and (max-width: 800px) {
          font-size: 36px;
          display: block;
        }
        @media screen and (max-width: 400px) {
          font-size: 28px;
        }
      }
      @media screen and (max-width: 1300px) {
        margin-bottom: 40px;
      }
      @media screen and (max-width: 800px) {
        margin-bottom: 35px;
        font-size: 36px;
      }
      @media screen and (max-width: 400px) {
        margin-bottom: 20px;
        font-size: 28px;
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    box-sizing: border-box;

    &.fixed {
      position: fixed;
      top: 90px;
      left: 0;
      right: 0;
      height: 100px;
      backdrop-filter: blur(5px);
      z-index: 99;

      @media screen and (max-width: 1300px) {
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        height: auto;
        backdrop-filter: none;
      }
    }

    li {
      @media screen and (max-width: 1300px) {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 30px;

        &:nth-of-type(4),
        &:nth-of-type(8) {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 800px) {
        &:nth-of-type(4) {
          margin-right: 20px;
        }
      }
      @media screen and (max-width: 700px) {
        &:nth-of-type(3),
        &:nth-of-type(6) {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 350px) {
        &:nth-of-type(3) {
          margin-right: 20px;
        }
      }
      /*     @media screen and (max-width: 450px) {
        &:nth-of-type(even) {
          margin-right: 0px;
        }
      }*/
    }
    .tab {
      background-color: #ffffff;
      border-radius: 22px;
      padding: 0px 35px;
      height: 45px;
      box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
      color: #505050;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.025em;
      @media screen and (max-width: 800px) {
        padding: 0 15px;
        box-sizing: border-box;
      }
      @media screen and (max-width: 420px) {
        padding: 0 10px;
        font-size: 16px;
      }
    }
    .active {
      background-color: #4d81db;
      color: #ffffff;
      box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
      font-weight: 500;
    }
    @media screen and (max-width: 1300px) {
      display: block;
      width: 680px;
      margin: 0 auto;
      text-align: center;
    }
    @media screen and (max-width: 700px) {
      width: 450px;
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
    }
    @media screen and (max-width: 430px) {
      box-sizing: border-box;
      grid-template-rows: 40px 40px;
    }
    @media screen and (max-width: 360px) {
    }
  }
`;

export const TabSection = styled.div`
  width: 100%;
  padding: 50px 0 50px 0;
  background-color: #f4f4f9;
  position: relative;

  & > div {
    h3 {
      text-align: center;
      font-size: 34px;
      color: #555555;
      font-family: 'NotoSansKr';
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 1.6;
      span {
        color: #3d6eb6;
        font-family: 'NotoSansKr';
        font-weight: 600;
        font-size: 44px;
        @media screen and (max-width: 800px) {
          font-size: 38px;
        }
        @media screen and (max-width: 480px) {
          font-size: 26px;
        }
      }
      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 480px) {
        font-size: 22px;
        margin-bottom: 30px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 480px) {
    padding: 50px 0;
  }
`;

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .tab-content {
    position: relative;
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1350px) {
      width: 100%;
    }
  }

  .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -150px;
    @media screen and (max-width: 1500px) {
      display: none;
    }
  }
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -150px;
    @media screen and (max-width: 1500px) {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 1200px;
  justify-content: space-between;
  display: flex;
  margin: 50px auto 50px;
  column-gap: 25px;
  box-sizing: border-box;

  @media screen and (max-width: 1300px) {
    width: auto;
    padding: 0 27px;
  }
  @media screen and (max-width: 1150px) {
    padding: 0 24px;
  }

  @media screen and (max-width: 880px) {
    flex-direction: column;
    row-gap: 25px;
    margin: 35px auto 20px;
    padding: 0 15px;
  }

  @media screen and (max-width: 580px) {
    padding: 0 10px;
  }

  @media screen and (max-width: 480px) {
    row-gap: 15px;
    padding: 0;
  }
`;

export const Content = styled.div`
  background-color: #ffffff;
  width: ${(props) => (props.className == 3 ? `calc(100% / 3)` : `calc(100% / 2)`)};
  margin: 0 auto;
  padding: 35px 40px 50px 40px;
  box-sizing: border-box;
  border-radius: 15px;

  @media screen and (max-width: 800px) {
    padding: 50px;
  }

  box-shadow: 3px 12px 7px rgba(219, 219, 219, 0.4);
  h5 {
    font-size: 24px;
    color: #4d81db;
    font-weight: 600;
    margin-bottom: 25px;

    @media screen and (max-width: 880px) {
      display: inline;
    }
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
    @media screen and (max-width: 450px) {
      display: block;
      margin-bottom: 15px;
    }
  }
  .title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 16px;
    letter-spacing: -0.025em;
    line-height: 1.2;
    color: #282828;

    @media screen and (max-width: 880px) {
      display: inline-block;
      font-size: 24px;
      margin-left: 16px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 480px) {
      font-size: 20px;
      margin-left: 16px;
    }
    @media screen and (max-width: 450px) {
      margin-left: 0;
    }
  }
  & > p {
    span {
      display: block;
      font-size: 18px;
      font-family: 'NotoSansKr';
      font-weight: 400;
      letter-spacing: -0.025em;
      line-height: 1.6;
      word-bread: keep-all;
      color: #555555;
    }
  }
  @media screen and (max-width: 1800px) {
    width: auto;
  }

  @media screen and (max-width: 880px) {
    width: 100%;
  }
`;
