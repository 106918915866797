import styled from 'styled-components';

export const HeaderNav = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  padding: 30px 100px;
  border-bottom: 1px solid #ececec;
  transition: 0.3s;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1300px) {
    padding: 30px 40px;
  }
  @media screen and (max-width: 900px) {
    padding: 21px 40px;
  }
  @media screen and (max-width: 500px) {
    padding: 16px 30px;
    img {
      width: 160px;
    }
  }
  &.main {
    padding: 60px 100px;
    background-color: transparent;
    border-bottom: 0;
    @media screen and (max-width: 1300px) {
      padding: 60px 40px;
    }
    @media screen and (max-width: 900px) {
      padding: 40px 40px;
    }
    @media screen and (max-width: 500px) {
      padding: 40px 30px;
    }
  }
  .b-navTop_pc {
    display: flex;
    align-items: center;
    justify-content: left;
    line-height: 0;
  }
  .b-nav_pc {
    justify-content: center;
    min-width: 50%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 880px) {
      display: none;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    a {
      padding: 0 30px;
      font-family: 'SBAggrolL' !important;
      color: #4f4f4f;
      font-size: 20px;
      font-weight: 400;
      transition: 0.2s;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #45bbec;
        opacity: 0.1;
        position: absolute;
        left: 15px;
        top: -15px;
        opacity: 0;
        transition: 0.2s;
        z-index: -1;
      }
      &:hover,
      &.active {
        color: #3d6eb6;
        &:before {
          opacity: 0.1;
        }
      }
    }
  }
`;
export const ToggleBtn = styled.button`
  transition: all 0.4s;
  box-sizing: border-box;
  position: relative;
  width: 32px;
  height: 24px;
  display: none;
  z-index: 6;
  background-color: transparent;
  @media screen and (max-width: 880px) {
    display: inline-block;
  }
  &.active {
    span {
      &:nth-of-type(1) {
        width: 17px;
        -webkit-transform: translate(15px, 5px) rotate(45deg);
        transform: translate(15px, 5px) rotate(45deg);
      }
      &:nth-of-type(2) {
        width: 29px;
      }
      &:nth-of-type(3) {
        width: 17px;
        -webkit-transform: translate(15px, -5px) rotate(-45deg);
        transform: translate(15px, -5px) rotate(-45deg);
      }
    }
  }
  span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #868686;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 10px;
      width: 27px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
`;
export const MobileNav = styled.div`
  position: fixed;
  width: 470px;
  height: 100%;
  background-color: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  transform: translateX(100%);
  transition: 0.5s;
  padding: 0 40px;
  box-sizing: border-box;
  @media screen and (max-width: 500px) {
    width: 400px;
    padding: 0 30px;
    img {
      width: 160px;
    }
  }
  @media screen and (max-width: 410px) {
    width: 340px;
  }
  &.active {
    transform: translateX(0%);
  }
  .top {
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  ul {
    display: block;
    li {
      &.active {
      }
      a {
        padding: 20px 0 20px 20px;
        display: block;
        &:before {
          left: 0;
          top: 5px;
        }
      }
    }
  }
  & > a {
    color: #a6a6a6;
    font-size: 20px;
    font-weight: 400;
    font-family: 'SBAggrolL' !important;
    margin-left: 20px;
    display: inline-block;
    border-top: 1px solid #c3c3c3;
    width: calc(100% - 40px);
    padding-top: 50px;
    margin-right: 50px;
    margin-top: 30px;
    @media screen and (max-width: 490px) {
      width: calc(100% - 30px);
    }
  }
`;
export const DarkWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;
