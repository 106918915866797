import React, { useEffect } from 'react';
import { ModalWrapper, ModalBody, CloseBtn } from './style';

const Modal = ({ closeModal, children, type }) => {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);
  return (
    <ModalWrapper onClick={closeModal}>
      <ModalBody
        className={type === 'download' ? 'download' : type === 'confirm' ? 'confirm' : ''}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseBtn onClick={closeModal} />
        {children}
      </ModalBody>
    </ModalWrapper>
  );
};

export default Modal;
