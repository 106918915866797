import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}

  *{
    word-break: keep-all;
    line-height: 1.3;
     }
  body{
    background: #fff;
    overflow-x: hidden;
  }
  a{
    text-decoration: none;
  }
  button{
    cursor: pointer;
    background: inherit; 
    border:none;
    box-shadow:none;
    border-radius:0;
    padding:0;
    overflow:visible;
  }
  img{
    max-width: 100%;
  }
  body{
    &::-webkit-scrollbar{
      /* 스크롤바 막대 너비 설정 */ width: 6px;
    }
    /* 스크롤바 막대 설정*/
    &::-webkit-scrollbar-thumb{
      /* 스크롤바 막대 높이 설정 */
      height: 17%;
      background-color: #888;
      /* 스크롤바 둥글게 설정 */
      border-radius: 10px;
    } /* 스크롤바 뒷 배경 설정*/
    &::-webkit-scrollbar-track{
      background-color: rgba(0,0,0,0);
    }
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    :focus {
      outline: none;
    }
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    :focus {
      outline: none;
    }
  }
`;
export default GlobalStyle;

export const MaxWidth = styled.div`
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  @media screen and (max-width: 1260px) {
    padding: 0 30px;
  }
`;

export const Section = styled.section`
  margin-top: 100px;
`;
