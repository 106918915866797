import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import {
  BottomBtnGroup,
  Content01,
  Content02,
  Content03,
  Content04,
  Content05,
  Content06,
  Section,
} from './style';
import { Link, useNavigate } from 'react-router-dom';
import { MaxWidth } from '../../GlobalStyle';
import { throttle } from 'lodash';
import EventPopUp from '../../popup/EventPopUp';

const Main = ({ noWheel }) => {
  const mainRef = useRef();
  const chartRef = useRef();
  const [scrollEvt, setScrollEvt] = useState(0);
  const [chartData, setChartData] = useState();
  const [slideNo, setSlideNo] = useState(1);

  /*이벤트 팝업*/
  const [closePop, setClosePop] = useState(true);
  const [todayPop, setTodayPop] = useState(false);

  const onChangeToday = useCallback(
    (checked) => {
      setTodayPop(checked);
    },
    [todayPop, setTodayPop],
  );

  const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie');
  const VISITED_NOW_DATE = Math.floor(new Date().getDate());

  useEffect(() => {
    // 팝업 오늘 하루닫기 체크
    if (VISITED_BEFORE_DATE !== null) {
      // 날짜가 같을경우 노출
      if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
        localStorage.removeItem('VisitCookie');
        setClosePop(true);
      }
      // 날짜가 다를경우 비노출
      if (VISITED_BEFORE_DATE !== VISITED_NOW_DATE) {
        setClosePop(false);
      }
    }
  }, [VISITED_BEFORE_DATE]);

  const onClickPop = useCallback(() => {
    if (todayPop) {
      const expiry = new Date();
      // +1일 계산
      const expiryDate = expiry.getDate() + 1;
      // 로컬스토리지 저장
      localStorage.setItem('VisitCookie', expiryDate);
    }
    setClosePop(false);
  }, [closePop, todayPop]);

  const navigate = useNavigate();
  ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

  /*  const pointImage = new Image();
  pointImage.src = '/images/ico-chartPoint.png';*/

  const myChart = () => {
    setChartData({
      labels: ['접근성', '안정성', '효율성', '신속성', '다양성'],
      datasets: [
        {
          //label: '시큐이클라우드와 함께 업무를 효율적으로',
          data: [],
          fill: true,
          /*backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createRadialGradient(390, 390, 390, 390, 390, 30);
          gradient.addColorStop(0, 'rgba(246,85,123, 0.8)');
          gradient.addColorStop(0.5, 'rgba(246,85,123, 0.25)');
          gradient.addColorStop(1, 'rgba(246,85,123, 0.3)');
          return gradient;
        },*/
          //pointStyle: pointImage,
          /*pointBorderWidth: 8,
          backgroundColor: 'rgba(170,201,255,0.4)',
          borderColor: 'rgb(32,137,255)',
          pointBackgroundColor: 'rgb(32,137,255)',
          pointBorderColor: 'rgb(32,137,255)',*/
        },
      ],
    });
  };

  const options = {
    scales: {
      myScale: {
        axis: 'r',
        suggestedMin: 70,
        suggestedMax: 100,
        angleLines: {
          display: false,
        },
        grid: {
          color: 'rgba(171,202,255, 1)',
        },
        pointLabels: {
          font: {
            size: 0,
            family: '"SBAggrolL", sans-serif',
            weight: 500,
          },
          color: '#457cda',
          padding: 105,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    animation: {
      duration: 1300,
    },
  };

  useEffect(() => {
    myChart();
  }, []);

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        let scrollEvent = mainRef.current.getBoundingClientRect().top;
        let chartScroll = chartRef.current.getBoundingClientRect().top;

        if (scrollEvent > -449) {
          setScrollEvt(0);
        }
        if (scrollEvent <= -450) {
          setScrollEvt(1);
        }
        if (scrollEvent <= -650) {
          setScrollEvt(2);
        }
        if (scrollEvent <= -750) {
          setScrollEvt(3);
        }
        if (chartScroll < 550) {
          setScrollEvt(4);
          setChartData({
            labels: ['접근성', '안정성', '효율성', '신속성', '다양성'],
            datasets: [
              {
                data: [100, 100, 100, 100, 100],
                fill: true,
                pointBorderWidth: 0,
                point: false,
                backgroundColor: 'rgba(170,201,255,0.4)',
                borderColor: 'rgb(32,137,255)',
              },
            ],
          });
        } else {
          setChartData({
            labels: ['접근성', '안정성', '효율성', '신속성', '다양성'],
            datasets: [
              {
                data: [0, 0, 0, 0, 0],
                fill: true,
                pointBorderWidth: 0,
                point: false,
                backgroundColor: 'rgba(170,201,255,0.4)',
                borderColor: 'rgb(32,137,255)',
              },
            ],
          });
        }
      }, 100),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  const nextSlide = useCallback(() => {
    if (slideNo !== 2) {
      setSlideNo(2);
    }
  }, [slideNo]);

  const prevSlide = useCallback(() => {
    if (slideNo !== 1) {
      setSlideNo(1);
    }
  }, [slideNo]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (!chartData) return false;
  return (
    <Section>
      <Content01 ref={mainRef}>
        <div className="text">
          <div className="textInner">
            <p className="top">
              그룹웨어의 <span>시</span>
              <span>야</span>를 넓히다
            </p>
            <h1>
              SECU <span>E</span> <span>Cloud</span>
            </h1>
            <p className="bot">
              <span>비즈니스 성장을 위한 ‘단 하나’의 해법</span>
              <span>보안메일부터 체계적인 문서관리에 효율적인 업무 방식까지! </span>
              <span>모든 솔루션을 하나에 담은 All-In-One 그룹웨어 플랫폼</span>
            </p>
            <Link to="/mail">VIEW MORE</Link>
          </div>
        </div>
        <div className="img">
          <div className="inner">
            <img src="/images/img-main02_bg.png" alt="메인이미지" className="img01" />
            <img src="/images/img-main02_human.png" alt="메인이미지" className="img02" />
            <img src="/images/img-main02_com.png" alt="메인이미지" className="img03" />
          </div>
        </div>
      </Content01>
      <Content02>
        <MaxWidth>
          <div className="inner">
            <h2>
              <span>일하는 우리 모두의</span>
              <span>
                <span>성장, 성공</span> 비결은
              </span>
              <span>무엇일까요?</span>
            </h2>
            <ul>
              <li className={scrollEvt >= 1 ? 'active' : ''}>
                <h4>
                  업계 1위로 크게 성장한 <span>회사의 김대표님</span>
                </h4>
                <p>“핵심 기술이 외부로 유출되는 것을 막아 경쟁력을 유지할 수 있었어요.”</p>
              </li>
              <li className={scrollEvt >= 2 ? 'active' : ''}>
                <h4>
                  팀의 능률을 극대화하는 <span>훌륭한 리더, 이팀장님</span>
                </h4>
                <p>“프로젝트를 체계적으로 관리해 팀을 성공적으로 이끌 수 있었어요.”</p>
              </li>
              <li className={scrollEvt >= 3 ? 'active' : ''}>
                <h4>
                  누구나 같이 일하고 싶어하는 <span>올해의 우수 직원, 조주임님</span>
                </h4>
                <p>“동료와 협업하여 퀄리티 높은 결과물을 기간 내에 완성했어요.”</p>
              </li>
            </ul>
          </div>
        </MaxWidth>
      </Content02>
      <Content03>
        <MaxWidth>
          <div className="inner">
            <h2>
              요즘 직장인들의 업무 트렌드, <span className="pc-br">시큐이클라우드</span>
            </h2>
            <p>
              <span className="pc-br">
                #너무 편리해요 #보안이 강력해요
                <span className="m-br"> #프로젝트 관리가 수월해요 </span>
              </span>
              <span className="pc-br">#업무 공유가 쉬워요 #놓치는 업무가 없어요</span>
            </p>
          </div>
        </MaxWidth>
      </Content03>
      <Content04>
        <MaxWidth>
          <h2>
            <span>시큐이클라우드</span>란?
          </h2>
          <p className="subTit">
            대내·외 업무에 필요한 모든 기능을
            <span>
              하나로 담은 <span className="fc-b">협업 도구</span>
              입니다.
            </span>
          </p>
          <ul>
            <li>
              <h3>
                <span>보안</span>
                <span>
                  <span className="fc-b">SECU</span>rity
                </span>
              </h3>
              <p>
                <span>기업의 중요한 정보 자산을 </span>
                안전하게 보호·관리
              </p>
              <Link to="/security">보안 바로가기</Link>
            </li>
            <li>
              <h3>
                <span>메일</span>
                <span>
                  <span className="fc-b">E</span>mail
                </span>
              </h3>
              <p>
                <span>효율적인 메일 관리를 위한 </span>
                다양한 메일 편의 기능
              </p>
              <Link to="/mail">메일 바로가기</Link>
            </li>
            <li>
              <h3>
                <span>그룹웨어</span>
                <span>
                  <span className="fc-b">Cloud</span> <span className="sub">Groupware</span>
                </span>
              </h3>
              <p>
                <span>프로젝트 및 업무의 </span>
                체계적인 관리와 협업 기능
              </p>
              <Link to="/groupware">그룹웨어 바로가기</Link>
            </li>
          </ul>
        </MaxWidth>
      </Content04>
      <Content05 ref={chartRef}>
        <h2>
          <span>‘시큐이클라우드’와 함께</span>
          업무를 효율적으로 디자인하세요
        </h2>
        <MaxWidth>
          <div className={scrollEvt >= 4 ? 'active chart' : 'chart'}>
            <Radar data={chartData} options={options} />
            <span>접근성</span>
            <span>안전성</span>
            <span>효율성</span>
            <span>신속성</span>
            <span>다양성</span>
            <img src="/images/img-chart.png" alt="" className="chartImg" />
          </div>

          <ul className={scrollEvt >= 4 ? 'active chartList' : 'chartList'}>
            <li>
              <span className="num">1</span>
              <h3>접근성</h3>
              <p>언제, 어디서나 원하는 장소에서 사용할 수 있습니다.</p>
            </li>
            <li>
              <span className="num">2</span>
              <h3>안전성</h3>
              <p>
                <span>EG-Platform의 보안 솔루션</span>으로 안전하게 메일을 사용할 수 있습니다.{' '}
              </p>
            </li>
            <li>
              <span className="num">3</span>
              <h3>효율성</h3>
              <p>
                문서/일정관리 등 <span>그룹웨어 기능</span>을 통해 업무를 효율적으로 수행할 수
                있습니다.
              </p>
            </li>
            <li>
              <span className="num">4</span>
              <h3>신속성</h3>
              <p>
                <span>신속한 커뮤니케이션 및 의사결정</span>으로 지연 없이 업무를 완수할 수
                있습니다.
              </p>
            </li>
            <li>
              <span className="num">5</span>
              <h3>다양성</h3>
              <p>업무에 필요한 다양한 기능을 지원하여 업무 생산성을 향상시킵니다. </p>
            </li>
          </ul>
        </MaxWidth>
      </Content05>
      <Content06>
        <div className="content06Inner">
          <h2>
            여러분의 시큐이클라우드
            <span>
              <span>성공 경험</span>을 공유해주세요
            </span>
          </h2>
          <div className="content06Wrap">
            <button type="button" onClick={() => prevSlide()} disabled={slideNo === 1}>
              {slideNo === 1 ? (
                <img src="/images/icon/ico-content06-arrowL-g.png" alt="이전 슬라이드 이동" />
              ) : (
                <img src="/images/icon/ico-content06-arrowL-b.png" alt="이전 슬라이드 이동" />
              )}
            </button>
            <div className="slideWrap">
              <ul className={slideNo === 2 ? 'nextSlide' : ''}>
                <li>
                  <h3>A 물류회사</h3>
                  <p>
                    <span>“첨부파일을 열어볼 때,</span>
                    랜섬웨어에 감염될까 불안해요”
                  </p>
                  <p>
                    <span>“메일이 안전한지 확인할 수 있어</span>더 이상 불안하지 않아요”
                  </p>
                </li>
                <li>
                  <h3>B 제조회사</h3>
                  <p>
                    <span>“견적서를 첨부한 메일을</span>
                    다른 거래처에 잘못 보냈어요”
                  </p>
                  <p>
                    <span>“보안 메일 회수 기능으로 거래처가</span>메일을 읽기 전에 회수 했어요”
                  </p>
                </li>
                <li>
                  <h3>C 무역회사</h3>
                  <p>
                    <span>“거래처와 유사한 도메인을 사용한</span>
                    사기메일을 받았어요”
                  </p>
                  <p>
                    <span>“시큐이클라우드가 차단하여</span>금전 피해를 막을 수 있었어요”
                  </p>
                </li>
                <li>
                  <h3>D 제약회사</h3>
                  <p>
                    <span>“신기술이 들어있는 중요한</span>
                    첨부파일의 유출 위험이 있어요”
                  </p>
                  <p>
                    <span>“승인메일 기능으로 첨부파일 유출</span>피해를 막을 수 있었어요”
                  </p>
                </li>
                <li>
                  <h3>E 공공기관</h3>
                  <p>
                    <span>“내부 사항.zip파일로 온 메일이</span>
                    피싱메일 이었어요”
                  </p>
                  <p>
                    <span>시큐이클라우드 안전검사보고서로</span>피해를 막을 수 있었어요”
                  </p>
                </li>
                <li>
                  <h3> F 일반회사</h3>
                  <p>
                    <span>"급여명세서와 같이 개인적인 메일이</span>
                    잘못 전송될까 불안해요"
                  </p>
                  <p>
                    <span>“보안메일의 비밀번호 설정으로</span>메일을 안전하게 보낼 수 있었어요”
                  </p>
                </li>
              </ul>
            </div>

            <button type="button" onClick={() => nextSlide()} disabled={slideNo === 2}>
              {slideNo === 2 ? (
                <img src="/images/icon/ico-content06-arrowR-g.png" alt="다음 슬라이드 이동" />
              ) : (
                <img src="/images/icon/ico-content06-arrowR-b.png" alt="다음 슬라이드 이동" />
              )}
            </button>
          </div>
        </div>
      </Content06>
      <BottomBtnGroup>
        <button
          className="inquerybtn"
          type="button"
          onClick={() => navigate('/customerSupport/contactUs')}
        >
          <img src="/images/img-inquiry.png" alt="문의하기" className="pcBtn inquery" />
          <img src="/images/img-inquiry_m.png" alt="문의하기" className="mBtn" />
        </button>
        <button type="button" onClick={onTop} className={noWheel ? '' : 'active'}>
          <img src="/images/img-top.png" alt="최상단 이동" className="pcBtn" />
          <img src="/images/img-top_m.png" alt="최상단 이동" className="mBtn" />
        </button>
      </BottomBtnGroup>
 {/*     {closePop && <EventPopUp onClickPop={onClickPop} onChangeToday={onChangeToday} />}*/}
    </Section>
  );
};

export default Main;
