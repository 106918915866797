import styled from 'styled-components';

export const DownloadWrapper = styled.div`
  border-radius: 15px;
  box-sizing: border-box;

  .confirm_content {
    margin: 100px 0 0 0;

    @media screen and (max-width: 870px) {
      margin: 80px 20px 15px 20px;
    }
    @media screen and (max-width: 650px) {
      margin: 100px 15px 25px 15px;
    }
    @media screen and (max-width: 590px) {
      margin: 70px 0 20px 0;
    }

    h2 {
      color: #5d5d5d;
      font-weight: 500;
      font-family: SBAggrolL;
      font-size: 30px;
      margin: 0 0 25px 0;

      @media screen and (max-width: 850px) {
        font-size: 27px;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 20px 0;
        font-size: 24px;
      }
      @media screen and (max-width: 590px) {
        margin: 0 0 15px 0;
        font-size: 24px;
      }
    }

    .salutation {
      p {
        font-size: 16px;
        font-weight: 400;
        font-family: 'NotoSansKr';
        color: #8e8e8e;
        letter-spacing: -0.025em;
        word-break: keep-all;
        line-height: 1.6;

        &:last-child {
          margin: 0 0 45px 0;

          @media screen and (max-width: 850px) {
            margin: 0 0 40px 0;
          }
        }

        @media screen and (max-width: 850px) {
          font-size: 15px;
        }

        @media screen and (max-width: 750px) {
          font-size: 14px;
        }
      }
    }
    button {
      padding: 11px 50px;
      font-size: 16px;
      font-weight: 400;
      font-family: 'NotoSansKr';
      background: #4d81db;
      border-radius: 15px;
      color: #ffffff;
    }
  }

  .dconfirm_content {
    margin: 100px 0 45px 0;

    @media screen and (max-width: 1300px) {
      margin: 100px 60px 45px 60px;
    }

    @media screen and (max-width: 900px) {
      margin: 100px 30px 40px 30px;
    }
    @media screen and (max-width: 850px) {
      margin: 100px 30px 35px 30px;
    }
    @media screen and (max-width: 750px) {
      margin: 90px 20px 40px 20px;
    }
    @media screen and (max-width: 550px) {
      margin: 80px 10px 30px 10px;
    }

    h2 {
      color: #5d5d5d;
      font-weight: 500;
      font-family: SBAggrolL;
      font-size: 30px;
      margin: 0 0 25px 0;

      @media screen and (max-width: 850px) {
        font-size: 24px;
        margin: 0 0 10px 0;
      }
      @media screen and (max-width: 500px) {
        font-size: 24px;
        margin: 0 0 10px 0;
      }
      @media screen and (max-width: 401px) {
        line-height: 1.2;
      }
    }

    .salutation {
      p {
        font-size: 16px;
        font-weight: 400;
        font-family: 'NotoSansKr';
        color: #8e8e8e;
        letter-spacing: -0.025em;
        word-break: keep-all;
        line-height: 1.6;

        &:last-child {
          margin: 0 0 45px 0;

          @media screen and (max-width: 850px) {
            margin: 0 0 50px 0;
          }
        }

        @media screen and (max-width: 850px) {
          font-size: 14px;
        }

        @media screen and (max-width: 750px) {
          font-size: 14px;
        }
      }
    }
    button {
      padding: 11px 50px;
      font-size: 16px;
      font-weight: 400;
      font-family: 'NotoSansKr';
      background: #4d81db;
      border-radius: 15px;
      color: #ffffff;
    }
  }
`;
