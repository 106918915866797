import React, { useState, useCallback } from 'react';
import { Content, Question, Answer } from './style';
import trTags from 'hooks/useTrTag';

const ContentItem = ({ info }) => {
  const [contentToggle, setContentToggle] = useState(false);
  return (
    <>
      <Content
        onClick={() => {
          setContentToggle((prev) => !prev);
        }}
      >
        <Question>
          <span>Q</span>
          <div>
            <span className="category">{info.categorySo === 'U' ? '이용방법' : '제품특징'}</span>
            <span className="title">{info.question}</span>
          </div>
          <i className={contentToggle ? 'drop-down active' : 'drop-down'} />
        </Question>
        {contentToggle && (
          <Answer>
            <span>A</span>
            <span
              className="contents"
              dangerouslySetInnerHTML={{ __html: trTags(info.answer) }}
            ></span>
          </Answer>
        )}
      </Content>
    </>
  );
};

export default ContentItem;
