import styled from 'styled-components';

export const FooterWrap = styled.footer`
  background-color: #ffffff;
  padding: 60px 100px;
  position: relative;

  &.bgGray {
    background-color: #f9faff;
  }
  @media screen and (max-width: 1300px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 1050px) {
    padding: 75px 40px 95px;
  }
  @media screen and (max-width: 550px) {
    padding: 85px 30px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d0d1d6;
  padding-bottom: 30px;
  margin-bottom: 25px;

  @media screen and (max-width: 1050px) {
    padding-bottom: 65px;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 550px) {
    padding-bottom: 80px;
    margin-bottom: 20px;
  }

  & > div {
    width: 180px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 5px 5px 18px 5px rgba(56, 116, 218, 0.08);
    position: relative;
    z-index: 3;
    @media screen and (max-width: 550px) {
      position: absolute;
      bottom: 60px;
    }
    &:hover {
      ul {
        height: 70px;
        box-shadow: 5px 5px 18px 5px rgba(56, 116, 218, 0.08);
      }
      span {
        background-image: url('/images/icon/ico-footer-downArrow.png');
        background-repeat: no-repeat;
        background-position: right center;
        background-origin: content-box;
      }
    }
    ul {
      position: absolute;
      padding-bottom: 35px;
      background-color: #ffffff;
      border-radius: 20px;
      height: 0px;
      left: 0;
      overflow: hidden;
      bottom: 7px;
      -webkit-transition: 0.2s;
      transition: 0.2s;

      li {
        width: 100%;
        display: inline-block;
        height: 35px;
        line-height: 35px;
        text-align: left;
        vertical-align: bottom;
        z-index: -1;
        padding: 0 20px;
        border-bottom: 2px solid #dddddd;
        &:hover {
          background-color: #457cda;
          a {
            color: #ffffff;
          }
        }
        a {
          display: block;
          font-family: 'SBAggrolL';
          font-size: 14px;
          color: #838693;
        }
      }
    }
    span {
      color: #555763;
      background-color: #ffffff;
      width: 100%;
      font-size: 14px;
      font-family: 'SBAggrolL';
      display: inline-block;
      text-align: left;
      line-height: 2.5;
      height: 35px;
      position: relative;
      z-index: 3;
      border-radius: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      background-image: url('/images/icon/ico-footer-arrow.png');
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
  font-weight: 400;
  position: relative;

  span {
    line-height: 1.5;
  }
  .dis-b_tb {
    display: block;
    margin-top: 0;
  }
  &.bot {
    display: flex;
    margin: -7px 0 0 0;

    @media screen and (max-width: 1300px) {
      div:nth-of-type(1) {
        margin: 0 0 20px 0;
      }
      div:nth-of-type(2) {
        @media screen and (max-width: 620px) {
          margin: 0 0 50px 0;
        }
      }
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    .contact_number {
      span {
        @media screen and (max-width: 620px) {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 1570px) {
    .unit {
      display: none;
    }
  }
  span {
    font-size: 15px;
    color: #9093a1;

    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 330px) {
      font-size: 13px;
    }
  }
  .btnWrap {
    line-height: 1.5;

    @media screen and (max-width: 1050px) {
      position: absolute;
      top: -85px;
    }

    @media screen and (max-width: 550px) {
      position: absolute;
      top: -90px;
    }
  }
  & > div {
    button {
      color: #555763;
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      &:not(:last-child):after {
        content: '·';
        margin: 0 15px;

        @media screen and (max-width: 600px) {
          margin: 0 17px;
        }
      }
      @media screen and (max-width: 360px) {
        font-size: 13px;
      }
      @media screen and (max-width: 330px) {
        font-size: 12px;
      }
    }
    a {
      color: #555763;
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      @media screen and (max-width: 360px) {
        font-size: 13px;
      }
      @media screen and (max-width: 330px) {
        font-size: 12px;
      }
      &:after {
        content: '·';
        margin: 0 15px;
        @media screen and (max-width: 600px) {
          margin: 0 17px;
        }
      }
    }
  }
  .phone {
    margin-right: 35px;
  }
`;
