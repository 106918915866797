import styled, { keyframes } from 'styled-components';

const overlap = keyframes`
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;
const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const Section = styled.section`
  height: 100%;
`;
export const BottomBtnGroup = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 4;

  @media screen and (max-width: 850px) {
    right: 30px;
  }
  @media screen and (max-width: 800px) {
    text-align: center;
  }
  .inquery {
    transform: rotate(360deg) translateY(-5px);
  }
  .inquerybtn {
    img {
      transform: rotate(0deg) translateY(-5px);
    }
  }
  .pcBtn {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .mBtn {
    display: none;
    @media screen and (max-width: 800px) {
      display: inline;
      box-shadow: 0px 3px 15px rgba(43, 88, 163, 0.1);
      border-radius: 50%;
    }
  }
  button {
    border: 0;
    background-color: transparent;

    @media screen and (max-width: 800px) {
      display: block;
    }
    img {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &.active {
      img {
        transform: rotate(0deg);
      }
    }
  }
`;
export const Content01 = styled.article`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  @media screen and (max-width: 1400px) {
    justify-content: start;
  }
  @media screen and (max-width: 1260px) {
    justify-content: center;
    height: 840px;
    margin-top: 100px;
  }
  @media screen and (max-width: 500px) {
    height: 720px;
  }
  &:before {
    position: absolute;
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url('/images/background/bg-main01.png');
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -1;
    @media screen and (max-width: 1400px) {
      background-size: 470px;
    }
    @media screen and (max-width: 1260px) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url('/images/background/bg-main01_bot.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: -1;
    @media screen and (max-width: 1260px) {
      background-image: url('/images/background/bg-main01_bot_m.png');
      background-position: center bottom;
    }
    @media screen and (max-width: 500px) {
      background-position: -660px 115px;
    }
  }

  & > div {
    flex-grow: 1;
    width: 50%;
    height: 100%;
  }

  .img {
    overflow-x: hidden;
    @media screen and (max-width: 1400px) {
      position: absolute;
      right: 0px;
    }
    @media screen and (max-width: 1260px) {
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: 100%;
    }
    .inner {
      width: 940px;
      height: 100%;
      position: relative;
      @media screen and (max-width: 1260px) {
        width: 510px;
        height: 420px;
        margin: 0 auto;
      }
      @media screen and (max-width: 500px) {
        width: 300px;
      }
      img {
        position: absolute;
      }

      .img01 {
        top: 33%;
        left: 10%;
        animation: ${fade} 0.5s linear alternate;
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: 1s;
        @media screen and (max-width: 1400px) {
          height: 390px;
          top: 41%;
        }
        @media screen and (max-width: 1260px) {
          height: 305px;
          left: 90px;
          top: 95px;
        }
        @media screen and (max-width: 500px) {
          height: 225px;
          left: 40px;
          top: 101px;
        }
      }

      .img02 {
        left: 3%;
        top: 47%;
        animation: ${fade} 0.8s linear alternate;
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: 2s;
        @media screen and (max-width: 1400px) {
          height: 280px;
          top: 55%;
        }
        @media screen and (max-width: 1260px) {
          height: 220px;
          top: 195px;
          left: 35px;
        }
        @media screen and (max-width: 500px) {
          height: 160px;
          top: 169px;
          left: 0px;
        }
      }

      .img03 {
        left: 18%;
        top: 21%;
        animation: ${fade} 0.8s linear alternate;
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: 1.5s;
        @media screen and (max-width: 1400px) {
          height: 440px;
          top: 29%;
        }
        @media screen and (max-width: 1260px) {
          height: 340px;
          top: 15px;
          left: 140px;
        }
        @media screen and (max-width: 500px) {
          height: 250px;
          top: 40px;
          left: 65px;
        }
      }
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-left: 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1400px) {
      justify-content: start;
      width: 100%;
    }
    @media screen and (max-width: 1260px) {
      justify-content: center;
      text-align: center;
      align-items: end;
      padding-left: 0px;
    }
  }

  .textInner {
    max-width: 700px;
    width: 100%;

    .top {
      font-weight: 400;
      font-size: 28px;
      color: #3d6eb6;
      font-family: 'GongGothic' !important;
      font-weight: 500;
      animation: ${overlap} 1s linear alternate;
      @media screen and (max-width: 1260px) {
        font-size: 22px;
      }
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }

      span {
        font-family: 'GongGothic' !important;
        font-weight: 500;
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #3d6eb6;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    h1 {
      font-family: 'SBAggrolL' !important;
      font-weight: 600;
      color: #3564b5;
      font-size: 82px;
      padding: 30px 0 55px;
      animation: ${overlap} 1s linear alternate;
      letter-spacing: -2px;
      @media screen and (max-width: 1260px) {
        font-size: 56px;
        padding: 20px 0 35px;
      }
      @media screen and (max-width: 500px) {
        font-size: 40px;
        padding: 15px 0 30px;
      }
      @media screen and (max-width: 350px) {
        font-size: 37px;
      }
      span {
        font-family: 'SBAggrolL' !important;
        font-weight: 600;

        &:first-of-type {
          color: #45bbec;
        }

        &:last-of-type {
          color: #457cda;
        }
      }
    }

    .bot {
      color: #737373;
      font-size: 20px;
      font-weight: 400;
      animation: ${overlap} 0.7s linear alternate;
      animation-fill-mode: forwards;
      opacity: 0;
      animation-delay: 0.3s;
      word-break: break-word;

      @media screen and (max-width: 1260px) {
        font-size: 18px;
      }
      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
      @media screen and (max-width: 400px) {
        padding: 0 30px;
      }
      span {
        display: block;
        margin-bottom: 20px;
        @media screen and (max-width: 400px) {
          display: inline-block;
          margin-bottom: 0;
          line-height: 1.6;
        }
        &:last-of-type {
          margin-bottom: 50px;
          @media screen and (max-width: 1260px) {
            margin-bottom: 0;
          }
        }
      }
    }

    a {
      color: #9c9c9c;
      font-weight: 500;
      font-family: 'SBAggrolL' !important;
      font-size: 14px;
      background-color: transparent;
      border: 0;
      width: 180px;
      box-sizing: border-box;
      letter-spacing: 0.9px;
      height: 45px;
      background-color: #fff;
      border-radius: 25px;
      box-shadow: 3px 3px 15px rgba(43, 88, 163, 0.1);
      padding-right: 45px;
      background-image: url('/images/icon/ico-more-g.png');
      background-repeat: no-repeat;
      background-position: 130px center;
      transition: 0.3s;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-top: 4px;
      animation: ${overlap} 0.5s linear alternate;
      animation-fill-mode: forwards;
      opacity: 0;
      animation-delay: 0.8s;

      &:hover {
        color: #3d6eb6;
        padding-right: 0px;
        padding-left: 25px;
        background-image: url('/images/icon/ico-more-b.png');
        font-weight: 500;
        background-position: 20px center;
      }

      @media screen and (max-width: 1260px) {
        margin: 45px 0 70px;
      }
      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin: 30px 0 70px;
        width: 160px;
        padding-right: 35px;
        background-position: 115px center;
        font-weight: 400;
        height: 40px;
      }
    }
  }
`;
export const Content02 = styled.article`
  & > div {
    background-image: url('/images/background/bg-main02.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 105px 0px 120px;
    @media screen and (max-width: 1260px) {
      padding: 100px 0;
    }
    @media screen and (max-width: 600px) {
      padding: 75px 0 80px;
      background-image: url('/images/background/bg-main02_m.png');
      background-position: center 330px;
    }
  }

  .inner {
    display: flex;
    padding: 0 40px;
    @media screen and (max-width: 1260px) {
      display: block;
    }
    @media screen and (max-width: 500px) {
      padding: 0 30px;
    }
  }

  h2 {
    width: 30%;
    @media screen and (max-width: 1260px) {
      width: 100%;
      text-align: center;
      margin-bottom: 45px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 35px;
    }

    & > span {
      display: block;
      color: #606060;
      font-size: 44px;
      font-weight: 500;
      font-family: 'SBAggrolL' !important;
      margin-bottom: 30px;
      @media screen and (max-width: 1260px) {
        font-size: 30px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 600px) {
        font-size: 24px;
      }
      @media screen and (max-width: 380px) {
        font-size: 22px;
      }
      @media screen and (max-width: 350px) {
        font-size: 21px;
      }
      @media screen and (max-width: 335px) {
        font-size: 20px;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        @media screen and (max-width: 1260px) {
          display: inline;
        }
      }

      span {
        font-family: 'SBAggrolL' !important;
        color: #457cda;
      }
    }
  }

  ul {
    display: grid;
    width: 70%;
    row-gap: 50px;
    @media screen and (max-width: 1260px) {
      display: block;
      width: 100%;
    }

    li {
      width: 750px;
      background-color: #fff;
      padding: 40px 0 40px 180px;
      box-sizing: border-box;
      box-shadow: 0px 3px 15px rgba(43, 88, 163, 0.1);
      border-radius: 20px;
      background-repeat: no-repeat;
      background-position: 40px bottom;
      opacity: 0;
      transform: translateY(60px);
      transition: 1s;
      @media screen and (max-width: 1260px) {
        width: 100%;
        max-width: 750px;
        margin: 0 auto 20px;
        padding-right: 30px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 600px) {
        padding: 20px 20px 35px;
        box-sizing: border-box;
        text-align: center;
        transform: translateY(0px);
        opacity: 1;
      }

      &.active {
        transform: translateY(0px);
        opacity: 1;
      }

      &:nth-of-type(1) {
        justify-self: end;
        background-image: url('/images/img-main02-item01.png');
        @media screen and (max-width: 600px) {
          background-image: none;
          h4 {
            background-image: url('/images/img-main02-item01_m.png');
          }
        }
      }

      &:nth-of-type(2) {
        justify-self: start;
        background-image: url('/images/img-main02-item02.png');
        @media screen and (max-width: 600px) {
          background-image: none;
          h4 {
            background-image: url('/images/img-main02-item02_m.png');
          }
        }
      }

      &:nth-of-type(3) {
        justify-self: end;
        background-image: url('/images/img-main02-item03.png');
        @media screen and (max-width: 600px) {
          background-image: none;
          h4 {
            background-image: url('/images/img-main02-item03_m.png');
          }
        }
      }

      h4 {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        font-family: 'SBAggrolL' !important;
        margin-bottom: 15px;
        line-height: 1.3;
        span {
          color: #000;
          font-size: 20px;
          font-weight: 500;
          font-family: 'SBAggrolL' !important;
          line-height: 1.3;
        }
        @media screen and (max-width: 600px) {
          span {
            display: block;
            line-height: 1.5;
            font-size: 16px;
          }
          line-height: 1.5;
          max-width: 270px;
          box-sizing: border-box;
          margin: 0 auto 15px;
          font-size: 16px;
          background-repeat: no-repeat;
          padding: 15px 0 15px 65px;
          background-position: left center;
          margin-bottom: 5px;
        }
        @media screen and (max-width: 380px) {
          padding: 15px 0 15px 90px;
          background-position: 15px center;
          span {
            display: inline;
          }
        }
      }

      p {
        color: #454545;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.3;
        @media screen and (max-width: 600px) {
          line-height: 1.4;
          font-size: 16px;
          max-width: 280px;
          margin: 0 auto;
        }
      }
    }
  }
`;
export const Content03 = styled.article`
  background-color: #4d81db;
  position: relative;
  text-align: center;
  padding: 100px 0 90px;
  @media screen and (max-width: 1200px) {
    padding: 75px 0 85px;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0;
    overflow: hidden;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('/images/background/bg-main03-left.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    top: 0;
    left: 0;
    @media screen and (max-width: 600px) {
      left: -115px;
      background-size: 300px;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('/images/background/bg-main03-right.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 360px;
    top: 0;
    right: 0;
    @media screen and (max-width: 1200px) {
      background-position: 670px center;
    }
    @media screen and (max-width: 600px) {
      background-size: 800px;
      right: -60%;
      background-position: left center;
    }
  }

  h2 {
    color: #fff;
    font-size: 56px;
    font-weight: 500;
    font-family: 'SBAggrolL' !important;
    margin-bottom: 30px;
    line-height: 1.3;
    @media screen and (max-width: 1200px) {
      font-size: 40px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 600px) {
      font-size: 28px;
      margin-bottom: 20px;
    }
    span {
      font-size: 56px;
      font-weight: 500;
      font-family: 'SBAggrolL' !important;
      @media screen and (max-width: 1200px) {
        font-size: 40px;
        display: block;
      }
      @media screen and (max-width: 600px) {
        font-size: 28px;
      }
    }
  }

  p {
    line-height: 1.5;
    span {
      color: #fff;
      font-size: 26px;
      font-weight: 500;
      &.pc-br {
        display: block;
      }
      @media screen and (max-width: 1200px) {
        font-size: 22px;
      }
      @media screen and (max-width: 670px) {
        font-size: 18px;
        &.pc-br {
          margin-bottom: 3px;
        }
        &.m-br {
          margin-top: 3px;
          display: block;
        }
      }
    }
  }

  .inner {
    position: relative;
    z-index: 2;
  }
`;
export const Content04 = styled.article`
  text-align: center;
  padding: 160px 0 180px;
  @media screen and (max-width: 1260px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0;
  }
  h2 {
    color: #4e4e4e;
    font-size: 54px;
    font-weight: 500;
    font-family: 'SBAggrolL' !important;
    margin-bottom: 25px;
    @media screen and (max-width: 1260px) {
      font-size: 38px;
      margin-bottom: 15px;
    }
    @media screen and (max-width: 630px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 500px) {
      font-size: 30px;
    }
    span {
      font-family: 'SBAggrolL' !important;
      color: #457cda;
    }
  }

  .subTit {
    color: #646464;
    font-weight: 500;
    font-size: 26px;
    font-family: 'SBAggrolL' !important;
    margin-bottom: 90px;
    @media screen and (max-width: 1260px) {
      font-size: 20px;
      margin-bottom: 45px;
    }
    @media screen and (max-width: 500px) {
      font-size: 16px;
      margin-bottom: 35px;
    }
    span {
      font-family: 'SBAggrolL' !important;
      @media screen and (max-width: 650px) {
        display: block;
        margin-top: 5px;
      }
    }
    .fc-b {
      font-family: 'SBAggrolL' !important;
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -5px;
        left: -3px;
        width: 105%;
        height: 110%;
        background-color: rgba(202, 221, 255, 0.4);
        z-index: -1;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1260px) {
      display: block;
      text-align: center;
    }

    li {
      width: calc(33.3% - 27px);
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 3px 5px 15px rgba(43, 88, 163, 0.1);
      padding: 70px 0;
      box-sizing: border-box;
      @media screen and (max-width: 1260px) {
        max-width: 700px;
        width: 100%;
        display: inline-block;
        padding: 55px;
        text-align: left;
        margin-bottom: 30px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 500px) {
        padding: 45px 25px 50px 30px;
      }
      &:nth-of-type(2) {
        h3 span span:after {
          width: 123%;
          z-index: -1;
        }
      }

      h3 {
        margin-bottom: 35px;
        line-height: 1.4;
        @media screen and (max-width: 1260px) {
          display: flex;
          flex-direction: row-reverse;
          justify-content: start;
          align-items: center;
          margin-bottom: 5px;
        }

        & > span {
          display: block;
          color: #4e4e4e;
          font-size: 34px;
          font-weight: 500;
          font-family: 'SBAggrolL' !important;
          @media screen and (max-width: 800px) {
            font-size: 28px;
          }
          @media screen and (max-width: 500px) {
            font-size: 26px;
          }
          &:first-of-type {
            font-size: 22px;
            margin-left: 15px;
            @media screen and (max-width: 630px) {
              display: none;
            }
          }
          .sub {
            font-family: 'SBAggrolL' !important;
            font-size: 28px;
            @media screen and (max-width: 500px) {
              font-size: 26px;
            }
          }
          .fc-b {
            font-family: 'SBAggrolL' !important;
            color: #457cda;
            font-weight: 500;
            font-size: 34px;
            position: relative;
            z-index: 1;
            @media screen and (max-width: 800px) {
              font-size: 28px;
            }
            @media screen and (max-width: 500px) {
              font-size: 26px;
            }
            &:after {
              content: '';
              display: inline-block;
              background-color: #caddff;
              opacity: 0.4;
              width: 105%;
              height: 80%;
              position: absolute;
              top: 15px;
              left: 50%;
              transform: translateX(-50%);
              z-index: -1;
              @media screen and (max-width: 500px) {
                top: 10px;
              }
            }
          }
        }
      }

      p {
        color: #6b6b6b;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 65px;
        @media screen and (max-width: 1260px) {
          margin-bottom: 25px;
        }
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }

        span {
          display: block;
          @media screen and (max-width: 1260px) {
            display: inline;
          }
        }
      }

      a {
        color: #4e4e4e;
        font-size: 18px;
        font-family: 'SBAggrolL' !important;
        font-weight: 500;
        padding: 22px 40px 20px 40px;
        width: 300px;
        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 3px 5px 15px rgba(43, 88, 163, 0.1);
        border-radius: 30px;
        display: inline-block;
        text-align: left;
        background-image: url('/images/icon/ico-rightArrow.png');
        background-position: 260px center;
        background-repeat: no-repeat;
        transition: 0.3s;
        @media screen and (max-width: 500px) {
          font-size: 16px;
          width: 205px;
          padding: 20px 25px 18px;
          background-position: 170px center;
        }
        &:hover {
          color: #fff;
          background-color: #4d81db;
          background-image: url('/images/icon/ico-rightArrow-w.png');
        }
      }
    }
  }
`;
export const Content05 = styled.article`
  background-color: #f8f9fd;
  padding: 130px 0 150px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1260px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 680px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 580px) {
    padding: 70px 0 80px;
  }
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('/images/background/bg-content05-left.png');
    background-repeat: no-repeat;
    background-position: left top;
    @media screen and (max-width: 580px) {
      background-position: -175px -85px;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('/images/background/bg-content05-right.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    @media screen and (max-width: 580px) {
      background-position: 0 800px;
    }
  }

  & > h2 {
    text-align: center;
    color: #606060;
    font-weight: 500;
    font-size: 46px;
    font-family: 'SBAggrolL' !important;
    line-height: 1.5;
    padding: 0 20px;
    @media screen and (max-width: 1260px) {
      font-size: 38px;
    }
    @media screen and (max-width: 580px) {
      margin-bottom: 440px;
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 500px) {
      font-size: 26px;
    }
    span {
      color: #457cda;
      display: block;
      font-family: 'SBAggrolL' !important;
    }
  }

  .chart {
    width: 685px;
    position: relative;
    display: inline-block;
    img {
      display: none;
    }
    @media screen and (max-width: 1300px) {
      width: 635px;
    }
    @media screen and (max-width: 1260px) {
      margin: 0 auto;
      display: block;
      width: 600px;
      transform: translateY(-40px);
    }
    @media screen and (max-width: 680px) {
      width: 550px;
      overflow: hidden;
    }
    @media screen and (max-width: 580px) {
      width: 500px;
      overflow: hidden;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 160px;
    }
    @media screen and (max-width: 500px) {
      top: 80px;
      position: unset;
      transform: translateX(0);
      margin-bottom: 60px;
      width: 100%;
      text-align: center;
      canvas,
      span {
        display: none !important;
      }
      img {
        display: inline;
      }
    }
    @media screen and (max-width: 400px) {
      top: 100px;
    }
    &.active {
      span {
        &:nth-of-type(1) {
          animation: ${fade} 0.5s linear alternate;
          animation-fill-mode: forwards;
          animation-delay: 1.3s;
        }

        &:nth-of-type(2) {
          animation: ${fade} 0.5s linear alternate;
          animation-fill-mode: forwards;
          animation-delay: 1.8s;
        }

        &:nth-of-type(3) {
          animation: ${fade} 0.5s linear alternate;
          animation-fill-mode: forwards;
          animation-delay: 2.3s;
        }

        &:nth-of-type(4) {
          animation: ${fade} 0.5s linear alternate;
          animation-fill-mode: forwards;
          animation-delay: 2.8s;
        }

        &:nth-of-type(5) {
          animation: ${fade} 0.5s linear alternate;
          animation-fill-mode: forwards;
          animation-delay: 3.1s;
        }
      }
    }

    span {
      position: absolute;
      font-family: 'SBAggrolL' !important;
      color: #457cda;
      font-size: 20px;
      font-weight: 500;
      background-image: url('/images/icon/ico-chartPoint.png');
      background-repeat: no-repeat;
      padding: 7px 0;
      opacity: 0;
      @media screen and (max-width: 500px) {
        font-size: 12px;
        background-size: 20px;
      }
      &:nth-of-type(1) {
        top: 0%;
        left: 46%;
        padding-top: 100px;
        padding-bottom: 40px;
        background-position: center bottom;
        @media screen and (max-width: 580px) {
          left: 45%;
          padding-top: 90px;
        }
        @media screen and (max-width: 500px) {
          left: 46.5%;
          padding-top: 0;
          top: 21%;
          padding-bottom: 25px;
        }
      }

      &:nth-of-type(2) {
        top: 42.5%;
        right: 5%;
        padding-left: 45px;
        background-position: left center;
        @media screen and (max-width: 580px) {
          top: 43.5%;
        }
        @media screen and (max-width: 500px) {
          top: 44%;
          padding-left: 25px;
          right: 12.5%;
        }
        @media screen and (max-width: 380px) {
          top: 40%;
          padding-left: 0px;
          right: 18.5%;
          padding-bottom: 25px;
          background-position: center bottom;
        }
      }

      &:nth-of-type(3) {
        bottom: 2.5%;
        right: 25.5%;
        padding-top: 40px;
        padding-bottom: 50px;
        background-position: center top;
        @media screen and (max-width: 580px) {
          right: 27.5%;
        }
        @media screen and (max-width: 500px) {
          bottom: 5.5%;
          right: 29.5%;
          padding-top: 25px;
        }
      }

      &:nth-of-type(4) {
        bottom: 2.5%;
        left: 25.5%;
        padding-top: 40px;
        padding-bottom: 50px;
        background-position: center top;
        @media screen and (max-width: 580px) {
          left: 26.5%;
        }
        @media screen and (max-width: 500px) {
          bottom: 5.5%;
          padding-top: 25px;
          left: 29.5%;
        }
      }

      &:nth-of-type(5) {
        top: 42.5%;
        left: 5%;
        padding-right: 45px;
        background-position: right center;
        @media screen and (max-width: 580px) {
          top: 43.5%;
        }
        @media screen and (max-width: 500px) {
          top: 44%;
          left: 12.6%;
          padding-right: 25px;
        }
        @media screen and (max-width: 380px) {
          top: 40%;
          padding-right: 0px;
          left: 19%;
          padding-bottom: 25px;
          background-position: center bottom;
        }
      }
    }
  }

  .chartList {
    width: calc(100% - 720px);
    margin-top: 130px;
    margin-left: 30px;
    position: relative;
    opacity: 0;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1300px) {
      width: calc(100% - 670px);
      margin-top: 100px;
    }
    @media screen and (max-width: 1260px) {
      display: block;
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }

    &.active {
      animation: ${overlap} 1s linear alternate;
      animation-fill-mode: forwards;

      animation-delay: 0.8s;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 35px;
      left: 16px;
      width: 2px;
      height: 90%;
      background-image: url('/images/icon/ico-listBorder.png');
      background-position: top;
      @media screen and (max-width: 1300px) {
        height: 85%;
      }
      @media screen and (max-width: 500px) {
        height: 75%;
      }
    }

    li {
      position: relative;
      padding-left: 90px;
      margin-bottom: 50px;
      @media screen and (max-width: 1300px) {
        margin-bottom: 30px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .num {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #fff;
        font-family: 'SBAggrolL' !important;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        background-color: #3b76db;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 500px) {
          font-size: 14px;
          top: 13px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 130%;
          height: 130%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: #3b76db;
          opacity: 0.2;
          z-index: -2;
        }

        &:after {
          content: '';
          display: inline-block;
          width: 160%;
          height: 160%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: #3b76db;
          opacity: 0.1;
          z-index: -1;
        }
      }

      h3 {
        color: #3771d6;
        font-size: 24px;
        font-weight: 500;
        font-family: 'SBAggrolL' !important;
        margin-bottom: 10px;
        @media screen and (max-width: 500px) {
          font-size: 20px;
        }
      }

      p {
        color: #575757;
        font-size: 18px;
        letter-spacing: -0.5px;
        font-weight: 500;
        line-height: 1.5;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
        @media screen and (max-width: 365px) {
          font-size: 15px;
        }
        span {
          color: #3771d6;
          position: relative;
          display: inline-block;
          font-weight: 600;

          &:before {
            display: inline-block;
            content: '';
            background-color: #d6f3ff;
            width: 103%;
            height: 110%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }
      }
    }
  }
`;

export const Content06 = styled.article`
  padding: 145px 0 160px;
  @media screen and (max-width: 1270px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 1270px) {
    padding: 75px 0;
  }
  .content06Inner {
    max-width: 1560px;
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .content06Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: 0;
      @media screen and (max-width: 1270px) {
        display: none;
      }
    }
  }

  h2 {
    color: #606060;
    font-weight: 500;
    font-size: 46px;
    font-family: 'SBAggrolL' !important;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 90px;
    @media screen and (max-width: 1270px) {
      font-size: 38px;
      margin-bottom: 45px;
    }
    @media screen and (max-width: 500px) {
      font-size: 26px;
      margin-bottom: 40px;
    }
    @media screen and (max-width: 400px) {
      font-size: 24px;
    }
    @media screen and (max-width: 340px) {
      font-size: 22px;
    }
    & > span {
      display: block;
      font-family: 'SBAggrolL' !important;

      span {
        font-family: 'SBAggrolL' !important;
        color: #457cda;
      }
    }
  }

  .slideWrap {
    width: 1165px;
    margin: 0 auto;
    overflow: hidden;
    @media screen and (max-width: 1300px) {
      width: 1135px;
    }
    @media screen and (max-width: 1270px) {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: auto;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        height: 4px;
        background-color: #90ace2;
      }
      &::-webkit-scrollbar-track {
        background-color: #e7edf4;
      }
    }
  }

  ul {
    padding: 10px 0;
    width: 2310px;
    white-space: nowrap;
    transition: 0.5s;
    transform: translateX(0%);
    @media screen and (max-width: 1300px) {
      width: 2250px;
    }
    @media screen and (max-width: 1270px) {
      padding-bottom: 50px;
    }

    &.nextSlide {
      transform: translateX(-50.3%);
    }

    li {
      width: 350px;
      display: inline-block;
      margin-right: 30px;
      margin-left: 15px;
      height: 430px;
      background-color: #fff;
      box-sizing: border-box;
      text-align: center;
      box-shadow: 0px 0px 10px rgba(43, 88, 163, 0.1);
      border-radius: 20px;

      @media screen and (max-width: 1300px) {
        margin-right: 15px;
      }
      @media screen and (max-width: 1270px) {
        width: 320px;
        height: 390px;
      }
      @media screen and (max-width: 500px) {
        width: 315px;
        margin-left: 45px;
      }
      @media screen and (max-width: 450px) {
        margin-left: 25px;
      }
      @media screen and (max-width: 430px) {
        margin-left: 16px;
      }
      @media screen and (max-width: 400px) {
        margin-right: 10px;
        margin-left: 3px;
      }

      &:nth-of-type(1) {
        h3 {
          background-image: url('/images/icon/ico-content06-list01.png');
        }

        &:hover {
          h3 {
            background-image: url('/images/icon/ico-content06-list07.png');
          }
        }
      }

      &:nth-of-type(2) {
        h3 {
          background-image: url('/images/icon/ico-content06-list02.png');
        }

        &:hover {
          h3 {
            background-image: url('/images/icon/ico-content06-list08.png');
          }
        }
      }

      &:nth-of-type(3) {
        margin-right: 0;
        @media screen and (max-width: 1270px) {
          margin-right: 15px;
        }

        h3 {
          background-image: url('/images/icon/ico-content06-list03.png');
        }

        &:hover {
          h3 {
            background-image: url('/images/icon/ico-content06-list09.png');
          }
        }
      }

      &:nth-of-type(4) {
        h3 {
          background-image: url('/images/icon/ico-content06-list04.png');
        }

        &:hover {
          h3 {
            background-image: url('/images/icon/ico-content06-list10.png');
          }
        }
      }

      &:nth-of-type(5) {
        h3 {
          background-image: url('/images/icon/ico-content06-list05.png');
        }

        &:hover {
          h3 {
            background-image: url('/images/icon/ico-content06-list11.png');
          }
        }
      }

      &:nth-of-type(6) {
        margin-right: 0;

        h3 {
          background-image: url('/images/icon/ico-content06-list06.png');
        }

        &:hover {
          h3 {
            background-image: url('/images/icon/ico-content06-list12.png');
          }
        }
      }

      h3 {
        padding-top: 165px;
        color: #000;
        font-size: 24px;
        font-weight: 500;
        font-family: 'SBAggrolL' !important;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        background-position: center 50px;
        transition: 0.2s;
        @media screen and (max-width: 1270px) {
          font-size: 22px;
          padding-top: 150px;
          background-size: 80px;
          background-position: center 30px;
        }
      }

      p {
        &:nth-of-type(1) {
          font-size: 18px;
          font-weight: 400;
          color: #454545;
          line-height: 1.3;
          @media screen and (max-width: 1270px) {
            font-size: 16px;
          }
          span {
            display: block;
          }
        }

        &:nth-of-type(2) {
          color: #3d6eb6;
          font-weight: 500;
          font-size: 18px;
          font-family: 'SBAggrolL' !important;
          padding-top: 75px;
          background-image: url('/images/icon/ico-content06-arrow.png');
          background-repeat: no-repeat;
          background-position: center 25px;
          line-height: 1.4;
          @media screen and (max-width: 1270px) {
            font-size: 16px;
            padding-top: 65px;
            background-position: center 15px;
          }
          span {
            display: block;
            font-family: 'SBAggrolL' !important;
          }
        }
      }
    }
  }
`;
