import styled from 'styled-components';

export const PaginationList = styled.ul`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 130px;
  img {
    max-width: unset;
  }
  .first-page {
    margin-right: 15px;
  }
  .prev-page {
    margin-right: 30px;
    @media screen and (max-width: 400px) {
      margin-right: 20px;
    }
    @media screen and (max-width: 350px) {
      margin-right: 15px;
    }
  }
  .page-num {
    margin-right: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #f9faff;
    color: #bdc0d0;
    font-size: 18px;
    font-weight: 400;
    font-family: 'SBAggrolL';
    text-align: center;
    box-sizing: border-box;
    padding-top: 3px;
    &.active {
      background-color: #4d81db;
      color: #ffffff;
    }
    @media screen and (max-width: 400px) {
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
    @media screen and (max-width: 380px) {
      width: 20px;
      height: 20px;
      font-size: 13px;
    }
  }
  .next-page {
    margin-left: 15px;
    margin-right: 15px;
    @media screen and (max-width: 400px) {
      margin-left: 5px;
    }
    @media screen and (max-width: 350px) {
      margin-left: 0px;
    }
  }
  .last-page {
    padding-right: 15px;
  }
`;
