import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import GlobalStyle from 'GlobalStyle';
import Main from 'pages/main';
import Header from 'components/header';
import Footer from 'components/footer';
import Mail from 'pages/mail';
import TermPersonal from './popup/TermPersonal';
import TermEmail from './popup/TermEmail';
import Groupware from 'pages/groupware';
import Security from 'pages/security';
import CustomerSupport from './pages/customerSupport';
import News from './pages/news';
import ScrollToTop from './components/ScrollToTop';

import './font.css';
import { throttle } from 'lodash';
import axios from 'axios';

function App() {
  if (process.env.NODE_ENV !== 'development') {
    axios.defaults.baseURL = 'https://back.secuecloud.co.kr';
  }

  const [noWheel, setNoWheel] = useState(false);

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY !== 0) {
          setNoWheel(true);
        } else {
          setNoWheel(false);
        }
      }, 100),
    [noWheel],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);
  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Header noWheel={noWheel} />
      <Routes>
        <Route path="/" element={<Main noWheel={noWheel} />} />
        <Route path="/mail" element={<Mail />} />
        <Route path="/groupware" element={<Groupware />} />
        <Route path="/security" element={<Security />} />
        <Route path="/news/*" element={<News />} />
        <Route path="/customerSupport/*" element={<CustomerSupport />} />
        <Route path="/popup/termPersonal" element={<TermPersonal />} />
        <Route path="/popup/termEmail" element={<TermEmail />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
