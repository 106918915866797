import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    padding: 0 30px;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top:25px;
  right: 25px;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-size: 20px;
  background-repeat: no-repeat;
  background-image: url('/images/icon/ico-support02_close.png');\
  width: 18px;
  height: 18px;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  position: absolute;
  width: 900px;
  padding: 40px 45px 65px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 15px;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    padding: 65px 40px;
    width: 720px;
  }
  @media screen and (max-width: 750px) {
    padding: 45px 25px;
    width: 90vw;
  }
  &.download {
    width: 940px;
    padding: 70px 95px;

    @media screen and (max-width: 1200px) {
      padding: 65px 40px;
      width: 720px;
    }
    @media screen and (max-width: 750px) {
      padding: 45px 25px;
      width: 90vw;
    }
  }
  &.confirm {
    position: relative;
    width: 600px;

    &:after {
      content: '';
      background: url('/images/img-confirm.png') no-repeat center center / cover;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 11%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;

      @media screen and (max-width: 870px) {
        top: 13%;
        width: 50px;
        height: 50px;
      }

      @media screen and (max-width: 590px) {
        top: 11%;
        width: 40px;
        height: 40px;
      }
    }
    @media screen and (max-width: 1300px) {
      width: auto;
    }
  }

  &.downconfirm {
    position: relative;
    width: 500px;
    padding: 40px 30px;
    @media screen and (max-width: 1300px) {
      width: auto;
      padding: 40px 20px;
    }

    &:after {
      content: '';
      background: url('/images/img-confirm.png') no-repeat center center / cover;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 13%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;

      @media screen and (max-width: 850px) {
        top: 15%;
        width: 55px;
        height: 55px;
      }
      @media screen and (max-width: 750px) {
        top: 14%;
        width: 50px;
        height: 50px;
      }

      @media screen and (max-width: 550px) {
        top: 13%;
        width: 45px;
        height: 45px;
      }
    }
  }
`;
