import styled from 'styled-components';

export const PaginationNav = styled.div`
  padding: 0 0 140px 0;

  @media screen and (max-width: 910px) {
    padding: 0 0 100px 0;
  }
`;

export const PaginationUl = styled.ul`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 910px) {
    gap: 10px;
  }

  .prev_page {
    margin: 0 20px 0 0;
  }

  .page {
    font-size: 18px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #bdc0d0;
    background: #f9faff;

    &.on {
      color: #ffffff;
      background: #4d81db;
    }
  }

  .next_page {
    margin: 0 0 0 20px;
  }
`;
