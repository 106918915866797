import React, { useCallback, useEffect, useState } from 'react';
import { DarkWrap, HeaderNav, MobileNav, ToggleBtn } from './style';
import { Link, useLocation } from 'react-router-dom';
import { navData } from './navData';

const Header = ({ noWheel }) => {
  const [activeMenu, setActiveMenu] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [sub, setSub] = useState(false);
  const { pathname } = useLocation();

  const toggleIcon = useCallback(() => {
    setToggle((prev) => !prev);
  }, [toggle]);

  const onClickClose = useCallback(() => {
    setToggle(false);
  }, [toggle]);

  useEffect(() => {
    if (pathname === '/mail') {
      setActiveMenu(1);
    } else if (pathname === '/groupware') {
      setActiveMenu(2);
    } else if (pathname === '/security') {
      setActiveMenu(3);
    } else if (pathname.split('/')[1] === 'news') {
      setActiveMenu(4);
    } else if (pathname === '/customerSupport') {
      setActiveMenu(5);
    } else {
      setActiveMenu(0);
    }
    setToggle(false);
    if (pathname === '/') {
      setSub(true);
    } else {
      setSub(false);
    }
  }, [pathname, setSub]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <HeaderNav className={!sub || noWheel ? '' : 'main'}>
      <div className="b-navTop_pc">
        <Link to="/" onClick={() => onTop()}>
          <img src="/images/img-logo.png" alt="SECU E Cloud" />
        </Link>
      </div>
      <div className="b-nav_pc">
        <nav>
          <ul>
            {navData.map((item) => (
              <li key={item.id}>
                <Link to={item.link} className={activeMenu === item.id ? 'active' : ''}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <ToggleBtn type="button" onClick={toggleIcon} className={toggle ? 'active' : ''}>
        <span></span>
        <span></span>
        <span></span>
      </ToggleBtn>
      {toggle && <DarkWrap onClick={onClickClose} />}
      <MobileNav className={toggle ? 'active' : ''}>
        <div className="top">
          <Link to="/">
            <img src="/images/img-logo.png" alt="SECU E Cloud" />
          </Link>
        </div>
        <ul>
          {navData.map((item) => (
            <li key={item.id}>
              <Link to={item.link} className={activeMenu === item.id ? 'active' : ''}>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <Link to="/customerSupport/contactUs">문의 바로가기&nbsp;&nbsp; ></Link>
      </MobileNav>
    </HeaderNav>
  );
};

export default Header;
