import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  box-sizing: border-box;
  padding: 235px 0 0 0;

  @media screen and (max-width: 920px) {
    padding: 150px 0 0 0;
  }
  @media screen and (max-width: 490px) {
    padding: 120px 0 0 0;
  }
`;

export const NewsMaxWidth = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 1300px) {
    width: auto;
    padding: 0 30px;
  }

  .line {
    border-top: 1px solid #dddddd;
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  padding: 0 0 10px 0;
  max-width: 1200px;

  @media screen and (max-width: 950px) {
    padding: 0 0 100px 0;
  }

  @media screen and (max-width: 510px) {
    padding: 0 0 100px 0;
  }

  .main_content {
    display: flex;
    gap: 50px;
    padding: 0 15px;

    @media screen and (max-width: 1300px) {
      padding: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 90px;
      left: 0;
      width: 360px;
      height: 220px;
      background: url('/images/background/bg-cs_circle01.png') no-repeat right center / contain;
      z-index: 0;

      @media screen and (max-width: 1300px) {
        display: none;
      }
    }

    .main_visual {
      flex: 1;
      background: url('/images/img-news-main.png') no-repeat center / contain;
      height: 350px;
      transform: translateY(-60px);

      @media screen and (max-width: 1100px) {
        background: url('/images/img-news-main.png') no-repeat right center / contain;
        height: 350px;
      }

      @media screen and (max-width: 950px) {
        display: none;
        max-width: auto;
        background: none;
        height: auto;
      }
    }

    .title {
      flex: 1;

      h2 {
        font-size: 64px;
        font-weight: 500;
        font-family: 'SBAggrolL';
        margin: 0 0 25px 0;
        letter-spacing: -0.015em;
        color: #4a4a4a;

        @media screen and (max-width: 950px) {
          font-size: 40px;
          margin: 0 0 27px 0;
          text-align: center;
        }
        @media screen and (max-width: 500px) {
          font-size: 34px;
          margin: 0 0 10px 0;
        }
      }

      p {
        font-size: 18px;
        font-weight: 400;
        font-family: 'NotoSansKr';
        margin: 0 0 70px 0;
        letter-spacing: -0.025em;
        color: #505050;

        @media screen and (max-width: 950px) {
          margin: 0 0 50px 0;
          text-align: center;
        }
        @media screen and (max-width: 510px) {
          margin: 0 0 40px 0;
          font-size: 16px;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
        word-break: break-all;
        line-height: 1.45;
      }
    }
  }
`;

export const MainUL = styled.ul`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 950px) {
    justify-content: center;
  }

  @media screen and (max-width: 510px) {
    gap: 10px;
  }

  li {
    a {
      padding: 12px 37px;
      border-radius: 50px;
      font-size: 18px;
      font-family: 'SBAggrolL';
      letter-spacing: -0.025em;
      box-shadow: 5px 5px 5px rgba(153, 153, 153, 0.1);
      color: #505050;
      background: #ffffff;

      @media screen and (max-width: 910px) {
        padding: 13px 30px;
        font-size: 16 px;
      }

      @media screen and (max-width: 510px) {
        padding: 13px 25px;
        font-size: 14px;
      }

      @media screen and (max-width: 350px) {
        padding: 10px 20px;
        font-size: 14px;
      }
      @media screen and (max-width: 320px) {
        padding: 10px 18px;
        font-size: 14px;
        border-radius: 30px;
      }

      &.active {
        background: #4d81db;
        color: #ffffff;
      }
    }
  }
`;
export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 25px 0;

  @media screen and (max-width: 910px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }

  .totalcount {
    p {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Pretendard';
      color: #282828;

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }

      span {
        font-size: 18px;
        color: #4d81db;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
`;

export const ItemsUl = styled.ul`
  padding: 0 0 75px 0;
  cursor: pointer;

  @media screen and (max-width: 1300px) {
    padding: 0 0 100px 0;
  }
  @media screen and (max-width: 1300px) {
    padding: 0 0 75px 0;
  }

  a {
    color: #000;
  }

  li {
    &:last-child {
      border-bottom: 1px solid #dddddd;
    }
  }
`;

export const ITMS = styled.li`
  a {
    display: flex;
    justify-content: space-between;
    padding: 35px 0;
    border-top: 1px solid #dddddd;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    figure {
      border-radius: 10px;
      overflow: hidden;
      width: 400px;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        @media screen and (max-width: 1000px) {
          object-position: 50% 50%;
        }

        @media screen and (max-width: 500px) {
          object-position: 50% 50%;
        }
      }

      @media screen and (max-width: 1300px) {
        width: 40%;
        height: 220px;
        margin: 0 0 30px 0;
      }

      @media screen and (max-width: 700px) {
        max-width: 500px;
        width: 100%;
        height: 270px;
        margin: 0 auto 30px;
      }

      @media screen and (max-width: 500px) {
        height: 185px;
        /*        object-fit: cover;
        object-position: 50% 50%;*/
      }
    }

    .descript {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 900px;

      @media screen and (max-width: 1300px) {
        width: 55%;
      }

      @media screen and (max-width: 700px) {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
      }

      h3 {
        margin: 0 0 30px 0;
        font-size: 24px;
        font-weight: 600;
        font-family: 'Pretendard';
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 0.01em;

        @media screen and (max-width: 1300px) {
          font-size: 22px;
        }

        @media screen and (max-width: 860px) {
          margin: 0 0 20px 0;
          word-break: keep-all;
          font-size: 22px;
          overflow: visible;
          text-overflow: clip;
          white-space: normal;
          line-height: 1.3;
        }
        @media screen and (max-width: 500px) {
          font-size: 20px;
        }
      }

      .content {
        display: block;
        margin: 0 0 30px 0;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Pretendard';
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.4;
        letter-spacing: 0.01em;
        color: #282828;

        @media screen and (max-width: 1300px) {
          font-size: 16px;
        }
      }
      span {
        font-family: 'Pretendard' !important;
        color: #999999 !important;
        font-size: 16px !important;
      }
      .date {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Pretendard';
        color: #999999;
        letter-spacing: 0.01em;
      }
    }
  }
`;

export const Container = styled.div`
  width: 1400px;
  margin: 0 auto;

  .noList {
    font-size: 20px;
    font-weight: 500;
    padding: 200px 0;
    text-align: center;
  }
  @media screen and (max-width: 1300px) {
    width: auto;
    border-top: none;
  }
`;

export const Contentbox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media screen and (max-width: 1300px) {
    gap: 20px;
  }

  @media screen and (max-width: 1299px) {
    justify-content: space-around;
  }

  a {
    color: #282828;

    .lettercon {
      margin: 50px 0 75px 0;
      border-top: none;

      figure {
        width: auto;

        img {
          margin: 0 0 35px 0;

          @media screen and (max-width: 1300px) {
            width: auto;
          }

          @media screen and (max-width: 1299px) {
            width: auto;
          }

          @media screen and (max-width: 960px) {
            margin: 0 0 30px 0;
            width: 900px;
            object-fit: cover;
          }
        }
        p {
          font-size: 24px;
          font-weight: 700;
          font-family: 'Pretendard';
          text-align: center;

          @media screen and (max-width: 1299px) {
            margin: 0 0 70px 0;
          }
        }
      }
    }
  }
`;

export const NLContentbox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0 0 0;

  @media screen and (max-width: 1300px) {
    gap: 20px;
    margin: 50px 0 0 0;
  }
  @media screen and (max-width: 1299px) {
    justify-content: space-around;
  }

  @media screen and (max-width: 800px) {
    margin: 50px 0 0 0;
  }
  @media screen and (max-width: 600px) {
    margin: 30px 0 0 0;
  }
  @media screen and (max-width: 400px) {
    margin: 30px 0 0 0;
  }

  .lettercon {
    margin-bottom: 70px;
    width: 30%;
    @media screen and (max-width: 860px) {
      width: 100%;
      margin-bottom: 60px;
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 40px;
    }
    a {
      color: #282828;
      margin: 0 0 75px 0;
      border-top: none;

      figure {
        div {
          width: 100%;
          height: 485px;
          overflow: hidden;
          margin-bottom: 30px;
          text-align: center;
          @media screen and (max-width: 860px) {
            height: 800px;
            margin-bottom: 40px;
          }
          @media screen and (max-width: 500px) {
            height: 395px;
            width: 100%;
            margin-bottom: 25px;
          }
        }
        img {
          width: 100%;

          @media screen and (max-width: 960px) {
            margin: 0 0 30px 0;
            width: 715px;
            height: 100%;
            object-position: top;
            object-fit: cover;
          }
        }
        p {
          font-size: 24px;
          font-weight: 700;
          font-family: 'Pretendard';
          text-align: center;
          letter-spacing: 0.01em;

          @media screen and (max-width: 800px) {
            font-size: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const SearchBox = styled.div`
  display: flex;
  gap: 15px;
  color: #9c9ea8;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 910px) {
    padding: 0 0 40px 0;
    width: 100%;
  }
  @media screen and (max-width: 350px) {
    display: block;
  }
`;

export const SearchForm = styled.div`
  position: relative;
  cursor: pointer;
`;

export const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-radius: 25px;
  background: #f9faff;
  cursor: pointer;

  @media screen and (max-width: 910px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 500px) {
    padding: 16px 18px;
    justify-content: flex-start;
    gap: 5px;
  }
  // @media screen and (max-width: 350px) {
  //   display: inline-flex;
  //   margin: 0 0 10px 0;
  // }

  @media screen and (max-width: 350px) {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    width: 100px;
  }
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: 'Pretendard';
  width: 80px;

  @media screen and (max-width: 910px) {
    width: 60px;
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
    width: 40px;
  }
`;

export const LabelIcon = styled.span`
  font-size: 19px;

  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
`;

export const SelectOption = styled.ul`
  position: absolute;
  top: 65px;
  left: 0;
  display: ${(props) => (props.show ? 'block' : 'none')};
  overflow: hidden;
  height: auto;
  border-radius: 25px;
  border: 1px solid #b9b9b9;
  z-index: 3;
  @media screen and (max-width: 430px) {
    top: 65px;
  }
  @media screen and (max-width: 350px) {
    top: 57px;
  }
`;

export const Option = styled.li`
  padding: 15px 30px;
  width: 80px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Pretendard';
  line-height: 20px;
  background: #ffffff;
  cursor: pointer;
  letter-spacing: 0.01em;
  &:hover {
    background-color: #f4f4f9;
  }

  @media screen and (max-width: 910px) {
    padding: 15px 22px;
    width: 80px;
  }
  @media screen and (max-width: 500px) {
    padding: 10px 0 10px 15px;
    font-size: 14px;
  }

  @media screen and (max-width: 350px) {
    width: 115px;
    font-size: 14px;
  }
`;

export const Form = styled.form`
  position: relative;

  @media screen and (max-width: 910px) {
    width: 100%;
  }

  input {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
    outline: none;
    border: none;
    padding: 0 70px 0 20px;
    width: 300px;
    height: 50px;
    border-radius: 25px;
    letter-spacing: -0.025em;
    background: #f9faff;

    &::placeholder {
      color: #9c9ea8;
    }

    &:focus::placeholder {
      color: transparent;
    }

    @media screen and (max-width: 910px) {
      padding: 0 100px 0 20px;
      width: calc(100% - 120px);
    }

    @media screen and (max-width: 500px) {
      font-size: 14px;
      padding: 0 100px 0 15px;
    }
    @media screen and (max-width: 400px) {
      padding: 0 15px;
      width: calc(100% - 30px);
    }
    @media screen and (max-width: 350px) {
      font-size: 14px;
      width: calc(100% - 120px);
      padding: 0 100px 0 15px;
    }
  }
  button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;

    @media screen and (max-width: 910px) {
      right: 0;
      font-size: 18x;
    }
    @media screen and (max-width: 500px) {
      right: 0;
      font-size: 18px;
    }

    .btn {
      font-size: 20px;
      color: #9c9ea8;
    }
  }
`;
