import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Form,
  Inner,
  ItemsUl,
  ITMS,
  Label,
  LabelBox,
  LabelIcon,
  NewsMaxWidth,
  Option,
  Search,
  SearchBox,
  SearchForm,
  SelectOption,
} from '../style';

import { Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import NewsMain from '../MainTitle/NewsMain';
import SearchBar from './SearchBar';
import removeTags from 'hooks/useRemoveTag';
import Pagination from '../../../components/pagination';
import TrDate from '../../../hooks/useTrDate';
import LazyImage from '../../../hooks/useLazeImg';

const NewsItem = ({ showOptions, setShowOptions }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('pageNo') || 1;
  const [list, setList] = useState(null);
  const [boardVo, setBoardVo] = useState();
  const [pagingInfo, setPagingInfo] = useState();
  const searchGubun = searchParams.get('searchGubun');
  const searchKeywordVal = searchParams.get('searchKeyword');

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(
          `/board?boardType=T&pageSize=6&pageNo=${currentPage}${
            searchGubun ? `&searchGubun=${searchGubun}` : ''
          }${searchKeywordVal ? `&searchKeyword=${searchKeywordVal}` : ''}`,
        );
        setList(response.data.boardList);
        setBoardVo(response.data.BoardVO);
        setPagingInfo(response.data.Paging);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [searchParams]);

  return (
    <NewsMaxWidth>
      <NewsMain />
      <Container>
        <Search>
          <div className="totalcount">
            <p>
              총 <span>{boardVo?.totalCount ? boardVo?.totalCount : 0}</span> 건
            </p>
          </div>
          <SearchBar setShowOptions={setShowOptions} showOptions={showOptions} />
        </Search>
        {list?.length !== 0 ? (
          <ItemsUl>
            {list?.map((it) => {
              return (
                <ITMS key={it.idx}>
                  <Link to={`detail/${it.idx}`}>
                    <figure>
                      <LazyImage
                        src={`https://back.secuecloud.co.kr/file/imgView?idx=${it.mainImgIdx}`}
                        alt={it.title}
                      />
                    </figure>
                    <div className="descript">
                      <h3>{it.title}</h3>
                      <p className="content">{removeTags(it.contents)}</p>
                      <span className="date">{TrDate(it.rgsde)}</span>
                    </div>
                  </Link>
                </ITMS>
              );
            })}
          </ItemsUl>
        ) : (
          <p className="noList">리스트가 없습니다.</p>
        )}
        {list?.length !== 0 && pagingInfo ? <Pagination pagingInfo={pagingInfo} /> : null}
      </Container>
    </NewsMaxWidth>
  );
};

export default NewsItem;
