import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MaxWidth, Section } from 'GlobalStyle';
import { Main } from './style';
import FAQ from './faq';
import Support from './support';

const CustomerSupport = () => {
  const [selectedTab, setSelectedTab] = useState('support');

  return (
    <>
      <Main>
        <MaxWidth>
          <div className="main01_wrap">
            <h1>고객지원</h1>
            <p>
              <span>시큐이클라우드에 대해 궁금하신 점은 자주 묻는 질문에서 확인하시거나</span>
              <span>기원테크 전문 인력에게 부담없이 문의해주세요.</span>
            </p>
            <ul>
              <li>
                <button
                  value={'support'}
                  className={selectedTab === 'support' ? 'active' : ''}
                  onClick={() => {
                    setSelectedTab('support');
                  }}
                >
                  제품문의
                </button>
              </li>
              <li>
                <button
                  value={'faq'}
                  className={selectedTab === 'faq' ? 'active' : ''}
                  onClick={() => {
                    setSelectedTab('faq');
                  }}
                >
                  자주 묻는 질문
                </button>
              </li>
            </ul>
          </div>
          <img src="/images/background/bg-cs_question.png" />
        </MaxWidth>
      </Main>
      {selectedTab === 'support' ? <Support /> : <FAQ />}
    </>
  );
};

export default CustomerSupport;
