import styled from 'styled-components';

export const PricePolicyWrapper = styled.div`
  border-radius: 15px;
  h3 {
    padding-top: 30px;
    box-sizing: border-box;
    color: #5d5d5d;
    font-weight: 500;
    font-family: SBAggrolL;
    font-size: 26px;
    margin-bottom: 20px;
    span {
      display: block;
      font-size: 15px;
      font-weight: 400;
      color: #868686;
      line-height: 2;
      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }
    }
    @media screen and (max-width: 1200px) {
      padding-top: 10px;
      font-size: 20px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 750px) {
      margin-bottom: 15px;
    }
  }
`;

export const Table = styled.div`
  display: grid;
  width: 100%;
  height: 280px;
  border-radius: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  border-bottom: 2px solid #d8d9dc;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #d8d9dc;
    border-bottom: 1px solid #d8d9dc;
    &:nth-of-type(3n + 0) {
      border-right: none;
    }
    &:nth-of-type(10),
    &:nth-of-type(11),
    &:nth-of-type(12) {
      border-bottom: none;
    }
  }
  .title {
    background-color: #4d81db;
    color: #ffffff;
    font-family: SBAggrolL;
    font-weight: 500;
    font-size: 18px;
    &:nth-of-type(1) {
      border-top-left-radius: 15px;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 15px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }
  .service {
    background-color: #f6f8fd;
    font-size: 19px;
    font-weight: 500;
    color: #5e5e5e;
    &:nth-of-type(4),
    &:nth-of-type(7),
    &:nth-of-type(10) {
      border-left: 2px solid #d8d9dc;
    }
    &:nth-of-type(10) {
      border-bottom-left-radius: 15px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 15px;
    }
  }
  .content {
    font-size: 18px;
    font-weight: 500;
    color: #5e5e5e;
    &:nth-of-type(6),
    &:nth-of-type(9),
    &:nth-of-type(12) {
      border-right: 2px solid #d8d9dc;
    }
    &:nth-of-type(12) {
      border-bottom-right-radius: 15px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 1200px) {
    height: 210px;
  }
  @media screen and (max-width: 750px) {
    display: none;
  }
`;

export const Table400 = styled.div`
  display: none;
  height: 50vh;
  h5 {
    position: relative;
    text-align: left;
    margin-left: 25px;
    font-family: SBAggrolL;
    font-size: 15px;
    font-weight: 600;
    color: #4d81db;
    margin-bottom: 10px;
    &:before {
      content: '•';
      position: absolute;
      top: 0;
      left: -10px;
      bottom: 50%;
      right: 0;
    }
    @media screen and (max-width: 750px) {
      display: block;
    }
  }
  .table {
    display: grid;
    width: 100%;
    //height: 90px;
    height: calc((100% - 155px) / 3);
    border-radius: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 25px;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-of-type(1) {
      border-top-left-radius: 10px;
    }
    &:nth-of-type(2) {
      border-top-right-radius: 10px;
    }
    &:nth-of-type(3) {
      border-bottom-left-radius: 10px;
    }
    &:nth-of-type(4) {
      border-bottom-right-radius: 10px;
    }
  }
  .title {
    background-color: #4d81db;
    color: #ffffff;
    font-family: SBAggrolL;
    font-weight: 500;
    font-size: 15px;
  }
  .content {
    font-size: 15px;
    font-weight: 500;
    color: #5e5e5e;
    background-color: #f6f8fd;
  }
  @media screen and (max-width: 750px) {
    display: block;
  }
`;
