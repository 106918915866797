import styled, { keyframes } from 'styled-components';

export const Main01 = styled.article`
  position: relative;
  width: 100%;
  padding: 231px 40px 150px;
  box-sizing: border-box;

  @media screen and (max-width: 920px) {
    padding: 180px 40px 110px;
  }

  @media screen and (max-width: 670px) {
    padding: 140px 40px 70px;
  }

  @media screen and (max-width: 600px) {
    padding: 470px 30px 50px;
  }

  @media screen and (max-width: 480px) {
    padding: 450px 30px 50px;
  }

  & > div {
    display: flex;
    justify-content: right;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      justify-content: center;
      align-items: end;
    }
  }

  .main01_wrap {
    position: relative;
    z-index: 1;
    width: 50%;

    @media screen and (max-width: 600px) {
      width: 100%;
      text-align: center;
      padding-bottom: 55px;
    }

    h1 {
      color: #4d81db;
      font-size: 60px;
      font-weight: 500;
      font-family: 'SBAggrolL';
      margin-bottom: 24px;
      line-height: 1.2;
      letter-spacing: -0.015em;
      @media screen and (max-width: 920px) {
        font-size: 48px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 480px) {
        font-size: 38px;
      }
    }
    p {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      letter-spacing: -0.025em;
      background: linear-gradient(45deg, #60d3f0, #4d81db 20%);
      color: transparent;
      -webkit-background-clip: text;

      @media screen and (max-width: 920px) {
        font-size: 20px;
      }

      @media screen and (max-width: 600px) {
        background: linear-gradient(45deg, #60d3f0, #4d81db 65%);
        color: transparent;
        -webkit-background-clip: text;
      }

      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }

    .intro {
      @media screen and (max-width: 1200px) {
        padding-right: 50px;
      }

      @media screen and (max-width: 920px) {
        font-size: 16px;
        word-break: break-word;
      }

      @media screen and (max-width: 670px) {
        padding-right: 30px;
      }

      @media screen and (max-width: 600px) {
        width: auto;
        margin: 0 auto;
        padding-right: 0;
      }

      @media screen and (max-width: 330px) {
        width: 100%;
      }
      span {
        display: block;
        margin: 0 0 3px 0;
        color: #6f6f6f;
        font-size: 19px;
        font-weight: 500;
        line-height: 1.6;

        @media screen and (max-width: 1200px) {
          display: inline;
        }
        @media screen and (max-width: 920px) {
          font-size: 16px;
        }
      }
    }
  }

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-groupware01-left.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    right: 54%;
    width: 890px;
    top: 0;
    bottom: 0;

    @media screen and (max-width: 920px) {
      background-size: auto 335px;
      width: 400px;
      background-position: right bottom;
      right: 57%;
    }

    @media screen and (max-width: 890px) {
      right: 56%;
    }

    @media screen and (max-width: 850px) {
      right: 53%;
    }

    @media screen and (max-width: 800px) {
      right: 50%;
    }

    @media screen and (max-width: 750px) {
      right: 47%;
      background-position: right bottom;
    }

    @media screen and (max-width: 600px) {
      background-image: url('/images/background/bg-groupware01-center.png');
      background-size: 600px;
      background-position: center 170px;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-mail01-right.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
`;

export const Main02 = styled.article`
  width: 100%;
  padding: 120px 0px;
  background-color: #4d81db;
  box-sizing: border-box;
  @media screen and (max-width: 1300px) {
    padding: 100px 0px;
  }
  @media screen and (max-width: 880px) {
    padding: 90px 45px;
  }
  @media screen and (max-width: 580px) {
    padding: 75px 0px;
  }
  & > div {
    @media screen and (max-width: 580px) {
      padding: 0 30px;
    }
  }
  h2 {
    color: #ffffff;
    text-align: center;
    font-size: 48px;
    padding-bottom: 65px;
    font-family: 'SBAggrolL';
    font-weight: 500;
    line-height: 1.5;
    @media screen and (max-width: 880px) {
      padding-bottom: 45px;
      font-size: 38px;
    }
    @media screen and (max-width: 480px) {
      padding-bottom: 28px;
      font-size: 28px;
    }
    & > span {
      font-family: 'SBAggrolL';
      font-weight: 400;
      line-height: 1.5;
      @media screen and (max-width: 880px) {
        display: block;
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 25px;
  box-sizing: border-box;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    row-gap: 25px;
  }
`;

export const Main02Item = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 148px 40px 68px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 40px 62px;
  box-shadow: 7px 7px 3px rgba(72, 81, 113, 0.15);
  height: 345px;

  &:nth-of-type(1) {
    background-image: url('/images/icon/ico-groupware01.png');
  }
  &:nth-of-type(2) {
    background-image: url('/images/icon/ico-groupware02.png');
  }
  &:nth-of-type(3) {
    background-image: url('/images/icon/ico-groupware03.png');
  }
  @media screen and (max-width: 1300px) {
    background-position: 40px 60px;
    height: 450px;
  }
  @media screen and (max-width: 1200px) {
    background-position: 40px 60px;
    height: 420px;
  }
  @media screen and (max-width: 1010px) {
    background-position: 40px 60px;
    height: 450px;
  }
  @media screen and (max-width: 900px) {
    background-position: 40px 60px;
    height: 470px;
  }
  @media screen and (max-width: 880px) {
    padding: 50px 35px 50px 170px;
    background-position: 60px 50%;
    height: auto;
  }
  @media screen and (max-width: 680px) {
    padding: 50px 15px 50px 150px;
    background-position: 50px 50%;
  }
  @media screen and (max-width: 580px) {
    padding: 40px;
    background-position: 40px 60px;
    background-size: auto 50px;
  }
  @media screen and (max-width: 480px) {
    padding: 35px;
    background-position: 35px 40px;
  }
  @media screen and (max-width: 390px) {
    padding: 90px 25px 50px 25px;
    background-position: 25px 35px;
    background-size: 40px 40px;
  }
  h5 {
    font-family: 'NotoSansKr';
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.3;
    letter-spacing: -0.035em;
    color: #333333;
    @media screen and (max-width: 680px) {
      font-size: 20px;
    }
    @media screen and (max-width: 580px) {
      margin: 15px 0 20px 75px;
    }
    @media screen and (max-width: 480px) {
      margin: 0 0 20px 80px;
    }
    @media screen and (max-width: 390px) {
      margin: 0 0 10px 0;
    }

    & > span {
      @media screen and (max-width: 1300px) {
        display: block;
        line-height: 1.5;
      }
      @media screen and (max-width: 880px) {
        display: inline;
      }
      @media screen and (max-width: 680px) {
        display: block;
      }
    }
  }
  p {
    span {
      display: block;
      font-size: 18px;
      font-family: 'NotoSansKr';
      color: #656565;
      line-height: 1.6;
      letter-spacing: -0.025em;
      padding-right: 30px;
      @media screen and (max-width: 680px) {
        font-size: 16px;
      }
      @media screen and (max-width: 580px) {
        padding-right: 0;
      }
    }
  }
`;

export const Main03 = styled.article`
  width: 100%;
  box-sizing: border-box;
  font-family: 'SBAggrolL';
  & > div {
    .tab-menu {
      padding: 160px 0 60px;
      @media screen and (max-width: 880px) {
        padding: 115px 0 55px;
      }
      @media screen and (max-width: 480px) {
        padding: 90px 0 35px;
      }
    }
    h2 {
      font-family: 'SBAggrolL';
      font-weight: 600;
      font-size: 44px;
      line-height: 1.3;
      color: #3d6eb6;
      text-align: center;
      margin-bottom: 50px;
      & > span {
        font-family: 'SBAggrolL';
        font-weight: 500;
        color: #4e4e4e;
        font-size: 50px;
        @media screen and (max-width: 880px) {
          font-size: 40px;
          display: block;
        }
        @media screen and (max-width: 480px) {
          font-size: 28px;
        }
      }
      @media screen and (max-width: 880px) {
        font-size: 36px;
      }
      @media screen and (max-width: 480px) {
        margin-bottom: 20px;
        font-size: 28px;
      }
    }
  }
  .inner {
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    &.fixed {
      position: fixed;
      top: 90px;
      z-index: 99;
      left: 0;
      right: 0;
      height: 100px;
      padding: 33px 0 0 0;
      backdrop-filter: blur(5px);

      @media screen and (max-width: 1300px) {
        position: inherit;
        top: 0;
        left: 0;
        padding: 0;
        height: auto;
        backdrop-filter: none;
      }
    }

    li {
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
      }
      @media screen and (max-width: 600px) {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 20px;
        //&:nth-of-type(3),
        //&:nth-of-type(5) {
        //  margin-right: 0;
        //}
      }
      @media screen and (max-width: 350px) {
        &:nth-of-type(3) {
          margin-right: 15px;
        }
      }
    }
    .tab {
      background-color: #ffffff;
      border-radius: 20px;
      padding: 0px 35px;
      height: 40px;
      box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
      color: #505050;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.025em;
      @media screen and (max-width: 890px) {
        padding: 0 20px;
        box-sizing: border-box;
      }
      @media screen and (max-width: 730px) {
        padding: 0 15px;
        font-size: 16px;
      }
    }
    .active {
      background-color: #4d81db;
      color: #ffffff;
      box-shadow: 5px 7px 12px rgba(15, 41, 107, 0.08);
      font-weight: 500;
    }
    @media screen and (max-width: 600px) {
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 320px;
    }
    @media screen and (max-width: 380px) {
      width: 210px;
    }
  }
`;

export const TabSection = styled.div`
  width: 100%;
  padding: 50px 0 50px 0;
  background-color: #f4f4f9;
  position: relative;
  box-sizing: border-box;
  & > div {
    h3 {
      text-align: center;
      font-size: 34px;
      color: #555555;
      font-family: 'NotoSansKr';
      font-weight: 600;
      margin-bottom: 8px;
      line-height: 1.6;
      span {
        color: #4e62aa;
        font-family: 'NotoSansKr';
        font-weight: 600;
        font-size: 44px;
        @media screen and (max-width: 880px) {
          font-size: 38px;
        }
        @media screen and (max-width: 480px) {
          font-size: 26px;
          display: block;
        }
      }
      @media screen and (max-width: 880px) {
        font-size: 30px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 480px) {
        font-size: 22px;
      }
    }
    & > p {
      text-align: center;
      line-height: 1.6;
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 18px;
        color: #767676;
        @media screen and (max-width: 880px) {
          font-size: 16px;
        }
        @media screen and (max-width: 480px) {
          display: inline;
        }
      }
      @media screen and (max-width: 880px) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 480px) {
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 480px) {
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 880px) {
    padding: 75px 0 50px;
  }
  @media screen and (max-width: 480px) {
    padding: 50px 0;
  }
`;

export const TabWrapper = styled.div`
  position: relative;
  width: 1280px;
  margin: 0 auto;
  img {
    width: 100%;
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  .tab-img {
    width: 100%;
  }

  // .centerimg {
  //   height: 800px;
  // }

  .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -90px;

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -90px;
    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
`;

export const Main04 = styled.article`
  padding: 50px 0;
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-groupware02-circle01.png');
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  &:after {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-groupware02-circle02.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media screen and (max-width: 1300px) {
      background-position: right -250px bottom;
    }
    @media screen and (max-width: 480px) {
      background-position: right -300px bottom -150px;
    }
  }
  & > div {
    display: flex;
    padding: 0 0 0 30px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    max-width: 1280px;
    & > div {
      flex-grow: 1;
      width: 50%;
      @media screen and (max-width: 430px) {
        text-align: center;
      }
      h3 {
        font-size: 34px;

        p {
          line-height: 1.3;
          color: #555555;
          font-weight: 600;
          letter-spacing: -0.025em;
          margin-bottom: 30px;
          & > span {
            display: inline;
            font-weight: 600;
            color: #4e62aa;
            font-size: 34px;
            @media screen and (max-width: 1080px) {
              font-size: 26px;
            }
            @media screen and (max-width: 680px) {
              font-size: 22px;
            }
          }
          @media screen and (max-width: 1080px) {
            margin-bottom: 15px;
          }
          @media screen and (max-width: 480px) {
            margin-bottom: 10px;
          }
        }
        @media screen and (max-width: 1080px) {
          font-size: 26px;
          text-align: center;
        }
        @media screen and (max-width: 680px) {
          font-size: 22px;
        }
      }
      span {
        display: block;
        font-size: 18px;
        color: #767676;
        line-height: 1.6;
        letter-spacing: -0.025em;
        @media screen and (max-width: 1080px) {
          font-size: 16px;
          text-align: center;
        }
        @media screen and (max-width: 430px) {
          display: inline;
        }
      }
      @media screen and (max-width: 1080px) {
        width: 100%;
      }
    }
    img {
      @media screen and (max-width: 1080px) {
        margin-top: 15px;
      }
    }
    @media screen and (max-width: 1080px) {
      flex-direction: column;
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 1080px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 480px) {
    padding: 55px 0;
  }
`;

export const Main05 = styled.article`
  padding: 50px 0 40px;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/background/bg-groupware02-circle03.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #f4f4f9;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 1300px) {
      background-position: left -150px bottom -100px;
    }
    @media screen and (max-width: 480px) {
      background-position: left -300px bottom -150px;
    }
  }
  & > div {
    display: flex;
    padding: 0;
    justify-content: start;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row-reverse;
    max-width: 1280px;

    & > div {
      flex-grow: 1;

      &.txtWrap {
        padding-left: 50px;

        h3 {
          p {
            line-height: 1.3;
            font-size: 34px;
            color: #555555;
            font-weight: 600;
            margin-bottom: 30px;
            letter-spacing: -0.025em;

            & > span {
              display: inline;
              font-size: 34px;
              font-weight: 600;
              color: #4e62aa;
              @media screen and (max-width: 1080px) {
                font-size: 26px;
              }
              @media screen and (max-width: 680px) {
                font-size: 22px;
              }
            }
            @media screen and (max-width: 1080px) {
              font-size: 26px;
              text-align: center;
              margin-bottom: 20px;
            }
            @media screen and (max-width: 680px) {
              font-size: 22px;
            }
          }
        }
        @media screen and (max-width: 1160px) {
          padding-left: 0;
        }
        @media screen and (max-width: 430px) {
          text-align: center;
        }
      }
      span {
        font-size: 18px;
        color: #767676;
        line-height: 1.6;
        display: block;
        letter-spacing: -0.025em;
        @media screen and (max-width: 1080px) {
          font-size: 16px;
          text-align: center;
        }
        @media screen and (max-width: 430px) {
          display: inline;
        }
      }
      @media screen and (max-width: 680px) {
        width: 100%;
      }
    }
    img {
      @media screen and (max-width: 1080px) {
        margin-top: 15px;
      }
    }
    @media screen and (max-width: 1080px) {
      flex-direction: column;
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 1080px) {
    padding: 50px 0 70px;
  }
`;
